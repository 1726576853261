import { FC } from "react";
import { MdOutlineTimer } from "react-icons/md";
import { useTimer } from "../../customHooks/useTimer";
import i18n from "../../locales";
import { IPromo } from "../../store/products/repository/IPromo";

interface IProps {
    promo: IPromo;
};

export const ProductCardTimer: FC<IProps> = ({ promo }) => {
    const endDate = promo.endDate || '';
    const { timeLeft, getDayWord } = useTimer(endDate);

    return (
        <div className="promotion_description__description">
            <MdOutlineTimer color="red" size="14" />
            {timeLeft.days ? (
                <>
                    <div className="promotion_card__remaining">{timeLeft && i18n.t('leftToEnd')}</div>
                    <span className="promotion_card__timeInDay">{timeLeft.days} {getDayWord(timeLeft.days)}</span>
                </>
            ) : (
                <>
                    <div className="promotion_card__remaining">{timeLeft && i18n.t('leftToEnd')}</div>
                    <span className="promotion_card__time">
                        <span className="time_block">{String(timeLeft.hours).padStart(2, '0')}</span>:
                        <span className="time_block">{String(timeLeft.minutes).padStart(2, '0')}</span>:
                        <span className="time_block">{String(timeLeft.seconds).padStart(2, '0')}</span>
                    </span>
                </>
            )}
        </div>
    )
}