import React from "react";
import i18n from "../../locales";
import TextSection from "../../ui-kit/textSection/textSection";
import SanitaizedText from "../../ui-kit/sanitaizedText/sanitaizedText";

const WarrantyPage = () => {
  return (
    <TextSection>
      <h1>{i18n.t("warrantyConditions")}</h1>
      <p>
        <SanitaizedText text={i18n.t("warrantyTermsText0")} />
      </p>
      {[...Array(12)].map((_, index) => (
        <React.Fragment key={index}>
          <h3>
            {index + 1}. {i18n.t(`warrantyTermsTitle${index + 1}`)}
          </h3>
          <p>
            <SanitaizedText text={i18n.t(`warrantyTermsText${index + 1}`)} />
          </p>
        </React.Fragment>
      ))}
    </TextSection>
  );
};

export default WarrantyPage;
