import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";
import { getCart } from "../../../orders/useCases/getCart/action";
import { setAppLoading } from "../../../app/repository/actions";
import i18n from "../../../../locales";

interface IActivatePromoCodWorker {
  type: string;
  payload: {
    promoCode: string;
  };
}

function* activatePromoCodeWorker({
  payload,
}: IActivatePromoCodWorker): SagaIterator {
  try {
    yield put(setAppLoading(true));

    const { data, isError, message } = yield call(
      requester.post,
      links.activatePromocode,
      { promoCode: payload }
    );

    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else if (
      data.message === "promo_code_not_found" ||
      data.message === "promo_code_expired" ||
      data.message === "promo_code_usage_amount_exhausted" ||
      data.message === "you_already_use_this_promo_code" ||
      data.message === "promo_code_start_date_has_not_yet_arrived"
    ) {
      createNotification({
        title: i18n.t("error"),
        message: i18n.t(data.message),
        type: "danger",
      });
    } else if (data) {
      createNotification({
        duration: 5000,
        title: i18n.t("success"),
        message: `${i18n.t("promoCode")} ${i18n.t("applied")}`,
        type: "success",
      });
      yield put(getCart());
    }
  } catch (error) {
    console.error("activatePromoCodeWorker: ", error);
  } finally {
    yield put(setAppLoading(false));
  }
}

export function* activatePromoCodeWatcher() {
  yield takeLatest("@saga/ACTIVATE_PROMOCODE", activatePromoCodeWorker);
}
