import { FC, ReactNode } from "react";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";

interface IProps {
  targetId: string;
  children: ReactNode;
}

gsap.registerPlugin(ScrollToPlugin);

const AnchorScroll: FC<IProps> = ({ targetId, children }) => {
  const handleClick = () => {
    const block = document.getElementById(targetId);
    const header = document.querySelector("header");
    if (!block || !header) return;

    const { height } = header.getBoundingClientRect();
    const offsetY = 30 + Math.round(height);

    gsap.to(window, {
      scrollTo: {
        y: block,
        offsetY,
      },
    });
  };

  return (
    <button
      onClick={handleClick}
      type="button"
      aria-label="Scroll to block"
      className="anchor"
    >
      {children}
    </button>
  );
};

export default AnchorScroll;
