import i18n from "../../../../locales";
import { createNotification } from "../../../../utils/createNotification";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { IUpdateUser } from "../../repository/IUpdateUser";
import { getUser } from "../getUser/actions";
import {
  saveUser,
  setConfirmEmailPopupOpen,
  setUpdateUserLoading,
} from "../../repository/actions";

interface IUpdateUserWorker {
  type: string;
  payload: IUpdateUser;
}

function* updateUserWorker({ payload }: IUpdateUserWorker): SagaIterator {
  try {
    yield put(setUpdateUserLoading(true));
    const { data, isError } = yield call(requester.put, links.user, payload);

    if (!isError) {
      if (
        data.message === "this_email_is_already_in_use" ||
        data.message === "this_email_already_in_use"
      ) {
        createNotification({
          title: i18n.t("error"),
          message: i18n.t(data.message),
          type: "danger",
        });
      } else {
        yield put(saveUser(data));

        if (payload.showMessage) {
          createNotification({
            title: i18n.t("success"),
            message: "",
            type: "success",
          });
        }
        yield put(getUser());

        if (payload.isEmailChange) {
          yield put(setConfirmEmailPopupOpen(true));
        }
      }
    }
  } catch (error) {
    console.error("updateUserWorker: ", error);
  } finally {
    yield put(setUpdateUserLoading(false));
  }
}

export function* updateUserWatcher() {
  yield takeLatest("@saga/UPDATE_USER", updateUserWorker);
}
