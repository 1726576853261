import { FC } from "react";
import { IBanner } from "../../store/banners/repository/IBanner";
import Image from "../image/image";
import "./fullScreenSlide.scss";

const FullScreenSlide: FC<IBanner> = ({
  type,
  src,
  title,
  imageWeb,
  imageMobile,
}) => {
  const isLink = (type === "External" || type === "Internal") && src;
  const Tag = isLink ? "a" : "div";

  return (
    <Tag
      href={isLink ? src : undefined}
      target={type === "External" ? "_blank" : undefined}
      className="hero_slide"
    >
      <Image
        imgClass="hero_slide__image"
        src={imageWeb.fileUrl}
        alt={title}
        mobileImage={imageMobile?.fileUrl}
      />
    </Tag>
  );
};

export default FullScreenSlide;
