import { FC, ReactNode } from "react";
import classNames from "classnames";

interface IProps {
  children: ReactNode;
  handleClick: () => void;
  isActive: boolean;
}

const OrdersTabButton: FC<IProps> = ({ children, handleClick, isActive }) => {
  return (
    <button
      onClick={handleClick}
      type="button"
      className={classNames("orders__button", {
        "orders__button--current_state": isActive,
      })}
    >
      {children}
    </button>
  );
};

export default OrdersTabButton;
