import i18n from "../../locales";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectLanguages,
  selectCurrentLang,
} from "../../store/language/repository/selectors";
import { selectisLoggedIn } from "../../store/user/repository/selectors";
import { selectAuthType } from "../../store/user/repository/selectors";
import { setAuthType } from "../../store/user/repository/actions";
import { saveCurrentLang } from "../../store/language/repository/actions";
import { updateUser } from "../../store/user/useCases/updateUser/actions";
import { useOutsideClick, useMedia, useMaxHeight } from "../../customHooks";
import classNames from "classnames";
import {
  LOCAL_STORAGE,
  VALIDATED_VALUES,
  DEFAULT_LANGUAGE,
} from "../../constants";
import { ILanguage } from "../../store/language/repository/ILanguage";
import "./langButton.scss";

interface ISwitchProps {
  id: number;
  short_name: string;
}

const LangButton = () => {
  const languages = useSelector(selectLanguages);
  const { data } = languages;

  const currentLang = useSelector(selectCurrentLang);
  const isLoggedIn = useSelector(selectisLoggedIn);
  const authType = useSelector(selectAuthType);
  const dispatch = useDispatch();

  const [isOpen, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const langRef = useRef(null);
  const listRef = useRef(null);
  const isMobile = useMedia();
  const listMaxHeight = useMaxHeight(listRef);

  const maxHeight = listMaxHeight !== 0 ? listMaxHeight : undefined;

  const isExistInData = (languages: ILanguage[], languageCode: string) =>
    languages.find((lang) => lang.short_name.toLowerCase() === languageCode);

  const setInitialLanguage = (languages: ILanguage[]) => {
    let selectedLanguage;

    if (!localStorage.getItem(LOCAL_STORAGE.language)) {
      selectedLanguage =
        isExistInData(languages, VALIDATED_VALUES.language) ??
        isExistInData(languages, DEFAULT_LANGUAGE);
    } else {
      selectedLanguage =
        isExistInData(
          languages,
          localStorage.getItem(LOCAL_STORAGE.language)!
        ) ?? isExistInData(languages, DEFAULT_LANGUAGE);
    }

    langSwitch({
      id: selectedLanguage?.id || -1,
      short_name: selectedLanguage?.short_name || DEFAULT_LANGUAGE,
    });
  };

  const switchLangOnLogin = () => {
    const lsUser = localStorage.getItem(LOCAL_STORAGE.user);
    if (!lsUser) return;

    const user = JSON.parse(lsUser);
    if (!user.language) return;

    if (authType === "login") {
      langSwitch({
        id: user.language.id,
        short_name: user.language.short_name,
      });
      dispatch(setAuthType(null));
    }
  };

  const changeLanguageOnRegister = () => {
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE.user) ?? "{}");
    if (!user.language) return;
    if (
      authType === "register" &&
      isLoggedIn === "true" &&
      currentLang !== user.language.short_name &&
      currentIndex !== -1
    ) {
      langSwitch({
        id: currentIndex,
        short_name: currentLang,
      });
    }
  };

  useEffect(() => {
    if (data.length <= 0) return;

    setInitialLanguage(data);
  }, [data]);

  useEffect(() => {
    if (!authType) return;
    switchLangOnLogin();
  }, [authType]);

  useEffect(() => {
    if (!authType) return;
    changeLanguageOnRegister();
  }, [authType, isLoggedIn]);

  const onMouseEnter = () => setOpen(true);
  const onMouseLeave = () => setOpen(false);

  const onClick = () => setOpen((isOpen) => !isOpen);
  const onClose = () => setOpen(false);

  const langSwitch = ({ id, short_name }: ISwitchProps) => {
    if (isLoggedIn === "true") {
      const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE.user) ?? "{}");
      if (user.id) {
        dispatch(updateUser({ id: user.id, language: id }));
      }
    }

    setCurrentIndex(id);
    localStorage.setItem(LOCAL_STORAGE.language, short_name);
    dispatch(saveCurrentLang(short_name));
    i18n.changeLanguage(short_name);
    isOpen && onClose();
  };

  useOutsideClick(langRef, onClose, isOpen);

  const langClasses = classNames("lang_button", {
    "lang_button--open_state": isOpen,
  });

  return (
    <div
      ref={langRef}
      onMouseEnter={isMobile ? undefined : onMouseEnter}
      onMouseLeave={isMobile ? undefined : onMouseLeave}
      className={langClasses}
    >
      <button
        onClick={isMobile ? onClick : undefined}
        className="lang_button__current"
        type="button"
      >
        {currentLang}
      </button>
      <ul ref={listRef} style={{ maxHeight }} className="lang_button__list">
        {data.map(({ id, short_name }) => {
          const isCurrent = id === currentIndex;
          const btnClasses = classNames("lang_button__action", {
            "lang_button__action--current_state": isCurrent,
          });

          return (
            <li key={short_name} className="lang_button__item">
              <button
                onClick={
                  isCurrent ? undefined : () => langSwitch({ id, short_name })
                }
                type="button"
                className={btnClasses}
              >
                {short_name}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default LangButton;
