import { RefObject, useEffect, useCallback, useState } from "react";

const useMaxHeight = <T extends HTMLElement>(
  elementRef: RefObject<T>
): string | number => {
  const [maxHeight, setMaxHeight] = useState<number>(0);

  const updateMaxHeight = useCallback(() => {
    const element = elementRef.current?.getBoundingClientRect();
    if (!element) return;

    const { top } = element;

    const viewportHeight = window.innerHeight;
    setMaxHeight(viewportHeight - top);
  }, [elementRef]);

  useEffect(() => {
    setTimeout(() => {
      updateMaxHeight();
    }, 500);

    window.addEventListener("resize", updateMaxHeight);

    return () => {
      window.removeEventListener("resize", updateMaxHeight);
    };
  }, [elementRef]);

  const remValue = maxHeight - 100;
  const vhValue = window.innerHeight * 0.8 - 100;
  const result = `${Math.round(Math.min(remValue, vhValue) / 10)}rem`;

  return result === "0rem" ? 0 : result;
};

export default useMaxHeight;
