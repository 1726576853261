import { FC, useState, useEffect, KeyboardEvent, ChangeEvent } from "react";
import classNames from "classnames";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import "./quantity.scss";
import Input from "../input/input";

interface IProps {
  handleDecrease: () => void;
  handleIncrease: () => void;
  onChange: (value: number) => void;
  minimalCount?: number;
  value?: number;
}

const Quantity: FC<IProps> = ({
  minimalCount = 1,
  value,
  handleIncrease,
  handleDecrease,
  onChange,
}) => {
  const [count, setCount] = useState<number>(value ?? minimalCount);

  const onIncrease = () => {
    setCount((prev) => prev + 1);
    handleIncrease();
  };
  const onDecrease = () => {
    setCount((prev) => {
      if (prev <= minimalCount) {
        return prev;
      }
      return prev - 1;
    });
    handleDecrease();
  };

  useEffect(() => {
    if (count < minimalCount) {
      setCount(minimalCount);
    }
  }, [minimalCount]);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowUp") {
      onIncrease();
    } else if (event.key === "ArrowDown") {
      onDecrease();
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value);

    if (isNaN(newValue) || newValue < minimalCount) return;

    setCount(newValue);
    onChange(newValue);
  };

  return (
    <div className="quantity">
      <button
        aria-label="Decrease"
        onClick={onDecrease}
        className={classNames(
          "quantity__btn",
          "quantity__btn--minus_mod",
          "font_icon",
          {
            "quantity__btn--disabled_mod": count === minimalCount,
          }
        )}
      >
        <AiFillMinusCircle />
      </button>
      <Input
        className="quantity__input"
        value={count}
        // onKeyDown={handleKeyDown}
        onChange={handleChange}
      />
      <button
        aria-label="Increase"
        onClick={onIncrease}
        className={classNames(
          "quantity__btn",
          "quantity__btn--plus_mod",
          "font_icon"
        )}
      >
        <AiFillPlusCircle />
      </button>
    </div>
  );
};

export default Quantity;
