import { FC, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNovaPoshtaAPI } from "../../../../customHooks";
import { createNotification } from "../../../../utils/createNotification";
import { isValidPhoneNumber } from "../../../../utils/isValidPhoneNumber";
import {
  selectUser,
  selectisLoggedIn,
} from "../../../../store/user/repository/selectors";
import {
  selectDislaimerPopupOpen,
  selectCurrentOrder,
  selectFactPaynamentMethod,
  selectCart,
} from "../../../../store/orders/repository/selectors";
import { setDislaimerPopupOpen } from "../../../../store/orders/repository/actions";
import { createOrder } from "../../../../store/orders/useCases/createOrder/action";
import { getCountries } from "country-state-picker";
import i18n from "../../../../locales";
import { findPercent } from "../../../../utils/findPercent";
import { TOrder } from "../../../../types/order";
import { IOrderData } from "../../../../store/orders/repository/IOrderData";
import { PROMISE_STATES, ROUTES } from "../../../../constants";
import { TSelectOption } from "../../../../types/selectOption";
import { FormLayout, FormLayoutItem } from "../../../formLayout";
import InputField from "../../../inputField/inputField";
import TextareaField from "../../../textareaField/textareaField";
import SelectField from "../../../selectField/selectField";
import OrderingPickup from "../orderingPickup/orderingPickup";
import Popup from "../../../popup/popup";
interface IFormData {
  name: string;
  surname: string;
  phone_code: string;
  phone: string;
  comment: string;
  paymentMethod?: "Fact";
  address?: string;
  area?: string;
  city?: string;
  warehouse?: string;
  discount?: number;
}

interface ICountryOption {
  name: string;
  code: string;
  dial_code: string;
}

interface ISelectOption {
  label: string;
  value: string;
}

interface IProps {
  realPriceGrn: string;
  realPriceYe: string;
  totalPriceGRN: string;
  totalPriceYE: string;
  setRealPriceGrn: (price: string) => void;
  setRealPriceYe: (price: string) => void;
}

const OrderingForm: FC<IProps> = ({
  setRealPriceGrn,
  setRealPriceYe,
  totalPriceGRN,
  totalPriceYE,
  realPriceGrn,
  realPriceYe,
}) => {
  const [errorField, setErrorField] = useState<string | null>(null);
  const [selectedArea, setSelectedArea] = useState<string | null>(null);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState<string | null>(
    null
  );
  const {
    status,
    areas,
    cities,
    warehouses,
    onSearchAreas,
    onSearchCity,
    onSearchWarehouses,
    areasLoading,
    citiesLoading,
    warehousesLoading,
  } = useNovaPoshtaAPI(selectedArea, selectedCity);

  const [orderType, setOrderType] = useState<TOrder | null>(null);
  const [defaultPhoneCode, setDefaultPhoneCode] =
    useState<TSelectOption | null>(null);
  const { register, handleSubmit, setValue, reset } = useForm<IFormData>();

  const navigate = useNavigate();
  const disaptch = useDispatch();
  const { promoCode } = useSelector(selectCart);
  const user = useSelector(selectUser);
  const isPaynamentMethodFact = useSelector(selectFactPaynamentMethod);
  const isLoggedIn = useSelector(selectisLoggedIn);
  const isDisclaimerPopupOpen = useSelector(selectDislaimerPopupOpen);
  const currentOrder = useSelector(selectCurrentOrder);
  const defaultPhone = useMemo(() => {
    if (isLoggedIn === "true" && user?.phone) {
      const phoneValue = user.phone.replace(user?.phoneCode, "");
      setValue("phone", phoneValue);
      return phoneValue;
    }
  }, [isLoggedIn, user?.phone, user?.phoneCode]);

  const phoneCodeOptions = useMemo(() => {
    return getCountries().map(({ dial_code }: ICountryOption) => ({
      label: dial_code,
      value: dial_code,
    }));
  }, []);
  const deliveryOptions = [
    {
      label: i18n.t("courierNP"),
      value: "DELIVERY",
    },
    {
      label: i18n.t("warehouseNP"),
      value: "WAREHOUSE",
    },
    // {
    //   label: i18n.t("pickup"),
    //   value: "PICKUP",
    // },
  ];

  useEffect(() => {
    if (isLoggedIn === "true" && user?.phoneCode) {
      const foundPhoneCode = phoneCodeOptions.find(
        ({ label }: ISelectOption) => label === user.phoneCode
      );
      if (foundPhoneCode) {
        setDefaultPhoneCode(foundPhoneCode);
        setValue("phone_code", foundPhoneCode.value);
      }
    } else {
      const foundPhoneCode = phoneCodeOptions.find(
        ({ label }: ISelectOption) => label === "+380"
      );
      if (foundPhoneCode) {
        setDefaultPhoneCode(foundPhoneCode);
        setValue("phone_code", foundPhoneCode.value);
      }
    }
  }, [phoneCodeOptions, user]);

  const handleOrderTypeSelect = (option: TOrder) => {
    setOrderType(option);
  };
  const handlePhoneCodeSelect = (option: string) => {
    setValue("phone_code", option);
  };

  const handleAreaSelect = (option: TSelectOption) => {
    setSelectedArea(option.value);
    setValue("area", option.label);

    if (selectedCity) {
      setSelectedCity(null);
      setValue("city", undefined);
    }
    if (selectedWarehouse) {
      setSelectedWarehouse(null);
      setValue("warehouse", undefined);
    }
  };
  const handleCitySelect = (option: TSelectOption) => {
    setSelectedCity(option.value);
    setValue("city", option.label);

    if (selectedWarehouse) {
      setSelectedWarehouse(null);
      setValue("warehouse", undefined);
    }
  };
  const handleWarehouseSelect = (option: TSelectOption) => {
    setSelectedWarehouse(option.value);
    setValue("warehouse", option.label);
  };

  const handleDisclaimerClose = () => {
    disaptch(setDislaimerPopupOpen(false));
    navigate(ROUTES.Home);
  };

  useEffect(() => {
    if (isPaynamentMethodFact) {
      if (promoCode && promoCode?.type === "PERCENTS") {
        const percentNow = (100 - promoCode.value) / 100;

        const originTotalPriceYe = Number(totalPriceYE) / percentNow;
        const resultYe =
          Number(originTotalPriceYe) -
          (Number(originTotalPriceYe) / 100) * (5 + promoCode.value);

        const originTotalPriceGRN = Number(totalPriceGRN) / percentNow;
        const resultGRN =
          Number(originTotalPriceGRN) -
          (Number(originTotalPriceGRN) / 100) * (5 + promoCode.value);

        setRealPriceGrn(resultGRN.toString());
        setRealPriceYe(resultYe.toString());
      } else {
        setRealPriceGrn(findPercent(totalPriceGRN));
        setRealPriceYe(findPercent(totalPriceYE));
      }
    } else {
      setRealPriceGrn(totalPriceGRN);
      setRealPriceYe(totalPriceYE);
    }
  }, [isPaynamentMethodFact]);

  const onSubmit = ({
    name,
    surname,
    phone,
    phone_code,
    area,
    city,
    warehouse,
    paymentMethod,
    comment,
    address,
  }: IFormData) => {
    if (!isValidPhoneNumber(phone)) {
      createNotification({
        type: "danger",
        title: "",
        message: `${i18n.t("invalidPhone")}`,
      });
      setErrorField("phone");
      return;
    }

    if (!orderType) {
      createNotification({
        type: "danger",
        title: "",
        message: `${i18n.t("chooseOrderType")}`,
      });
      setErrorField("orderType");
      return;
    }

    if (orderType === "DELIVERY" || orderType === "WAREHOUSE") {
      if (!area) {
        createNotification({
          type: "danger",
          title: "",
          message: `${i18n.t("chooseState")}`,
        });
        setErrorField("area");
        return;
      }
      if (!city) {
        createNotification({
          type: "danger",
          title: "",
          message: `${i18n.t("chooseCity")}`,
        });
        setErrorField("city");
        return;
      }
    }

    if (orderType === "WAREHOUSE") {
      if (!warehouse) {
        createNotification({
          type: "danger",
          title: "",
          message: `${i18n.t("chooseWarehouse")}`,
        });
        setErrorField("warehouse");
        return;
      }
    }

    const repsonse: IOrderData = {
      status: "Pending",
      paymentMethod: isPaynamentMethodFact ? "Fact" : "NonFact",
      orderPhone: `${phone_code + phone}`,
      orderUserName: `${name} ${surname}`,
      novaPoshtaOrderType: orderType,
      totalPriceGRN: realPriceGrn,
      totalPriceYE: realPriceYe,
    };

    comment && (repsonse.comment = comment);
    repsonse.paymentMethod === "Fact" && (repsonse.discount = 5);

    if (orderType === "DELIVERY" || orderType === "WAREHOUSE") {
      area && (repsonse.novaPoshtaState = area);
      city && (repsonse.novaPoshtaCity = city);
    }

    if (orderType === "DELIVERY") {
      address && (repsonse.novaPoshtaAdress = address);
    }

    if (orderType === "WAREHOUSE") {
      warehouse && (repsonse.novaPoshtaWarehouse = warehouse);
    }

    setErrorField(null);
    disaptch(createOrder(repsonse));
    reset();
  };

  return (
    <>
      <FormLayout
        onSubmit={handleSubmit(onSubmit)}
        button={i18n.t("processOrder")}
        isLoading={status === PROMISE_STATES.pending}
      >
        <FormLayoutItem width="fifty">
          <InputField
            label={`${i18n.t("name")}`}
            id="name"
            register={register("name")}
            placeholder={`${i18n.t("yourName")}`}
            required
          />
        </FormLayoutItem>
        <FormLayoutItem width="fifty">
          <InputField
            label={`${i18n.t("surname")}`}
            id="surname"
            register={register("surname")}
            placeholder={`${i18n.t("yourSurname")}`}
            required
          />
        </FormLayoutItem>
        <FormLayoutItem
          key={JSON.stringify(defaultPhoneCode)}
          width="thirty"
          selectMod
        >
          <SelectField
            label={i18n.t("phoneCode")}
            id="phone_code"
            onValueChange={(option) => handlePhoneCodeSelect(option.label)}
            options={phoneCodeOptions}
            defaultValue={defaultPhoneCode}
            name="phone_code"
            required
            mod="secondary"
          />
        </FormLayoutItem>
        <FormLayoutItem width="seventy">
          <InputField
            id="phone"
            isError={errorField === "phone"}
            register={register("phone")}
            label={`${i18n.t("phone")}`}
            placeholder={`${i18n.t("enter")} ${i18n.t("phone")}`}
            defaultValue={defaultPhone}
            type="tel"
            required
          />
        </FormLayoutItem>
        <FormLayoutItem selectMod>
          <SelectField
            isError={errorField === "orderType"}
            label={i18n.t("orderType")}
            placeholder={i18n.t("chooseOrderType")}
            id="order_type"
            onValueChange={(option) => handleOrderTypeSelect(option.value)}
            options={deliveryOptions}
            name="order_type"
            mod="secondary"
            required
          />
        </FormLayoutItem>

        {(orderType === "DELIVERY" || orderType === "WAREHOUSE") && (
          <>
            <FormLayoutItem selectMod>
              <SelectField
                onInputChange={(text) => onSearchAreas(text)}
                isLoading={areasLoading}
                isError={errorField === "area"}
                label={i18n.t("state")}
                placeholder={i18n.t("chooseState")}
                id="state"
                onValueChange={(option) => handleAreaSelect(option)}
                options={areas}
                name="state"
                mod="secondary"
                required
              />
            </FormLayoutItem>

            <FormLayoutItem selectMod>
              <SelectField
                onInputChange={(text) => onSearchCity(text)}
                isLoading={citiesLoading}
                isError={errorField === "city"}
                label={i18n.t("city")}
                placeholder={i18n.t("chooseCity")}
                key={selectedArea}
                id="city"
                isDisabled={!selectedArea}
                onValueChange={(option) => handleCitySelect(option)}
                options={cities}
                name="city"
                mod="secondary"
                required
              />
            </FormLayoutItem>
          </>
        )}

        {orderType === "PICKUP" && (
          <FormLayoutItem>
            <OrderingPickup />
          </FormLayoutItem>
        )}
        {orderType === "DELIVERY" && (
          <FormLayoutItem>
            <TextareaField
              label={`${i18n.t("address")}`}
              id="address"
              register={register("address")}
              placeholder={`${i18n.t("yourAddress")}`}
              required
            />
          </FormLayoutItem>
        )}
        {orderType === "WAREHOUSE" && (
          <FormLayoutItem selectMod>
            <SelectField
              onInputChange={(text) => onSearchWarehouses(text)}
              isLoading={warehousesLoading}
              isError={errorField === "warehouse"}
              label={i18n.t("warehouse")}
              placeholder={i18n.t("chooseWarehouse")}
              id="warehouse"
              key={selectedCity}
              isDisabled={!selectedCity}
              onValueChange={(option) => handleWarehouseSelect(option)}
              options={warehouses}
              name="warehouse"
              mod="secondary"
              required
            />
          </FormLayoutItem>
        )}

        <FormLayoutItem>
          <TextareaField
            label={`${i18n.t("comment")}`}
            id="comment"
            register={register("comment")}
            placeholder={`${i18n.t("yourComment")}`}
          />
        </FormLayoutItem>
      </FormLayout>
      <Popup
        size="xxs"
        isOpen={isDisclaimerPopupOpen}
        handleClose={handleDisclaimerClose}
      >
        <div className="order_section__content">
          <h4>{i18n.t("thanksForOrder")}</h4>
          {currentOrder && currentOrder.id && (
            <strong>
              {i18n.t("order")} № {currentOrder.id}
            </strong>
          )}
          <p>{i18n.t("orderInfo")}</p>
          <button onClick={handleDisclaimerClose} type="button">
            {i18n.t("goToHomePage")}
          </button>
        </div>
      </Popup>
    </>
  );
};

export default OrderingForm;
