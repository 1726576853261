import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectCatalog } from "../../store/catalog/repository/selectors";
import CategoriesList from "../../ui-kit/categoriesList/categoriesList";
import { findCatalogById } from "../../utils/findCatalogById";
import { TelegramButton } from "../../ui-kit/telegramButton/telegramButton";
import { useEffect, useState } from "react";

const CategoriesListPage = () => {
  const { id } = useParams();
  const [isShownTgButton, setShownTgButton] = useState(false);
  const catalog = useSelector(selectCatalog);
  const item = findCatalogById(parseInt(id || "0"), catalog);

  useEffect(() => {
    if (item?.isPartOfTgRadioBot) {
      setShownTgButton(true);
    } else {
      setShownTgButton(false);
    }
  }, [item]);

  return (
    <>
      <CategoriesList
        id={item?.id ?? 0}
        childCategories={item?.childCategories}
        name={item?.name ?? ""}
        description={item?.description}
      />
      {(item?.childCategories && isShownTgButton) &&
        <TelegramButton />}
    </> 

  );
};

export default CategoriesListPage;
