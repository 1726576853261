import { IReviewsResponse } from "./IReviewsResponse";

export const saveProductReviews = (payload: IReviewsResponse) => ({
  type: "SAVE_PRODUCT_REVIEWS",
  payload,
});

export const saveProductReviewsPage = (payload: number) => ({
  type: "SAVE_PRODUCT_REVIEWS_PAGE",
  payload,
});

export const setProductReviewsLoading = (payload: boolean) => ({
  type: "SET_PRODUCT_REVIEWS_LOADING",
  payload,
});

export const setProductReviewsPopupOpen = (payload: boolean) => ({
  type: "SET_PRODUCT_REVIEWS_POPUP_OPEN",
  payload,
});
