import { useDispatch } from "react-redux";
import i18n from "../../locales";
import { login } from "../../store/user/useCases/login/action";
import { ILoginData } from "../../store/user/repository/ILoginData";
import Input from "../input/input";
import PasswordInput from "../passwordInput/passwordInput";
import { createNotification } from "../../utils/createNotification";
import { FormLayout, FormLayoutItem } from "../formLayout";
import { useForm } from "react-hook-form";

const LoginForm = () => {
  const { register, handleSubmit } = useForm<ILoginData>();
  const dispatch = useDispatch();

  const onSubmit = (data: ILoginData) => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    const phoneRegex = /^\+?\d{9,15}$/;

    if (emailRegex.test(data.data) || phoneRegex.test(data.data)) {
      dispatch(login(data));
    } else {
      createNotification({
        title: "Email or Phone number is invalid",
        message: "",
        type: "danger",
      });
    }
  };

  return (
    <FormLayout onSubmit={handleSubmit(onSubmit)} button={i18n.t("login")}>
      <FormLayoutItem>
        <Input
          register={register("data")}
          placeholder={`${i18n.t("emailOrPhone")}`}
          required
          autoComplete="username"
        />
      </FormLayoutItem>
      <FormLayoutItem>
        <PasswordInput
          register={register("password")}
          placeholder={`${i18n.t("pass")}`}
          required
          autoComplete="current-password"
        />
      </FormLayoutItem>
    </FormLayout>
  );
};

export default LoginForm;
