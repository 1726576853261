import { CLASSES } from "../../constants";
import { useDispatch } from "react-redux";
import { saveCatalogHistory } from "../../store/catalog/repository/actions";
import "./burgerButton.scss";

const BurgerButton = () => {
  const dispatch = useDispatch();

  const handleMenuToggle = () => {
    document.body.classList.toggle(CLASSES.menuOpen);

    if (document.body.classList.contains(CLASSES.megamenuOpen)) {
      dispatch(saveCatalogHistory([]));
    }
  };

  return (
    <button
      onClick={handleMenuToggle}
      className="burger_button"
      type="button"
      aria-label="Menu trigger"
    >
      <span />
    </button>
  );
};

export default BurgerButton;
