import { RefObject, useEffect } from "react";
import useLatest from "./useLatest";

const useOutsideClick = <T extends Node>(
  elementRef: RefObject<T>,
  handler: () => void,
  attached: boolean = true,
  excludeRef?: RefObject<Node>
) => {
  const latestHandler = useLatest(handler);

  useEffect(() => {
    if (!attached) return;

    const handleClick = (e: MouseEvent) => {
      if (!elementRef.current) return;
      if (
        !elementRef.current.contains(e.target as Node) &&
        (!excludeRef ||
          !excludeRef.current ||
          !excludeRef.current.contains(e.target as Node))
      ) {
        latestHandler.current();
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [elementRef, latestHandler, attached, excludeRef]);
};

export default useOutsideClick;
