import { INewsResponse } from "./INewsResponse";
import { INews } from "./INews";

export const saveNews = (payload: INewsResponse) => ({
  type: "SAVE_NEWS",
  payload,
});
export const saveNewsPage = (payload: number) => ({
  type: "SAVE_NEWS_PAGE",
  payload,
});
export const setNewsLoading = (payload: boolean) => ({
  type: "SET_NEWS_LOADING",
  payload,
});

export const saveSingleNews = (payload: INews) => ({
  type: "SAVE_SINGLE_NEWS",
  payload,
});
export const setSingleNewsLoading = (payload: boolean) => ({
  type: "SET_SINGLE_NEWS_LOADING",
  payload,
});

export const setRatingPopupOpen = (payload: boolean) => ({
  type: "SET_RATING_POPUP_OPEN",
  payload,
});
export const saveVotedArticleId = (payload: number) => ({
  type: "SAVE_VOTED_ARTICLE_ID",
  payload,
});
