import { useSelector } from "react-redux";
import { FC, useMemo } from "react";
import { selectProductDescriptionLoading } from "../../store/products/repository/selectors";
import { IProductDescription } from "../../store/products/repository/IProductDescription";
import Loader from "../loader/loader";
import SingleColumn from "./singleColumn";
import DoubleColumn from "./doubleColumn";
import "./imagesDescriptions.scss";

interface IProps {
  descriptions: IProductDescription[];
}

const ImagesDescriptions: FC<IProps> = ({ descriptions }) => {
  const sortedDescription = useMemo(
    () => descriptions.slice().sort((a, b) => a.sortNumber - b.sortNumber),
    [descriptions]
  );

  const isDescriptionLoading = useSelector(selectProductDescriptionLoading);

  return (
    <div className="images_descriptions">
      {isDescriptionLoading && <Loader isLocal />}
      {sortedDescription.map(
        ({ id, type, main, secondary, backgroundColor, textColor }) => {
          return (
            <div
              style={{ color: textColor, backgroundColor }}
              key={id}
              className="images_descriptions__row"
            >
              {type === "DOUBLE" ? (
                <DoubleColumn main={main} secondary={secondary} />
              ) : (
                <SingleColumn
                  image={main?.image}
                  title={main?.title}
                  text={main?.description}
                />
              )}
            </div>
          );
        }
      )}
    </div>
  );
};

export default ImagesDescriptions;
