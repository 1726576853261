import { useMemo } from "react";
import { IProductWarehouse } from "../store/products/repository/IProductWarehouse";
import { IUser } from "../store/user/repository/IUser";
import { PRODUCT_AVAILABLE } from "../constants";

const useProductAvailable = (
  warehouse: IProductWarehouse | undefined | null,
  isAvailable: boolean = false,
  user: IUser | null
) => {
  const productAvailable = useMemo(() => {
    if (user && warehouse) {
      if (warehouse?.userCityProductsAmount > 0) {
        return PRODUCT_AVAILABLE.available;
      } else if (warehouse?.userAnotherCityAmount > 0) {
        return PRODUCT_AVAILABLE.toOrder;
      } else {
        return PRODUCT_AVAILABLE.unavailable;
      }
    } else {
      if (isAvailable) {
        return PRODUCT_AVAILABLE.available;
      } else {
        return PRODUCT_AVAILABLE.unavailable;
      }
    }
  }, [user, warehouse, isAvailable]);

  return { productAvailable };
};

export default useProductAvailable;
