import classNames from "classnames";
import { FC, useEffect, useRef, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { gsap } from "gsap";
import './promoDetails.scss';
import i18n from "../../../../locales";

interface IProps {
    value: string;
};

const PromoDetails: FC<IProps> = ({ value }) => {
    const [isActive, setActive] = useState(false);
    const $content = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const target = $content.current;

        if (isActive) {
            gsap.to(target, {
                height: "auto",
                duration: 0.3,
            });

            return () => {
                gsap.to(target, {
                    height: "0",
                    duration: 0.3,
                });
            };
        }
        return () => { };
    }, [isActive]);

    const handleClick = () => {
        setActive((prev) => !prev);
    };

    return (
        <div
            className={classNames("promotion_item", { "promotion_item--active_state": isActive, "promotion_item--read_state": !isActive })} >
            <button type="button" className="promotion_item__button" onClick={handleClick}>
                <div className="promotion_item__main">
                    <div className="promotion_item__title">{i18n.t('promoDetails')}</div>
                    <div className="promotion_item__arrow font_icon">
                        <FiChevronDown />
                    </div>
                </div>
            </button>
            <div ref={$content} className="promotion_item__content">
                <div className="promotion_item__footer">
                    {value}
                </div>
            </div>
        </div>
    )
};

export default PromoDetails;