import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import {
  saveSingleProduct,
  setSingleProductLoading,
} from "../../repository/actions";
import { createNotification } from "../../../../utils/createNotification";

function* getSingleProductWorker({ payload }: any): SagaIterator {
  try {
    yield put(setSingleProductLoading(true));
    const { data, isError, message } = yield call(
      requester.get,
      `${links.singleProduct}/${payload}`
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(saveSingleProduct(data.data));
    }
  } catch (error) {
    console.error("getSingleProductWorker: ", error);
  } finally {
    yield put(setSingleProductLoading(false));
  }
}

export function* getSingleProductWatcher() {
  yield takeLatest("@saga/GET_SINGLE_PRODUCT", getSingleProductWorker);
}
