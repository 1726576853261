import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectisLoggedIn } from "../../store/user/repository/selectors";
import ProfileWrapper from "../../ui-kit/profileWrapper/profileWrapper";
import NotificationSection from "../../ui-kit/notificationSection/notificationSection";
import { getNotifications } from "../../store/notifications/useCases/getNotifications/action";

const NotificationPage = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectisLoggedIn);

  useEffect(() => {
    if (isLoggedIn !== "true") return;

    dispatch(getNotifications());
  }, [isLoggedIn]);

  return (
    <ProfileWrapper>
      <NotificationSection />
    </ProfileWrapper>
  );
};

export default NotificationPage;
