import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleNews } from "../../store/news/useCases/getSingleNews/action";
import SingleNewsSection from "../../ui-kit/singleNewsSection/singleNewsSection";

const SingleNewsPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getSingleNews(Number(id)));
  }, []);

  return <SingleNewsSection />;
};

export default SingleNewsPage;
