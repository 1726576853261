import { IRootState } from "../../store";

export const selectProductReviews = (state: IRootState) =>
  state.reviews.productReviews;
export const selectProductReviewsPage = (state: IRootState) =>
  state.reviews.productReviewsPage;
export const selectProductReviewsLoading = (state: IRootState) =>
  state.reviews.productReviewsLoading;
export const selectProductReviewsPopupOpen = (state: IRootState) =>
  state.reviews.productReviewsPopupOpen;
