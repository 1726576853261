import i18n from "../../locales";
import { FC } from "react";
import { ROUTES } from "../../constants";
import Section from "../../ui-kit/section/section";
import { Link } from "react-router-dom";
import "./InfoEmailPage.scss";

interface IProps {
  success?: boolean;
}

export const InfoEmailPage: FC<IProps> = ({ success }) => {
  return (
    <Section className="info_email">
      <h1>
        {success ? i18n.t("successConfirmEmail") : i18n.t("failConfirmEmail")}
      </h1>
      <Link to={ROUTES.Home}>
        <span>{i18n.t("goToHomePage")}</span>
      </Link>
    </Section>
  );
};

export default InfoEmailPage;
