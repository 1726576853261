import { ICatalog } from "./ICatalog";
import { ICatalogHistory } from "./ICatalogHistory";

interface IInitialState {
  catalog: ICatalog[];
  catalogHistory: ICatalogHistory[];
}

const initialState: IInitialState = {
  catalog: [],
  catalogHistory: [],
};

export function catalogReducer(
  state: IInitialState = initialState,
  action: any
): IInitialState {
  switch (action.type) {
    case "SAVE_CATALOG":
      return { ...state, catalog: action.payload };

    case "SAVE_CATALOG_HISTORY":
      return { ...state, catalogHistory: action.payload };

    default:
      return state;
  }
}
