import { IResponseLanguage } from "./IResponseLanguage";
import { DEFAULT_LANGUAGE } from "../../../constants";

interface IInitialState {
  languages: IResponseLanguage;
  currentLang: string;
}

const initialState: IInitialState = {
  languages: {
    data: [],
    count: 0,
  },
  currentLang: DEFAULT_LANGUAGE,
};

export function languagesReducer(
  state: IInitialState = initialState,
  action: any
): IInitialState {
  switch (action.type) {
    case "SAVE_LANGUAGES":
      return { ...state, languages: action.payload };

    case "SAVE_CURRENT_LANG":
      return { ...state, currentLang: action.payload };

    default:
      return state;
  }
}
