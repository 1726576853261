import { IPromoProducts } from "./IPromoProducts";

export const savePromoProducts = (payload: IPromoProducts) => ({
  type: "SAVE_PROMO_PRODUCTS",
  payload,
});

export const savePromotionList = (payload: any) => ({
  type: "SAVE_PROMO_LIST",
  payload,
});

export const saveSinglePromo = (payload: IPromoProducts) => ({
  type: "SAVE_SINGLE_PROMO",
  payload,
});

export const setPromoProductsLoading = (payload: boolean) => ({
  type: "SET_PROMO_PRODUCTS_LOADING",
  payload,
});
export const setHiddenPromoProductsLoading = (payload: boolean) => ({
  type: "SET_HIDDEN_PROMO_PRODUCTS_LOADING",
  payload,
});
