import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";
import { setAppLoading } from "../../../app/repository/actions";
import {
  saveRegisterData,
  saveRestoreData,
  saveToken,
  setConfirmation,
  setAuthType,
} from "../../repository/actions";
import { LOCAL_STORAGE } from "../../../../constants";
import { IRegisterData } from "../../repository/IRegisterData";
import i18n from "../../../../locales";

interface IRegisterWorker {
  type: string;
  payload: IRegisterData;
}

function* registerWorker({ payload }: IRegisterWorker): SagaIterator {
  try {
    yield put(setAppLoading(true));
    const { data, isError, message } = yield call(
      requester.post,
      links.register,
      payload
    );

    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else if (
      data.message === "this_phone_number_is_already_in_use" ||
      data.message === "this_email_is_already_in_use" ||
      data.message === "today_max_phone_code_amount_expired"
    ) {
      createNotification({
        title: i18n.t("error"),
        message: i18n.t(data.message),
        type: "danger",
      });
    } else {
      if (data.token) {
        yield put(saveRegisterData(payload));
        yield put(saveRestoreData(null));

        yield put(setConfirmation(true));
        yield put(saveToken(data.token));
        yield put(setAuthType("register"));
        localStorage.setItem(LOCAL_STORAGE.token, data.token);
        localStorage.setItem(LOCAL_STORAGE.confirmMod, "phone");
      } else {
        createNotification({
          title: i18n.t("error"),
          message: i18n.t(data.message),
          type: "danger",
        });
      }
    }
  } catch (error) {
    console.error("registrationWorker: ", error);
  } finally {
    yield put(setAppLoading(false));
  }
}

export function* registerWatcher() {
  yield takeLatest("@saga/REGISTER", registerWorker);
}
