import { FC, ReactNode } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import Input, { IProps as InputProps } from "../input/input";
import Label from "../label/label";
import "./inputField.scss";

interface IProps extends InputProps {
  id: string;
  label: ReactNode;
  register: UseFormRegisterReturn;
  isError?: boolean;
}

const InputField: FC<IProps> = ({
  id,
  label,
  register,
  required,
  ...props
}) => {
  return (
    <div className="input_field">
      <Label id={id} required={required}>
        {label}
      </Label>
      <Input register={register} required={required} id={id} {...props} />
    </div>
  );
};

export default InputField;
