import i18n from "../../locales";
import classNames from "classnames";
import { FC, ReactNode, HTMLProps, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../store/user/useCases/register/action";
import { restorePass } from "../../store/user/useCases/restorePass/action";
import {
  selectRegisterData,
  selectRestoreData,
} from "../../store/user/repository/selectors";
import { LOCAL_STORAGE } from "../../constants";
import { createNotification } from "../../utils/createNotification";
import "./formLayout.scss";

interface IProps extends HTMLProps<HTMLFormElement> {
  button: string;
  children: ReactNode;
  resendButton?: boolean;
  isLoading?: boolean;
}

const FormLayout: FC<IProps> = ({
  children,
  button,
  resendButton,
  isLoading,
  ...props
}) => {
  const timeout = 60;
  const [resendCount, setResendCount] = useState(0);
  const [countdown, setCountdown] = useState(timeout);
  const dispatch = useDispatch();
  const registerData = useSelector(selectRegisterData);
  const restoreData = useSelector(selectRestoreData);
  const isConfirmTypeEmail =
    localStorage.getItem(LOCAL_STORAGE.confirmMod) === "email";

  const startTimer = () => {
    const startTime = Date.now();
    const timer = setInterval(() => {
      const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
      const remainingTime = timeout - elapsedTime;
      if (remainingTime >= 0) {
        setCountdown(remainingTime);
      } else {
        clearInterval(timer);
      }
    }, 1000);
  };

  const handleResendClick = () => {
    if (resendCount < 5) {
      setResendCount((prev) => prev + 1);
      setCountdown(timeout);

      if (registerData !== null) {
        dispatch(registerUser(registerData));
        createNotification({
          title: "",
          message: i18n.t("codeAgainSend"),
          type: "success",
        });
      } else if (restoreData !== null) {
        dispatch(restorePass(restoreData));
        createNotification({
          title: "",
          message: i18n.t(
            isConfirmTypeEmail ? "codeAgainSendEmail" : "codeAgainSend"
          ),
          type: "success",
        });
      }

      startTimer();
    } else {
      createNotification({
        title: "",
        message: i18n.t("maxResendCount"),
        type: "danger",
      });
    }
  };

  useEffect(() => {
    startTimer();
  }, []);

  return (
    <form
      className={classNames("form_layout", {
        "form_layout--disabled_mod": isLoading,
      })}
      {...props}
    >
      <div className="form_layout__body">
        <div className="form_layout__row">{children}</div>
      </div>
      <div className="form_layout__footer">
        {resendButton && (
          <button
            onClick={handleResendClick}
            className={classNames("form_layout__resend_button", {
              "form_layout__resend_button--disabled_state": countdown > 0,
            })}
            type="button"
          >
            <span>
              {i18n.t("resendCode")} {countdown !== 0 && `(${countdown})`}
            </span>
          </button>
        )}
        <button className="form_layout__button" type="submit">
          <span>{button}</span>
        </button>
      </div>
    </form>
  );
};

export default FormLayout;
