import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import {
  saveToken,
  saveUser,
  setAuthPopupOpen,
  setLoggedIn,
  setAuthType,
} from "../../repository/actions";
import { createNotification } from "../../../../utils/createNotification";
import { setAppLoading } from "../../../app/repository/actions";
import { LOCAL_STORAGE } from "../../../../constants";
import { ILoginData } from "../../repository/ILoginData";
import i18n from "../../../../locales";

interface ILoginWorker {
  type: string;
  payload: ILoginData;
}

function* loginWorker({ payload }: ILoginWorker): SagaIterator {
  try {
    yield put(setAppLoading(true));
    const { data, isError, message } = yield call(
      requester.post,
      links.login,
      payload
    );

    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else if (
      data.message === "login_or_password_wrong" ||
      data.message === "user_not_found "
    ) {
      createNotification({
        title: i18n.t("error"),
        message: i18n.t(data.message),
        type: "danger",
      });
    } else {
      if (!data.token) return;

      yield put(saveUser(data.user));
      localStorage.setItem(LOCAL_STORAGE.user, JSON.stringify(data.user));
      yield put(saveToken(data.token));
      localStorage.setItem(LOCAL_STORAGE.token, data.token);
      yield put(setLoggedIn("true"));
      localStorage.setItem(LOCAL_STORAGE.loggedIn, "true");
      yield put(setAuthType("login"));

      yield put(setAuthPopupOpen(false));

      createNotification({
        title: i18n.t("success"),
        message: "",
        type: "success",
      });
    }
  } catch (error) {
    console.error("loginWorker: ", error);
  } finally {
    yield put(setAppLoading(false));
  }
}

export function* loginWatcher() {
  yield takeLatest("@saga/LOGIN", loginWorker);
}
