import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveNewsPage } from "../../store/news/repository/actions";
import {
  selectNewsLoading,
  selectNewsPage,
} from "../../store/news/repository/selectors";
import { selectNews } from "../../store/news/repository/selectors";
import { PRODUCTS_PER_PAGE } from "../../constants";
import { getNews } from "../../store/news/useCases/getNews/action";
import i18n from "../../locales";
import NewsCard from "../newsCard/newsCard";
import Section from "../section/section";
import Pagination from "../pagination/pagination";
import Loader from "../loader/loader";
import "./newsSection.scss";

const NewsSection = () => {
  const { data, count } = useSelector(selectNews);
  const currentPage = useSelector(selectNewsPage);
  const dispatch = useDispatch();

  const [activeItem, setActiveItem] = useState(currentPage ?? 1);
  const isNewsLoading = useSelector(selectNewsLoading);

  const paginationClick = (page: number) => {
    dispatch(getNews(page));
    dispatch(saveNewsPage(page));
    setActiveItem(page);
  };

  return (
    <Section className="news_section">
      {isNewsLoading && <Loader />}
      <h1 className="news_section__title">{i18n.t("newsAndArticles")}</h1>
      <ul className="news_section__list">
        {data &&
          data.map((item) => {
            return (
              <li key={item.id} className="news_section__item">
                <NewsCard item={item} />
              </li>
            );
          })}
      </ul>
      {count > PRODUCTS_PER_PAGE && (
        <Pagination
          pagesCount={count}
          onClick={paginationClick}
          activeItem={activeItem}
        />
      )}
    </Section>
  );
};

export default NewsSection;
