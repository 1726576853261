import classNames from "classnames";
import { FC, ReactNode } from "react";

interface IProps {
  children: ReactNode;
  selectMod?: boolean;
  width?: "full" | "thirty" | "seventy" | "fifty";
}

const FormLayoutItem: FC<IProps> = ({
  children,
  selectMod,
  width = "full",
}) => {
  return (
    <div
      className={classNames("form_layout__item", {
        "form_layout__item--select_mod": selectMod,
        "form_layout__item--full_mod": width === "full",
        "form_layout__item--thirty_mod": width === "thirty",
        "form_layout__item--seventy_mod": width === "seventy",
        "form_layout__item--fifty_mod": width === "fifty",
      })}
    >
      {children}
    </div>
  );
};

export default FormLayoutItem;
