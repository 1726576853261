import classNames from "classnames";
import "./image.scss";

interface IProps {
  src: string;
  contain?: boolean;
  alt?: string;
  imgClass?: string;
  mobileImage?: string;
  webp?: string;
  mediaPoint?: number;
  onClick?: () => void;
}

const Image = ({
  src,
  contain,
  alt,
  imgClass,
  mobileImage,
  webp,
  mediaPoint,
  onClick,
}: IProps) => {
  // const getExt = (name: string) => name.match(/\.([^.]+)$/)?.[1];
  // const imgType = getExt(src);
  // const mobImageType = mobileImage ? getExt(mobileImage) : null;
  const mobileMedia = mobileImage
    ? `(min-width:${mediaPoint ? mediaPoint : 1024}px)`
    : undefined;

  const pictureClasses = classNames("picture", imgClass);
  const imgClasses = classNames("image", {
    "image--contain_mod": contain,
  });

  return (
    <picture onClick={onClick ? onClick : undefined} className={pictureClasses}>
      {/* <source srcSet={src} type={`image/${imgType}`} media={mobileMedia} /> */}
      {mobileImage ? (
        <source
          srcSet={mobileImage}
          media={`(max-width:${mediaPoint ? mediaPoint - 1 : 1023}px)`}
          // type={`image/${mobImageType} `}
        />
      ) : null}
      {webp ? (
        <source srcSet={src} type="image/webp" media={mobileMedia} />
      ) : null}
      <img className={imgClasses} src={src} alt={alt} loading="lazy" />
    </picture>
  );
};

export default Image;
