import { useState, FC } from "react";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { useMedia } from "../../customHooks";
import { Link } from "react-router-dom";
import { CLASSES, ROUTES } from "../../constants";
import { ICatalog } from "../../store/catalog/repository/ICatalog";
import { FiChevronDown } from "react-icons/fi";
import { IconType } from "react-icons/lib";
import Dropdown from "../dropdown/dropdown";
import "./dropdownItem.scss";

interface IProps {
  item: ICatalog;
  depthLevel: number;
  Icon?: IconType;
}

const DropdownItem: FC<IProps> = ({ item, depthLevel, Icon }) => {
  const [isOpen, setOpen] = useState(false);
  const isMobile = useMedia();
  const location = useLocation();

  const handleLinkClick = () => {
    document.body.classList.contains(CLASSES.menuOpen) &&
      document.body.classList.remove(CLASSES.menuOpen);
  };

  const onMouseEnter = () => {
    if (!isMobile) setOpen(true);
  };
  const onMouseLeave = () => {
    if (!isMobile) setOpen(false);
  };

  const handleClick = () => {
    if (isMobile) setOpen((open) => !open);
  };

  const { childCategories, name, url } = item;
  const dropdownItemClasses = classNames("dropdown_item", {
    "dropdown_item--open_state": isOpen,
  });

  return (
    <div className={dropdownItemClasses}>
      {childCategories?.length && depthLevel !== 1 ? (
        <div
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className="dropdown_item__wrap"
        >
          <button
            type="button"
            aria-expanded={isOpen ? "true" : "false"}
            onClick={handleClick}
            onKeyDown={handleClick}
            tabIndex={0}
            className="dropdown_item__link"
          >
            {Icon && (
              <span className="dropdown_item__icon font_icon">
                <Icon />
              </span>
            )}
            <span className="dropdown_item__text">{name}</span>
            <span className="dropdown_item__arrow font_icon">
              <FiChevronDown />
            </span>
          </button>
          <Dropdown
            items={childCategories}
            isOpen={isOpen}
            depthLevel={depthLevel}
          />
        </div>
      ) : (
        <Link
          to={url ?? ROUTES.NotFoundLink}
          onClick={handleLinkClick}
          className={classNames("dropdown_item__link", {
            "dropdown_item__link--current_state": location.pathname.includes(
              url ?? ROUTES.NotFoundLink
            ),
          })}
        >
          <span className="dropdown_item__text">{name}</span>
        </Link>
      )}
    </div>
  );
};

export default DropdownItem;
