import { FC } from "react";
import Rating from "../rating/rating";
import "./reviewItem.scss";

interface IProps {
  name: string;
  date: string;
  rating: number;
  comment: string;
}

const ReviewItem: FC<IProps> = ({ name, date, rating, comment }) => {
  return (
    <div className="review_item">
      <div className="review_item__head">
        <div className="review_item__wrap">
          <div className="review_item__name">{name}</div>
          <div className="review_item__rating">
            <Rating size="small" rating={rating} />
          </div>
        </div>
        <div className="review_item__date">{date}</div>
      </div>
      <div className="review_item__comment">{comment}</div>
    </div>
  );
};

export default ReviewItem;
