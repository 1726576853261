import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isValidPhoneNumber } from "../../utils/isValidPhoneNumber";
import { getCountries, getStates } from "country-state-picker";
import i18n from "../../locales";
import { registerUser } from "../../store/user/useCases/register/action";
import { createNotification } from "../../utils/createNotification";
import { IRegisterData } from "../../store/user/repository/IRegisterData";
import { FormLayout, FormLayoutItem } from "../formLayout";
import Checkbox from "../checkbox/checkbox";
import InputField from "../inputField/inputField";
import SelectField from "../selectField/selectField";
import { selectCurrentLang } from "../../store/language/repository/selectors";
import { DEFAULT_LANGUAGE } from "../../constants";

interface IRegionOption {
  label: string;
  value: string;
  phone: string;
}

interface IPhoneOption {
  label: string;
  value: string;
  country: string;
}

interface ICountryOption {
  name: string;
  code: string;
  dial_code: string;
}

const RegisterForm = () => {
  const [country, setCountry] = useState<IRegionOption | null>(null);
  const [errorField, setErrorField] = useState<string | null>(null);
  const [defaultPhoneCode, setDefaultPhoneCode] = useState<IPhoneOption | null>(
    null
  );
  const [regionData, setRegionData] = useState<IRegionOption[]>([]);

  const { register, handleSubmit, setValue } = useForm<IRegisterData>({
    mode: "onChange",
  });
  const dispatch = useDispatch();
  const currentLang = useSelector(selectCurrentLang);

  const termsUaLink =
    "https://doc-hosting.flycricket.io/politika-konfidentsiinosti/c520b6bb-e997-42cc-a679-531b8c43fec7/privacy";
  const termsEnLink =
    "https://doc-hosting.flycricket.io/apulse-terms-of-use/b4d60be8-920c-4def-9a4f-297c5b88e9e9/terms";

  const countryOptions = useMemo(() => {
    return getCountries().map(({ name, code, dial_code }: ICountryOption) => ({
      label: name,
      value: code,
      phone: dial_code,
    }));
  }, []);

  const phoneCodeOptions = useMemo(() => {
    return countryOptions.map(({ phone, value }: IRegionOption) => ({
      label: phone,
      value: phone,
      country: value,
    }));
  }, []);

  const handleCountrySelect = (value: IRegionOption) => {
    setValue("country", value.label);
    setErrorField(null);

    const regions = getStates(value.value);
    const regionOptions = regions.map((region: string) => {
      return {
        label: region,
        value: region,
      };
    });

    setRegionData(regionOptions);

    const currentOption = phoneCodeOptions.find(
      ({ country }: IPhoneOption) => country === value.value
    );

    if (currentOption) {
      setDefaultPhoneCode(currentOption);
      setValue("phoneCode", currentOption.label);
    }

    setCountry(value);
  };

  const handleRegionSelect = (value: string) => {
    setValue("city", value);
    setErrorField(null);
  };
  const handlePhoneCodeSelect = (value: string) => {
    setValue("phoneCode", value);
  };

  const findDefaultState = () => {
    const currentLang = "ua";
    const option = countryOptions.find(
      (item: IPhoneOption) => item.value === currentLang
    );

    if (option) {
      setTimeout(() => {
        handleCountrySelect(option);
      }, 10);
      return option;
    }
    return undefined;
  };

  const onSubmit = ({
    country,
    city,
    phone,
    phoneCode,
    name,
    email,
    companyName,
  }: IRegisterData) => {
    if (!country) {
      createNotification({
        type: "danger",
        title: "",
        message: `${i18n.t("chooseCountry")}`,
      });
      setErrorField("country");
      return;
    }

    if (!city) {
      createNotification({
        type: "danger",
        title: "",
        message: `${i18n.t("chooseCity")}`,
      });
      setErrorField("city");
      return;
    }

    if (!isValidPhoneNumber(phone)) {
      createNotification({
        type: "danger",
        title: "",
        message: `${i18n.t("invalidPhone")}`,
      });
      setErrorField("phone");
      return;
    }

    const request: IRegisterData = {
      name,
      phone,
      phoneCode,
      country,
      state: city,
      city,
    };

    if (email !== "") {
      request.email = email;
    }
    if (companyName !== "") {
      request.companyName = companyName;
    }

    setErrorField(null);
    dispatch(registerUser({ ...request, phone: phoneCode + phone }));
  };

  return (
    <FormLayout onSubmit={handleSubmit(onSubmit)} button={i18n.t("register")}>
      <FormLayoutItem>
        <InputField
          label={`${i18n.t("name")}`}
          id="register_name"
          register={register("name")}
          placeholder={`${i18n.t("yourName")}`}
          required
        />
      </FormLayoutItem>
      <FormLayoutItem selectMod>
        <SelectField
          isError={errorField === "country"}
          id="register_country"
          onValueChange={(option) => handleCountrySelect(option)}
          options={countryOptions}
          label={i18n.t("country")}
          defaultValue={findDefaultState}
          name="country"
          required
        />
      </FormLayoutItem>
      <FormLayoutItem key={JSON.stringify(regionData[0])} selectMod>
        <SelectField
          isError={errorField === "city"}
          onValueChange={(option) => handleRegionSelect(option.label)}
          options={regionData}
          isDisabled={country === null}
          label={i18n.t("region")}
          name="city"
          id="register_city"
          required
        />
      </FormLayoutItem>
      <FormLayoutItem
        key={JSON.stringify(defaultPhoneCode)}
        width="thirty"
        selectMod
      >
        <SelectField
          label={i18n.t("phoneCode")}
          id="phoneCode"
          onValueChange={(option) => handlePhoneCodeSelect(option.label)}
          options={phoneCodeOptions}
          defaultValue={defaultPhoneCode}
          name="phoneCode"
          required
        />
      </FormLayoutItem>
      <FormLayoutItem width="seventy">
        <InputField
          isError={errorField === "phone"}
          id="register_phone"
          register={register("phone")}
          label={`${i18n.t("phone")}`}
          placeholder={`${i18n.t("enter")} ${i18n.t("phone")}`}
          type="tel"
          required
        />
      </FormLayoutItem>
      <FormLayoutItem>
        <InputField
          id="register_email"
          register={register("email")}
          type="email"
          label="Email"
          placeholder={`${i18n.t("enter")} Email`}
        />
      </FormLayoutItem>
      <FormLayoutItem>
        <InputField
          id="register_company_name"
          register={register("companyName")}
          label={`${i18n.t("company")}`}
          placeholder={`${i18n.t("enterCompany")}`}
        />
      </FormLayoutItem>
      <FormLayoutItem>
        <Checkbox
          variant="secondary"
          id="register_terms"
          value="terms"
          text={
            <>
              {i18n.t("terms")}{" "}
              <a
                target="_blank"
                href={
                  currentLang === DEFAULT_LANGUAGE ? termsUaLink : termsEnLink
                }
                rel="noreferrer"
              >
                {i18n.t("termsWord")}.
              </a>
            </>
          }
          required
        />
      </FormLayoutItem>
    </FormLayout>
  );
};

export default RegisterForm;
