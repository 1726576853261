import { FC } from "react";
import i18n from "../../locales";
import {
  IProductComponents,
  IProductComponentsValues,
} from "../../store/products/repository/IProductComponents";
import "./availableMagnitolsTable.scss";

interface IGetAmount {
  values: IProductComponentsValues[];
  productId: number;
  warehouseId: number;
}

const getAmount = ({ values, productId, warehouseId }: IGetAmount) => {
  const foundElement = values?.find((element) => {
    return (
      element.productId === productId && element.warehouseId === warehouseId
    );
  });

  return foundElement ? foundElement.value : 0;
};

const AvailableMagnitolsTable: FC<IProductComponents> = ({
  values,
  warehouses,
  products,
}) => {
  return (
    <div className="available_magnitols_table">
      <table className="available_magnitols_table__in">
        <thead>
          <tr>
            <td />
            {products?.map(({ id, name }) => {
              return <td key={id}>{name}</td>;
            })}
          </tr>
          <tr>
            <td>{i18n.t("storage")}</td>
            <td colSpan={100}>{i18n.t("availability")}</td>
          </tr>
        </thead>
        <tbody>
          {warehouses?.map(({ id: warehouseId, name }) => {
            return (
              <tr key={warehouseId}>
                <td>{name}</td>
                {products?.map(({ id: productId }) => {
                  const currentAmount = getAmount({
                    productId,
                    warehouseId,
                    values,
                  });

                  return (
                    <td key={productId}>
                      {currentAmount} {i18n.t("piece")}
                    </td>
                  );
                })}
              </tr>
            );
          })}

          <tr>
            <td>{i18n.t("allWarehouses")}</td>
            {products?.map(({ id, amount }) => {
              return (
                <td key={id}>
                  {amount} {i18n.t("piece")}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AvailableMagnitolsTable;
