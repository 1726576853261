import classNames from "classnames";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  selectConfirmation,
  selectPasswordPopupOpen,
} from "../../store/user/repository/selectors";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import i18n from "../../locales";
import ForgotPasswordForm from "../forgotPasswordForm/forgotPasswordForm";
import LoginForm from "../loginForm/loginForm";
import RegisterForm from "../registerForm/registerForm";
import ConfirmCodeForm from "../confirmCodeForm/confirmCodeForm";
import CreatePasswordForm from "../createPasswordForm/createPasswordForm";
import "./authTabs.scss";

const LoginTabs = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [isNavHidden, setNavHidden] = useState(false);
  const isConfirmation = useSelector(selectConfirmation);
  const isPasswordPopupOpen = useSelector(selectPasswordPopupOpen);

  const handleForgotPasswordClick = () => {
    setTabIndex(2);
  };

  const handleConfirmOpen = () => {
    setTabIndex(3);
    setNavHidden(true);
  };

  const handlePasswordOpen = () => {
    setTabIndex(4);
    setNavHidden(true);
  };

  useEffect(() => {
    if (isConfirmation) handleConfirmOpen();
  }, [isConfirmation]);

  useEffect(() => {
    if (isPasswordPopupOpen) handlePasswordOpen();
  }, [isPasswordPopupOpen]);

  return (
    <Tabs
      className="auth_tabs"
      selectedIndex={tabIndex}
      onSelect={(index) => setTabIndex(index)}
    >
      <nav
        className={classNames("auth_tabs__nav", {
          "auth_tabs__nav--hidden_state": isNavHidden,
        })}
      >
        <TabList className="auth_tabs__list">
          <Tab className="auth_tabs__item">
            <button type="button" className="auth_tabs__button">
              {i18n.t("login")}
            </button>
          </Tab>
          <Tab className="auth_tabs__item">
            <button type="button" className="auth_tabs__button">
              {i18n.t("register")}
            </button>
          </Tab>
          <Tab className="auth_tabs__item auth_tabs__item--hidden_mod" />
          <Tab className="auth_tabs__item auth_tabs__item--hidden_mod" />
          <Tab className="auth_tabs__item auth_tabs__item--hidden_mod" />
        </TabList>
      </nav>
      <div className="auth_tabs__panels">
        <TabPanel>
          <LoginForm />
        </TabPanel>
        <TabPanel>
          <RegisterForm />
        </TabPanel>
        <TabPanel>
          <ForgotPasswordForm />
        </TabPanel>
        <TabPanel>
          <ConfirmCodeForm />
        </TabPanel>
        <TabPanel>
          <CreatePasswordForm />
        </TabPanel>
      </div>
      <div
        className={classNames("auth_tabs__footer", {
          "auth_tabs__footer--hidden_state": tabIndex === 1 || isNavHidden,
        })}
      >
        <button
          onClick={handleForgotPasswordClick}
          type="button"
          className={classNames("auth_tabs__forgot", {
            "auth_tabs__forgot--active_state": tabIndex === 2,
          })}
        >
          {i18n.t("forgotPass")}?
        </button>
      </div>
    </Tabs>
  );
};

export default LoginTabs;
