import i18n from "../../locales";
import classNames from "classnames";
import { selectisLoggedIn } from "../../store/user/repository/selectors";
import { selectUser } from "../../store/user/repository/selectors";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useProductAvailable } from "../../customHooks";
import { ROUTES, ROLES, PRODUCT_AVAILABLE, LOCAL_STORAGE } from "../../constants";
import { transformPrice } from "../../utils/transformPrice";
import { IProduct } from "../../store/products/repository/IProduct";
import { ICharacteristic } from "../../store/products/repository/ICharacteristic";
import { AiOutlineFilePdf } from "react-icons/ai";
import { Link } from "react-router-dom";
import Rating from "../rating/rating";
import LikeButton from "../likeButton/likeButton";
import SanitaizedText from "../sanitaizedText/sanitaizedText";
import Characteristics from "../characteristics/characteristics";
import BuyButton from "../buyButton/buyButton";
import AvailableTable from "../availableTable/availableTable";
import AvailableMagnitolsTable from "../availableMagnitolsTable/availableMagnitolsTable";
import Image from "../image/image";
import { FaChevronRight } from "react-icons/fa";
import "./productDescription.scss";
import { ProductCardTimer } from "../productCardTimer/productCardTimer";

import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { selectCart } from "../../store/orders/repository/selectors";
import { updateCartProduct } from "../../store/orders/useCases/updateCartProduct/action";
import { setCartChange } from "../../store/orders/repository/actions";
import { TCartAction } from "../cartProduct/cartProduct";
import { ICartProduct } from "../../store/orders/repository/ICartProduct";

interface IProps {
  productData: IProduct;
};

const ProductDescription: FC<IProps> = ({ productData }) => {
  const {
    name,
    code1c,
    isAvailable,
    isFavorite,
    shortDescription,
    instruction,
    rating,
    price,
    wholesalePrice,
    largeWholesalePrice,
    minimalCount,
    option,
    promo,
    isSale,
    manufacturer,
    warehouse,
    components,
    oldPrice,
    oldWholesalePrice,
    oldLargeWholesalePrice,
    initialCount
  } = productData;

  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [shouldTableRender, setShouldTableRender] = useState<boolean>(false);
  const [shouldMagnitolsTableRender, setShouldMagnitolsTableRender] = useState<boolean>(false);

  const { id } = useParams();
  const isLoggedIn = useSelector(selectisLoggedIn);
  const user = useSelector(selectUser);
  const { productAvailable } = useProductAvailable(warehouse, isAvailable, user);
  const priceData: ICharacteristic[] = [];
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) return;

    const isSuperAdmin = user.roles.some(
      (role) => role.name === ROLES.superAdmin
    );

    const isManager = user.roles.some((role) => role.name === ROLES.manager);

    if (isSuperAdmin || isManager) {
      setIsAdmin(true);
    }
  }, [user]);

  useEffect(() => {
    if (components) {
      setShouldMagnitolsTableRender(
        isAdmin && components?.warehouses?.length > 0
      );
    }

    if (!isAdmin || !warehouse) return;

    setShouldTableRender(
      isAdmin &&
      warehouse?.userCityProductWarehouse?.length > 0 &&
      warehouse?.allWarehouseAmount !== 0
    );
  }, [components, warehouse, isAdmin]);

  const addPriceData = (value: number | undefined, keyTranslation: string, oldValue: number | undefined) => {
    const currency =
      keyTranslation === "retailPrice" ? i18n.t("uah") : i18n.t("ye");

    priceData.push({
      key: i18n.t(keyTranslation),
      value: value ? `${transformPrice(value)} ${currency}` : "-",
      oldValue: oldValue ? `${transformPrice(oldValue)} ${currency}` : "-",
    });
  };

  const selectPrices = () => {
    if (!user) return;

    if (isAdmin) {
      addPriceData(wholesalePrice, "wholesalePrice", oldWholesalePrice);
      addPriceData(largeWholesalePrice, "largeWholesalePrice", oldLargeWholesalePrice);
    } else {
      const isLgWholesale = user.roles.some(
        ({ name }) => name === `${manufacturer?.name}_largeWholesale`
      );
      const isWholesale = user.roles.some(
        ({ name }) => name === `${manufacturer?.name}_wholesale`
      );

      if (isWholesale) addPriceData(wholesalePrice, "wholesalePrice", oldWholesalePrice);
      if (isLgWholesale)
        addPriceData(largeWholesalePrice, "largeWholesalePrice", oldLargeWholesalePrice);
    }
  };

  addPriceData(price, "retailPrice", oldPrice);

  if (isLoggedIn === "true") selectPrices();

  const { data } = useSelector(selectCart);
  const currentProductCart = data?.find(({ product }) => product.id === Number(id));
  // const currentProductAmount = currentProductCart ? currentProductCart.amount : 1;
  const [amount, setAmount] = useState<number>(minimalCount || 1/* currentProductAmount */);

  const cartAction = (type: TCartAction, products: string, val?: number) => {
    const parsedProducts = JSON.parse(products);
    const productIndex = parsedProducts.findIndex(
      ({ product }: ICartProduct) => product.id === Number(id)
    );

    if (productIndex !== -1) {
      const key =
        type === "del"
          ? Number(id) * -1
          : `chng${id}${parsedProducts[productIndex].amount}`;

      switch (type) {
        case "del":
          parsedProducts.splice(productIndex, 1);
          break;

        case "chg":
          parsedProducts[productIndex].amount = val;
          break;

        default:
          break;
      }

      localStorage.setItem(LOCAL_STORAGE.cart, JSON.stringify(parsedProducts));
      dispatch(setCartChange(key));
    }
  };

  const onIncrease = () => {
    const step = initialCount || 1;
    const newValue = amount + step;
    // if (isLoggedIn === "true") {
    //   dispatch(updateCartProduct(Number(id), newValue));
    // } else {
    //   const products = localStorage.getItem(LOCAL_STORAGE.cart);

    //   if (products) {
    //     cartAction("chg", products, newValue);
    //   }
    // }
    setAmount(newValue);
  };

  const onDecrease = () => {
    const step = initialCount || 1;
    const newValue = amount - step;
    // if (isLoggedIn === "true") {
    //   dispatch(updateCartProduct(Number(id), newValue));
    // } else {
    //   const products = localStorage.getItem(LOCAL_STORAGE.cart);

    //   if (products) {
    //     cartAction("chg", products, newValue);
    //   }
    // }

    setAmount(newValue);
  };

  return (
    <div className="product_description">
      <h1 className="product_description__title">{name}</h1>
      <div className="product_description__wrap">
        <div className="product_description__row">
          <div className="product_description__column">
            <Rating rating={rating} />
          </div>
          <div className="product_description__column">
            <div className="product_description__code">
              {i18n.t("productCode")}: &nbsp;
              <span>{code1c}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="product_description__wrap product_description__wrap--labels_mod">
        <div
          className={classNames("product_description__label", {
            "product_description__label--available_mod":
              productAvailable === PRODUCT_AVAILABLE.available ||
              productAvailable === PRODUCT_AVAILABLE.toOrder,
            "product_description__label--not_available_mod":
              productAvailable === PRODUCT_AVAILABLE.unavailable,
          })}
        >
          {productAvailable === PRODUCT_AVAILABLE.available &&
            i18n.t("available")}
          {productAvailable === PRODUCT_AVAILABLE.unavailable &&
            i18n.t("notAvailable")}
          {productAvailable === PRODUCT_AVAILABLE.toOrder && i18n.t("toOrder")}
        </div>
        {isSale && (
          <div className="product_description__label product_description__label--sale_mod">
            {i18n.t("sale")}
          </div>
        )}
        {promo && (
          <div className="product_description__label product_description__label--promo_mod">
            {i18n.t("promo")}
          </div>
        )}
        {minimalCount && minimalCount > 1 && (
          <div className="product_description__label product_description__label--pair_mod">
            {i18n.t("pair")}
          </div>
        )}
      </div>
      {promo &&
        <Link
          to={`/${ROUTES.SinglePromotionPage.replace(':id', String(promo?.id))}`}
          className="product_promotion__link"
        >
          <div>
            <div className="product_promotion__row">
              <div className="product_promotion__column1">
                <Image src={promo?.image ? promo.image.fileUrl : '/favicon-310.png'} alt="cover" imgClass="product_promotion__cover" />
              </div>
              <div className="product_promotion__column2">
                <h1 className="promotion_description__title">{promo?.title}</h1>
                {promo.endDate && <ProductCardTimer promo={promo} />}
              </div>
              <div className="product_promotion__column3">
                <FaChevronRight size="20" />
              </div>
            </div>
          </div>
        </Link>
      }
      {shortDescription &&
        (shortDescription.length > 1 ? (
          <ul className="product_description__list">
            {shortDescription?.map((item) => {
              return (
                <li className="product_description__item" key={item}>
                  {item}
                </li>
              );
            })}
          </ul>
        ) : (
          <SanitaizedText
            className="product_description__editor"
            text={shortDescription[0]}
          />
        ))}

      {instruction && (
        <div className="product_description__wrap">
          <a
            href={instruction.fileUrl}
            download
            className="product_description__instruction"
          >
            {i18n.t("showInstruction")}
            <span>
              <AiOutlineFilePdf />
            </span>
          </a>
        </div>
      )}

      {minimalCount && minimalCount > 1 ? (
        <div className="product_description__alert">
          {i18n.t("minimalCount")}: {minimalCount}
        </div>
      ) : null}

      <div className="product_description__wrap product_description__wrap--prcies_mod">
        <Characteristics data={priceData} promo={promo} />
      </div>

      <div className="product_description__block">
        {id && (
          <div className="product_description__action">
            <BuyButton
              productAvailable={productAvailable}
              id={parseFloat(id)}
              product={productData}
              minimalCount={minimalCount}
              className="product_description__button"
              productAmount={amount}
            > 
              <span>{i18n.t("buy")}</span>
            </BuyButton>
          </div>
        )}
        <div className="product_description__action">
          <LikeButton productId={Number(id)} isActive={isFavorite} />
        </div>
        {!(!!currentProductCart) &&
          <div className="product_quantity">
            <button
              aria-label="Decrease"
              onClick={onDecrease}
              className={classNames(
                "product_quantity__btn",
                "product_quantity__btn--minus_mod",
                "font_icon",
                {
                  "quantity__btn--disabled_mod": amount === minimalCount || amount === 1,
                }
              )}
            >
              <FaMinus />
            </button>
            <div className="product_quantity__num">{amount}</div>
            <button
              aria-label="Increase"
              onClick={onIncrease}
              className={classNames(
                "product_quantity__btn",
                "product_quantity__btn--plus_mod",
                "font_icon"
              )}
            >
              <FaPlus />
            </button>
          </div>}
      </div>
      {
        option && (
          <div className="product_description__options">
            {option.map(({ name, productId, isAvailable }) => (
              <Link
                to={`${ROUTES.SingleProduct}/${productId}`}
                aria-label="Change product option"
                key={name + productId}
                className={classNames("product_description__option", {
                  "product_description__option--active_state":
                    Number(id) === productId,
                  "product_description__option--disabled_state": !isAvailable,
                })}
              >
                {name}
              </Link>
            ))}
          </div>
        )
      }

      {
        shouldTableRender && warehouse && (
          <div className="product_description__wrap">
            <AvailableTable
              data={warehouse.userCityProductWarehouse}
              allWarehouseAmount={warehouse.allWarehouseAmount}
            />
          </div>
        )
      }
      {
        shouldMagnitolsTableRender && components && (
          <AvailableMagnitolsTable {...components} />
        )
      }
    </div >
  );
};

export default ProductDescription;
