import i18n from "../../../../locales";
import { createNotification } from "../../../../utils/createNotification";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { setAppLoading } from "../../../app/repository/actions";
import {
  setDislaimerPopupOpen,
  saveCurrentOrder,
  saveCart,
  setFactPaynamentMethod,
} from "../../repository/actions";
import { IOrderData } from "../../repository/IOrderData";

interface ICreateOrderWorker {
  type: string;
  payload: IOrderData;
}

function* createOrderWorker({ payload }: ICreateOrderWorker): SagaIterator {
  try {
    yield put(setAppLoading(true));

    const { data, isError, message } = yield call(
      requester.post,
      links.createOrder,
      payload,
      undefined,
      undefined,
      true
    );

    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else if (data) {
      yield put(saveCurrentOrder(data));
      yield put(saveCart({ data: [], totalPriceGRN: "0", totalPriceYE: "0" }));
      yield put(setDislaimerPopupOpen(true));
      yield put(setFactPaynamentMethod(false));
    }
  } catch (error) {
    console.error("createOrderWorker: ", error);
  } finally {
    yield put(setAppLoading(false));
  }
}

export function* createOrderWatcher() {
  yield takeLatest("@saga/CREATE_ORDER", createOrderWorker);
}
