import { useEffect, useState } from "react";
import { PROMISE_STATES } from "../constants";
import { novaPoshtaAPI } from "../libs/NovaPoshta/NovaPoshtaAPI";
import { TSelectOption } from "../types/selectOption";
import useDebounce from "./useDebounce";

const transformToSelectArray = (data: any[]) => {
  if (!data || !data.length) return [];

  return data.map((item) => ({
    label: item.Description,
    value: item.Ref,
  }));
};

type TSelected = string | null;

const useNovaPoshtaAPI = (selectedArea: TSelected, selectedCity: TSelected) => {
  const [status, setStatus] = useState(PROMISE_STATES.idle);
  const [areas, setAreas] = useState<TSelectOption[]>([]);
  const [areasLoading, setAreasLoading] = useState<boolean>(false);
  const [cities, setCities] = useState<TSelectOption[]>([]);
  const [citiesLoading, setСitiesLoading] = useState<boolean>(false);
  const [warehouses, setWarehouses] = useState<TSelectOption[]>([]);
  const [warehousesLoading, setWarehousesLoading] = useState<boolean>(false);

  useEffect(() => {
    setStatus(PROMISE_STATES.pending);

    novaPoshtaAPI
      .getAreas()
      .then((response) => {
        setStatus(PROMISE_STATES.fulfilled);

        if (!response.isError) {
          setAreas(transformToSelectArray(response.data));
        }
      })
      .catch((error) => {
        setStatus(PROMISE_STATES.rejected);
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (!selectedArea) return;

    setStatus(PROMISE_STATES.pending);

    novaPoshtaAPI
      .getCities(selectedArea)
      .then((response) => {
        setStatus(PROMISE_STATES.fulfilled);

        if (!response.isError) {
          setCities(transformToSelectArray(response.data));
        }
      })
      .catch((error) => {
        setStatus(PROMISE_STATES.rejected);
        console.error(error);
      });
  }, [selectedArea]);

  useEffect(() => {
    if (!selectedCity) return;

    setStatus(PROMISE_STATES.pending);

    novaPoshtaAPI
      .getWarehouses(selectedCity)
      .then((response) => {
        setStatus(PROMISE_STATES.fulfilled);

        if (!response.isError) {
          setWarehouses(transformToSelectArray(response.data));
        }
      })
      .catch((error) => {
        setStatus(PROMISE_STATES.rejected);
        console.error(error);
      });
  }, [selectedCity]);

  const searchAreas = async (areaName: string) => {
    const response = await novaPoshtaAPI.getAreas(areaName);
    if (!response.isError) {
      setAreas(transformToSelectArray(response.data));
    }
    setAreasLoading(false);
  };

  const onSearchAreas = (areaName: string) => {
    if (areaName === "" || areaName === " ") return;
    setAreasLoading(true);
    debouncedSearchAreas(areaName);
  };

  const { debouncedWrapper: debouncedSearchAreas } = useDebounce(
    searchAreas,
    1300
  );

  const searchCity = async (findString: string) => {
    if (selectedArea) {
      const response = await novaPoshtaAPI.getCities(selectedArea, findString);
      if (!response.isError) {
        setCities(transformToSelectArray(response.data));
      }
      setСitiesLoading(false);
    }
  };

  const onSearchCity = (findString: string) => {
    if (findString === "" || findString === " ") return;
    setСitiesLoading(true);
    debouncedSearchCity(findString);
  };

  const { debouncedWrapper: debouncedSearchCity } = useDebounce(
    searchCity,
    1300
  );

  const searchWarehouses = async (findString: string) => {
    if (selectedCity) {
      const response = await novaPoshtaAPI.getWarehouses(
        selectedCity,
        findString
      );
      if (!response.isError) {
        setWarehouses(transformToSelectArray(response.data));
      }
      setWarehousesLoading(false);
    }
  };

  const onSearchWarehouses = (findString: string) => {
    if (findString === "" || findString === " ") return;
    setWarehousesLoading(true);
    debouncedSearchWarehouses(findString);
  };

  const { debouncedWrapper: debouncedSearchWarehouses } = useDebounce(
    searchWarehouses,
    1300
  );

  return {
    status,
    areas,
    cities,
    warehouses,
    onSearchAreas,
    onSearchCity,
    onSearchWarehouses,
    areasLoading,
    citiesLoading,
    warehousesLoading,
  };
};

export default useNovaPoshtaAPI;
