import { FC, InputHTMLAttributes } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

export interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  register?: UseFormRegisterReturn;
  min?: number | string;
  max?: number | string;
  isError?: boolean;
}

const Input: FC<IProps> = ({
  register,
  type,
  inputMode,
  isError,
  ...props
}) => {
  return (
    <input
      maxLength={1000}
      type={type || "text"}
      inputMode={inputMode || "text"}
      className={isError ? "error" : ""}
      {...register}
      {...props}
    />
  );
};

export default Input;
