import { useEffect, useState } from "react";

const useMedia = (mediaPoint?: string): boolean => {
  let query: string;

  switch (mediaPoint) {
    case "isDesktop":
      query = "(min-width: 1024px)";
      break;

    default:
      query = "(max-width: 1023px)";
      break;
  }

  const getMatches = (query: string): boolean => {
    if (typeof window !== "undefined") {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState<boolean>(getMatches(query));

  function handleChange() {
    setMatches(getMatches(query));
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    handleChange();

    matchMedia.addEventListener("change", handleChange);

    return () => {
      matchMedia.removeEventListener("change", handleChange);
    };
  }, [query]);

  return matches;
};

export default useMedia;
