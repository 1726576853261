import { FC, ReactNode, InputHTMLAttributes } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import classNames from "classnames";
import "./checkbox.scss";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  text: ReactNode;
  value: string;
  register?: UseFormRegisterReturn;
  variant?: "primary" | "secondary";
}

const Checkbox: FC<IProps> = ({
  id,
  text,
  value,
  variant = "primary",
  register,
  ...props
}) => {
  return (
    <div className={classNames("checkbox", `checkbox--${variant}_mod`)}>
      <label htmlFor={id} className="checkbox__label">
        <input
          className="checkbox__input"
          type="checkbox"
          value={value}
          id={id}
          {...register}
          {...props}
        />
        <span className="checkbox__checkmark" />
        <span className="checkbox__text">{text}</span>
      </label>
    </div>
  );
};

export default Checkbox;
