import { FC, useMemo } from "react";
import { INewsContent } from "../../../store/news/repository/INews";
import DoubleColumn from "./doubleColumn";
import SingleColumn from "./singleColumn";
import "./singleNewsContent.scss";

interface IProps {
  content: INewsContent[];
}

const SingleNewsContent: FC<IProps> = ({ content }) => {
  const sortedContent = useMemo(
    () => content.sort((a, b) => a.sortNumber - b.sortNumber),
    [content]
  );

  return (
    <div className="single_news_content">
      {sortedContent.map(({ id, type, main, secondary }) => (
        <div key={id} className="single_news_content__row">
          {type === "DOUBLE" ? (
            <DoubleColumn main={main} secondary={secondary} />
          ) : (
            <SingleColumn main={main} />
          )}
        </div>
      ))}
    </div>
  );
};

export default SingleNewsContent;
