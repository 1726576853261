import i18n from "../../../../locales";
import "./orderingPickup.scss";

const OrderingPickup = () => {
  return (
    <div className="ordering_pickup">
      <div className="ordering_pickup__block ordering_pickup__block--info_mod">
        <div className="ordering_pickup__title">{i18n.t("address")}</div>
        <div className="ordering_pickup__text">
          <a
            target="_blank"
            href="https://maps.google.com/maps?q=49.981197%2C36.237327&hl=ua-ua&t=m&z=15"
          >
            {i18n.t("pickupAddress")}
          </a>
        </div>
      </div>
      <div className="ordering_pickup__block ordering_pickup__block--diclaimer_mod">
        <div className="ordering_pickup__title">{i18n.t("attention")}!</div>
        <div className="ordering_pickup__text">
          {i18n.t("pickupText1")} <strong>{i18n.t("pickupStrongText")}</strong>{" "}
          {i18n.t("pickupText2")}
        </div>
      </div>
    </div>
  );
};

export default OrderingPickup;
