import React, { FC } from "react";
import DOMPurify from "isomorphic-dompurify";

interface IProps {
  className?: string;
  text: string;
}

const SanitizedText: FC<IProps> = ({ className, text }) => {
  if (!text) return null;

  const formattedChildren = text.replace(/\n/g, "<br>");
  const sanitized = DOMPurify.sanitize(formattedChildren, {
    ADD_ATTR: ["target", "rel"],
    ADD_TAGS: ["a"],
    FORBID_TAGS: ["style"],
    FORBID_ATTR: ["style"],
  });

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: sanitized }}
    />
  );
};

export default SanitizedText;
