import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import {
  saveSaleProducts,
  setSaleProductsLoading,
  setHiddenSaleProductsLoading,
} from "../../repository/actions";
import { SLIDES_PER_PAGE } from "../../../../constants";
import { createNotification } from "../../../../utils/createNotification";

interface IGetSaleProductsWorker {
  type: string;
  payload: {
    page: number;
    noLoading?: boolean;
  };
}

function* getSaleProductsWorker({
  payload,
}: IGetSaleProductsWorker): SagaIterator {
  const { page, noLoading } = payload;

  const params = {
    perPage: SLIDES_PER_PAGE,
    page: page ?? 1,
  };

  try {
    if (noLoading) {
      yield put(setHiddenSaleProductsLoading(true));
    } else {
      yield put(setSaleProductsLoading(true));
    }
    const { data, isError, message } = yield call(
      requester.get,
      links.saleProducts,
      params
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(saveSaleProducts(data));
    }
  } catch (error) {
    console.error("getSaleProductsWorker: ", error);
  } finally {
    if (noLoading) {
      yield put(setHiddenSaleProductsLoading(false));
    } else {
      yield put(setSaleProductsLoading(false));
    }
  }
}

export function* getSaleProductsWatcher() {
  yield takeLatest("@saga/GET_SALE_PRODUCTS", getSaleProductsWorker);
}
