import { IOrderResponse } from "./IOrdersResponse";
import { ICartResponse } from "./ICartResponse";
import { IOrder } from "./IOrder";

interface IInitialState {
  orders: IOrderResponse;
  ordersPage: number;
  ordersLoading: boolean;
  disclaimerPopupOpen: boolean;

  cart: ICartResponse;
  cartLoading: boolean;
  cartChange: number;

  addToOrderLoading: boolean;
  addToOrderLoadingId: number | null;

  currentOrder: IOrder | null;
  isPaynamentMethodFact: boolean;
}

const initialState: IInitialState = {
  orders: { data: [], count: 0 },
  ordersPage: 1,
  ordersLoading: false,
  disclaimerPopupOpen: false,

  cart: { data: [], totalPriceGRN: "0.00", totalPriceYE: "0.00" },
  cartLoading: false,
  cartChange: 0,

  addToOrderLoading: false,
  addToOrderLoadingId: null,

  currentOrder: null,
  isPaynamentMethodFact: false,
};

export function ordersReducer(
  state: IInitialState = initialState,
  action: any
): IInitialState {
  switch (action.type) {
    case "SAVE_ORDERS":
      return { ...state, orders: action.payload };
    case "SAVE_ORDERS_PAGE":
      return { ...state, ordersPage: action.payload };
    case "SET_ORDERS_LOADING":
      return { ...state, ordersLoading: action.payload };
    case "SET_DICLAIMER_POPUP_OPEN":
      return { ...state, disclaimerPopupOpen: action.payload };

    case "SAVE_CART":
      return { ...state, cart: action.payload };
    case "SET_CART_CHANGE":
      return { ...state, cartChange: action.payload };
    case "SET_CART_LOADING":
      return { ...state, cartLoading: action.payload };

    case "SET_ADD_TO_ORDER_LOADING":
      return { ...state, addToOrderLoading: action.payload };
    case "SAVE_ADD_TO_ORDER_LOADING_ID":
      return { ...state, addToOrderLoadingId: action.payload };

    case "SAVE_CURRENT_ORDER":
      return { ...state, currentOrder: action.payload };
    case "SET_FACT_PAYNAMENT_METHOD":
      return { ...state, isPaynamentMethodFact: action.payload };

    default:
      return state;
  }
}
