import { INotificationsResponse } from "./INotificationsResponse";

interface IInitialState {
  notifications: INotificationsResponse;
};

const initialState: IInitialState = {
  notifications: { data: [], count: 0 },
};

export function notificationsReducer(
  state: IInitialState = initialState,
  action: any
): IInitialState {
  switch (action.type) {
    case "SAVE_NOTIFICATIONS":
      return { ...state, notifications: action.payload };

    default:
      return state;
  }
}
