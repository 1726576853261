import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";
import { PRODUCTS_PER_PAGE } from "../../../../constants";
import { setAppLoading } from "../../../app/repository/actions";
import { saveNotifications } from "../../repository/actions";

interface IGetNotificationsWorker {
  type: string;
  payload: number;
}

function* getNotificationsWorker({
  payload,
}: IGetNotificationsWorker): SagaIterator {
  try {
    yield put(setAppLoading(true));

    const params = {
      page: payload ?? 1,
      perPage: PRODUCTS_PER_PAGE,
    };

    const { data, isError, message } = yield call(
      requester.get,
      links.notifications,
      params
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(saveNotifications(data));
    }
  } catch (error) {
    console.error("getNotificationsWorker: ", error);
  } finally {
    yield put(setAppLoading(false));
  }
}

export function* getNotificationsWatcher() {
  yield takeLatest("@saga/GET_NOTIFICATIONS", getNotificationsWorker);
}
