import { FC, ReactNode } from "react";
import { Tab, Tabs, TabList } from "react-tabs";
import i18n from "../../locales";
import "./singleProductTabs.scss";

interface IProps {
  children: ReactNode;
  onSelect?: (index: number) => void;
  hasDescription?: boolean;
}

const SingleProductTabs: FC<IProps> = ({
  children,
  onSelect,
  hasDescription,
}) => {
  return (
    <Tabs onSelect={onSelect}>
      <nav className="single_product_tabs__nav">
        <TabList className="single_product_tabs__list">
          {hasDescription && (
            <Tab className="single_product_tabs__button">
              <span>{i18n.t("description")}</span>
            </Tab>
          )}
          <Tab className="single_product_tabs__button">
            <span>{i18n.t("characteristics")}</span>
          </Tab>
          <Tab className="single_product_tabs__button">
            <span>{i18n.t("reviews")}</span>
          </Tab>
        </TabList>
      </nav>
      {children}
    </Tabs>
  );
};

export default SingleProductTabs;
