import { FC, RefObject, useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  selectCategoryFilterType,
  selectCurrentSettings,
} from "../../../store/products/repository/selectors";
import { IFilter } from "../../../store/products/repository/IFilter";
import { IFilterValue } from "../../../store/products/repository/IFilterValue";
import { IFilterRequest } from "../../../store/products/repository/IFilterRequest";
import { UseFormSetValue, UseFormGetValues } from "react-hook-form";
import { SelectItem } from "./selectItem";
import { CheckboxItem } from "./checkboxItem";
import FilterItem from "../../filterItem/filterItem";

interface IProps {
  filters: IFilter[];
  resetKey: number;
  handleButtonHide: () => void;
  handleFilterChange: (ref: RefObject<HTMLDivElement | HTMLLIElement>) => void;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  handleSubmit: any;
}

interface IProccessFiltersProps {
  id: number;
  type: "DropDown" | "CheckBox";
  values: IFilterValue[];
  index: number;
}

interface IProccessCheckboxProps {
  id: number;
  name: string;
  choosenFilter: IFilterRequest | undefined;
}

export const FiltersGroup: FC<IProps> = ({
  filters,
  handleButtonHide,
  resetKey,
  setValue,
  handleFilterChange,
  getValues,
  handleSubmit,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [nextIndex, setNextIndex] = useState<number | null>(null);

  const currentSettings = useSelector(selectCurrentSettings);
  const filterType = useSelector(selectCategoryFilterType);
  const isByOrder = filterType === "ByOrder";

  const onSelectChange = useCallback(
    (selected: boolean, index: number, id: string) => {
      if (!isByOrder) return;

      if (currentSettings?.filter) {
        const deletedFilters = currentSettings.filter.filter(
          ({ keyId }) => keyId > Number(id)
        );

        deletedFilters.map(({ keyId }) => {
          setValue(keyId.toString(), undefined);
          return null;
        });
      }

      if (selected) {
        setSelectedIndex(index + 1);
        index < selectedIndex ? setNextIndex(index + 1) : setNextIndex(null);
      } else {
        setSelectedIndex(index);
        setNextIndex(null);
      }
      handleSubmit();
    },
    [isByOrder, currentSettings?.filter, selectedIndex, handleSubmit]
  );

  const transformValuesForSelect = useMemo(() => {
    return (values: IFilterValue[]) => {
      const newValues = values
        .map(({ name }) => {
          if (!name) return undefined;
          return { label: name, value: name };
        })
        .filter(Boolean);

      return newValues;
    };
  }, []);

  const proccessFiltres = useMemo(() => {
    return ({ type, values, id, index }: IProccessFiltersProps) => {
      const isSelect = type === "DropDown" || isByOrder;
      const isCheckbox = type === "CheckBox";

      const selectedValues = isSelect
        ? transformValuesForSelect(values)
        : undefined;

      const choosenFilter: IFilterRequest | undefined =
        currentSettings?.filter?.find((filter) => filter.keyId === id);

      const selectDefaultOption = choosenFilter &&
        isSelect && {
          label: choosenFilter.values[0],
          value: choosenFilter.values[0],
        };

      const isDisabled = isByOrder && index > selectedIndex;

      return {
        selectDefaultOption,
        selectedValues,
        choosenFilter,
        isDisabled,
        isSelect,
        isCheckbox,
      };
    };
  }, [
    isByOrder,
    currentSettings?.filter,
    transformValuesForSelect,
    selectedIndex,
  ]);

  const proccessCheckbox = useMemo(() => {
    return ({ choosenFilter, name, id }: IProccessCheckboxProps) => {
      const choosenValues =
        choosenFilter &&
        Array.isArray(choosenFilter.values) &&
        typeof choosenFilter.values[0] === "string"
          ? (choosenFilter.values as string[])
          : undefined;

      const isChecked = choosenValues && choosenValues.includes(name);

      const handleSetValue = (checked: boolean) => {
        const currentId = id.toString();
        const values = getValues(currentId);
        let updatedValues;

        if (checked) {
          updatedValues = Array.isArray(values) ? [name, ...values] : [name];
        } else {
          updatedValues = Array.isArray(values)
            ? values.filter((value: string) => value !== name)
            : undefined;
        }

        setValue(currentId, updatedValues);
      };

      return { isChecked, handleSetValue };
    };
  }, []);

  return (
    <>
      {filters.map(({ id, type, name, values }, index) => {
        const {
          selectDefaultOption,
          selectedValues,
          choosenFilter,
          isDisabled,
          isSelect,
          isCheckbox,
        } = proccessFiltres({ type, values, id, index });

        return (
          <div key={id} className="product_filtres__item">
            {isSelect && (
              <SelectItem
                isDisabled={isDisabled}
                shouldClear={nextIndex === index}
                onSelectChange={onSelectChange}
                name={name}
                resetKey={resetKey}
                id={id.toString()}
                options={selectedValues}
                setValue={setValue}
                handleButtonHide={handleButtonHide}
                handleFilterChange={handleFilterChange}
                defaultValue={selectDefaultOption ?? undefined}
                index={index}
              />
            )}
            {isCheckbox && !isByOrder && (
              <FilterItem
                values={values}
                onSpoilerClose={handleButtonHide}
                name={name}
              >
                <ul className="filter_item__list">
                  {values.map(({ name }, index) => {
                    if (!name) return null;

                    const { isChecked, handleSetValue } = proccessCheckbox({
                      id,
                      name,
                      choosenFilter,
                    });

                    return (
                      <CheckboxItem
                        key={name + index + resetKey}
                        name={name}
                        handleFilterChange={(ref, e) => {
                          handleFilterChange(ref);
                          handleSetValue(e.target.checked);
                        }}
                        defaultChecked={isChecked}
                      />
                    );
                  })}
                </ul>
              </FilterItem>
            )}
          </div>
        );
      })}
    </>
  );
};
