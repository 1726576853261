import { gsap } from "gsap";
import { useRef, useEffect, useState } from "react";
import i18n from "../../locales";
import { IOrder } from "../../store/orders/repository/IOrder";
import classNames from "classnames";
import { transformDate } from "../../utils/transformDate";
import { FiChevronDown } from "react-icons/fi";
import Price from "../price/price";
import OrderProduct from "../orderProduct/orderProduct";
import OrderInfoItem from "./ui/orderInfoItem/orderInfoItem";
import "./orderItem.scss";

const OrderItem = ({
  id,
  status,
  totalPriceGRN,
  totalPriceYE,
  created_at,
  products,
  comment,
  discount,
  paymentMethod,
  novaPoshtaOrderType,
  novaPoshtaCity,
  novaPoshtaState,
  novaPoshtaWarehouse,
  novaPoshtaAdress,
  orderUserName,
  orderPhone,
  oldTotalPriceGRN,
  oldTotalPriceYE,
  promoCode,
}: IOrder) => {
  const [isActive, setActive] = useState(false);
  const $content = useRef<HTMLDivElement | null>(null);

  const isOrderTypeNP = novaPoshtaOrderType === "DELIVERY" || "WAREHOUSE";

  const handleClick = () => {
    setActive((prev) => !prev);
  };

  useEffect(() => {
    const target = $content.current;

    if (isActive) {
      gsap.to(target, {
        height: "auto",
        duration: 0.3,
      });

      return () => {
        gsap.to(target, {
          height: "0",
          duration: 0.3,
        });
      };
    }
    return () => { };
  }, [id, isActive]);


  const isPromoGRN = (Number(oldTotalPriceGRN) - Number(totalPriceGRN)) > 0;
  const isPromoYE = (Number(oldTotalPriceYE) - Number(totalPriceYE)) > 0;

  return (
    <div
      className={classNames("orders_item", {
        "orders_item--pending_mod": status === "Pending",
        "orders_item--closed_mod": status === "Closed",
        "orders_item--active_state": isActive,
      })}
    >
      <button type="button" onClick={handleClick} className="orders_item__row">
        <div className="orders_item__decor" />
        <div className="orders_item__wrap">
          <div className="orders_item__id">№ {id}</div>
          <div className="orders_item__date">{transformDate(created_at)}</div>
        </div>
        <div className="orders_item__wrap">
          <div className="orders_item__label">{i18n.t("status")}:</div>
          <div className="orders_item__status">
            {status === "Pending" && i18n.t("pending")}
            {status === "Closed" && i18n.t("closed")}
          </div>
        </div>
        <div className="orders_item__wrap">
          <div className="orders_item__label">{i18n.t("total")}:</div>

          <div className="orders_item__price">
            {Number(totalPriceGRN) > 0 && (
              <Price
                price={totalPriceGRN}
                oldPrice={oldTotalPriceGRN}
                size="xs"
                promo={isPromoGRN}
              />
            )}
            {Number(totalPriceYE) > 0 && (
              <Price
                price={totalPriceYE}
                oldPrice={oldTotalPriceYE}
                currency="ye"
                size="xs"
                promo={isPromoYE}
              />
            )}
          </div>
        </div>
        <div className="orders_item__icon font_icon">
          <FiChevronDown />
        </div>
      </button>
      <div ref={$content} className="orders_item__body">
        {products && products.length > 0 && (
          <ul className="orders_item__products">
            {products.map(({ product, amount }) => (
              <li key={product.id} className="orders_item__product">
                <OrderProduct count={amount} /* {... */{ ...product/* , promo: undefined } */} />
              </li>
            ))}
          </ul>
        )}
        <div className="orders_item__info">
          {paymentMethod && (
            <OrderInfoItem
              label={i18n.t("paymentMethod")}
              value={`${paymentMethod === "Fact" ? i18n.t("fact") : i18n.t("nonFact")
                }`}
            />
          )}
          {novaPoshtaOrderType && (
            <OrderInfoItem
              label={i18n.t("orderType")}
              value={
                novaPoshtaOrderType === "DELIVERY"
                  ? i18n.t("delivery")
                  : novaPoshtaOrderType === "PICKUP"
                    ? i18n.t("pickup")
                    : i18n.t("deliveryToWarehouse")
              }
            />
          )}
          {orderUserName && (
            <OrderInfoItem label={i18n.t("name")} value={orderUserName} />
          )}
          {orderPhone && (
            <OrderInfoItem label={i18n.t("phone")} value={orderPhone} />
          )}
          {novaPoshtaCity && isOrderTypeNP && (
            <OrderInfoItem label={i18n.t("city")} value={novaPoshtaCity} />
          )}
          {novaPoshtaState && isOrderTypeNP && (
            <OrderInfoItem label={i18n.t("state")} value={novaPoshtaState} />
          )}
          {novaPoshtaWarehouse && novaPoshtaOrderType === "WAREHOUSE" && (
            <OrderInfoItem
              label={i18n.t("warehouse")}
              value={novaPoshtaWarehouse}
            />
          )}
          {novaPoshtaAdress && novaPoshtaOrderType === "DELIVERY" && (
            <OrderInfoItem label={i18n.t("address")} value={novaPoshtaAdress} />
          )}
          {comment && (
            <OrderInfoItem label={i18n.t("comment")} value={comment} />
          )}
          {discount !== 0 && (
            <OrderInfoItem label={i18n.t("discount")} value={`${discount}%`} />
          )}
          {promoCode?.promCode && (
            <OrderInfoItem
              label={i18n.t("promoCode")}
              value={promoCode.promCode}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderItem;
