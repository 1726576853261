import { FC, InputHTMLAttributes, useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import classNames from "classnames";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import Input from "../input/input";
import "./passwordInput.scss";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  register: UseFormRegisterReturn;
}

const PasswordInput: FC<IProps> = ({ register, ...props }) => {
  const [isHidden, setHidden] = useState(true);

  const handleToggleVisible = () => {
    setHidden((isHidden) => !isHidden);
  };

  return (
    <div className="password_input">
      <Input
        type={isHidden ? "password" : "text"}
        register={register}
        {...props}
      />
      <button
        type="button"
        onClick={handleToggleVisible}
        aria-label="Toggle password visibility"
        className="password_input__button"
      >
        <span>
          <AiFillEyeInvisible
            className={classNames("password_input__icon", {
              "password_input__icon--active_state": !isHidden,
            })}
          />
          <AiFillEye
            className={classNames("password_input__icon", {
              "password_input__icon--active_state": isHidden,
            })}
          />
        </span>
      </button>
    </div>
  );
};

export default PasswordInput;
