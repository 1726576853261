import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";
import { setAppLoading } from "../../../app/repository/actions";
import { IFeedbackData } from "../../repository/IFeedbackData";
import i18n from "../../../../locales";

interface ICreateFeedbackWorker {
  type: string;
  payload: IFeedbackData;
}

function* createFeedbackWorker({
  payload,
}: ICreateFeedbackWorker): SagaIterator {
  try {
    yield put(setAppLoading(true));
    const { data, isError, message } = yield call(
      requester.post,
      links.createFeedback,
      payload
    );

    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      createNotification({
        title: i18n.t("success"),
        message: "",
        type: "success",
      });
    }
  } catch (error) {
    console.error("createFeedbackWorker: ", error);
  } finally {
    yield put(setAppLoading(false));
  }
}

export function* createFeedbackWatcher() {
  yield takeLatest("@saga/CREATE_FEEDBACK", createFeedbackWorker);
}
