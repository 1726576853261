import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch } from "react-redux";
import i18n from "../../locales";
import { ROUTES, REGULARS } from "../../constants";
import { FiSearch } from "react-icons/fi";
import { CLASSES } from "../../constants";
import { saveCatalogHistory } from "../../store/catalog/repository/actions";
import { createNotification } from "../../utils/createNotification";
import Input from "../input/input";
import "./searchForm.scss";

interface ISearchData {
  query: string;
}

const SearchForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { register, handleSubmit, reset } = useForm<ISearchData>();

  useEffect(() => {
    if (!location.pathname.includes(ROUTES.SearchResultPage)) reset();
  }, [location.pathname]);

  const onSubmit: SubmitHandler<ISearchData> = (data) => {
    const { query } = data;

    if (!REGULARS.isNumbersOnly.test(query) && query.length < 2) {
      createNotification({
        title: "",
        message: i18n.t("searchMinLengthError"),
        type: "danger",
      });
      return;
    }

    navigate(`${ROUTES.SearchResultPage}/${encodeURIComponent(query)}`);

    if (document.body.classList.contains(CLASSES.menuOpen))
      document.body.classList.remove(CLASSES.menuOpen);

    if (document.body.classList.contains(CLASSES.megamenuOpen)) {
      dispatch(saveCatalogHistory([]));
    }
  };

  return (
    <form action="#" onSubmit={handleSubmit(onSubmit)} className="search_form">
      <Input
        required
        register={register("query")}
        className="search_form__input"
        title="Search field"
        placeholder={`${i18n.t("search")}....`}
      />
      <button type="submit" aria-label="Search" className="search_form__button">
        <div className="search_form__icon font_icon">
          <FiSearch />
        </div>
      </button>
    </form>
  );
};

export default SearchForm;
