import { useDispatch, useSelector } from "react-redux";
import { useRef, useState, useEffect, FC } from "react";
import { getPromoProducts } from "../../store/promo/useCases/getPromoProducts/action";
import { getCategoryProducts } from "../../store/products/useCases/getCategoryProducts/action";
import { savePaginationGroupId } from "../../store/groupProducts/repository/actions";
import {
  selectHiddenProductsLoading,
  selectHiddenSaleProductsLoading,
} from "../../store/products/repository/selectors";
import { selectHiddenPromoProductsLoading } from "../../store/promo/repository/selectors";
import { SLIDES_PER_PAGE } from "../../constants";
import { getSaleProducts } from "../../store/products/useCases/getSaleProducts/action";
import { IProduct } from "../../store/products/repository/IProduct";
import { TSliderMod } from "../../types/sliderMod";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import SwiperArrow from "../swiperArrow/swiperArrow";
import ProductCard from "../productCard/productCard";
import "./productsSlider.scss";
import "swiper/css";

interface IProps {
  products: IProduct[];
  mod: TSliderMod;
  id?: number;
  groupId?: number;
}

SwiperCore.use([Navigation]);

const ProductsSlider: FC<IProps> = ({ products, mod, id, groupId }) => {
  const dispatch = useDispatch();
  const saleLoading = useSelector(selectHiddenSaleProductsLoading);
  const promoLoading = useSelector(selectHiddenPromoProductsLoading);
  const categoryLoading = useSelector(selectHiddenProductsLoading);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFirstRender, setFirstRender] = useState<boolean>(true);
  const [isPagination, setIsPagination] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [slides, setSlides] = useState<IProduct[]>([]);
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);

  const stopTrigger = products.length < SLIDES_PER_PAGE;

  useEffect(() => {
    if (isFirstRender) setFirstRender(false);

    if (isPagination) {
      setSlides([...slides, ...products]);
      setIsPagination(false);
    } else {
      setSlides(products);
    }
  }, [products]);

  useEffect(() => {
    if (mod === "sale" && id === -1) {
      saleLoading ? setIsLoading(true) : setIsLoading(false);
    } else if (mod === "promo" && id === -1) {
      promoLoading ? setIsLoading(true) : setIsLoading(false);
    } else {
      categoryLoading ? setIsLoading(true) : setIsLoading(false);
    }
  }, [mod, id, saleLoading, promoLoading, categoryLoading]);

  const onReachEnd = () => {
    if (stopTrigger || isFirstRender) return;

    if (mod === "sale" && id === -1) {
      if (saleLoading) return;
      dispatch(getSaleProducts(page + 1, true));
    } else if (mod === "promo" && id === -1) {
      if (promoLoading) return;
      dispatch(getPromoProducts(page + 1, true));
    } else if (mod === "group") {
      if (categoryLoading) return;

      if (id === -1) {
        dispatch(
          getCategoryProducts({
            productGroupId: groupId,
            page: page + 1,
            perPage: SLIDES_PER_PAGE,
            slider: "group",
            noLoading: true,
          })
        );
        dispatch(savePaginationGroupId(groupId));
      } else {
        dispatch(
          getCategoryProducts({
            id,
            productGroupId: groupId,
            page: page + 1,
            perPage: SLIDES_PER_PAGE,
            slider: mod,
            noLoading: true,
          })
        );
      }
    } else {
      if (categoryLoading) return;

      dispatch(
        getCategoryProducts({
          id,
          page: page + 1,
          perPage: SLIDES_PER_PAGE,
          slider: mod,
          noLoading: true,
        })
      );
    }

    setIsPagination(true);
    setPage((prev) => prev + 1);
  };

  const swiperProps = {
    spaceBetween: 20,
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      480: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      },
    },
    onInit: (swiper: SwiperCore) => {
      if (
        swiper.params.navigation &&
        typeof swiper.params.navigation !== "boolean"
      ) {
        swiper.params.navigation.prevEl = prevRef.current;
        swiper.params.navigation.nextEl = nextRef.current;
        swiper.navigation.init();
        swiper.navigation.update();
      }
    },
    onReachEnd: onReachEnd,
  };

  return (
    <div className="products_slider">
      <Swiper className="products_slider__body" {...swiperProps}>
        {slides.map((product) => {
          return (
            <SwiperSlide key={product.id} className="products_slider__slide">
              <ProductCard product={product} />
            </SwiperSlide>
          );
        })}
        <SwiperArrow
          arrowRef={prevRef}
          classes="products_slider__arrow products_slider__arrow--prev_mod"
        />
        <SwiperArrow
          isLoading={isLoading}
          arrowRef={nextRef}
          classes="products_slider__arrow products_slider__arrow--next_mod"
        />
      </Swiper>
    </div>
  );
};

export default ProductsSlider;
