import { IRootState } from "../../store";

export const selectFavoritesProducts = (state: IRootState) =>
  state.favorites.favoritesProducts;

export const selectFavoritesPage = (state: IRootState) =>
  state.favorites.favoritesPage;

export const selectUnlikedProducts = (state: IRootState) =>
  state.favorites.unlikedProducts;

export const selectLikedProducts = (state: IRootState) =>
  state.favorites.likedProducts;

export const selectFavoritesProductsLoading = (state: IRootState) =>
  state.favorites.isFavoritesProductsLoading;
