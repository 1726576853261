import { IArea } from "./types/IArea";
import { ICity } from "./types/ICity";
import { INPAddressResponse } from "./types/INPAddressResponse";
import { INPResponse } from "./types/INPResponse";
import { ISettlement } from "./types/ISettlement";
import { IWarehouse } from "./types/IWarehouse";

export interface INovaPoshtaAPI {
    getAreas: (FindByString?: string) => Promise<INPResponse<IArea[]>>;
    getSettlements: (areaRef?: string, FindByString?: string) => Promise<INPResponse<ISettlement[]>>;
    getCities: (areaRef?: string, FindByString?: string) => Promise<INPResponse<ICity[]>>;
    getWarehouses: (CityRef?: string, SettlementRef?: string, FindByString?: string) => Promise<INPResponse<IWarehouse[]>>;
};

const api_key = '36e89d99f7efe1820c16e485e55c829e';
const api_link = 'https://api.novaposhta.ua/v2.0/json/';

class NovaPoshtaAPI implements INovaPoshtaAPI {

    getAreas = async (FindByString?: string): Promise<INPResponse<IArea[]>> => {
        try {
            const response = await fetch(api_link, {
                method: 'POST',
                headers: { 'Accept': '*/*', 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    apiKey: api_key,
                    modelName: "Address",
                    calledMethod: "getAreas",
                    methodProperties: {
                        FindByString,
                    }
                }),
            });
            const result = await this.processingResponse(response);
            return result;
        } catch (error) {
            console.error('NovaPoshtaAPI -> getAreas: ', error);
            return { isError: true, data: [], message: 'Get areas error' };
        };
    };

    getSettlements = async (areaRef?: string, FindByString?: string): Promise<INPResponse<ISettlement[]>> => {
        try {
            const response = await fetch(api_link, {
                method: 'POST',
                headers: { 'Accept': '*/*', 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    apiKey: api_key,
                    modelName: "Address",
                    calledMethod: "getSettlements",
                    methodProperties: {
                        areaRef,
                        FindByString,
                        Warehouse: 1
                    }
                }),
            });
            const result = await this.processingResponse(response);
            return result;
        } catch (error) {
            console.error('NovaPoshtaAPI -> getSettlements: ', error);
            return { isError: true, data: [], message: 'Get settlements error' };
        };
    };

    getCities = async (AreaRef?: string, FindByString?: string): Promise<INPResponse<ICity[]>> => {
        try {
            const response = await fetch(api_link, {
                method: 'POST',
                headers: { 'Accept': '*/*', 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    apiKey: api_key,
                    modelName: "Address",
                    calledMethod: "getCities",
                    methodProperties: {
                        AreaRef,
                        FindByString,
                    }
                }),
            });
            const result = await this.processingResponse(response);
            return result;
        } catch (error) {
            console.error('NovaPoshtaAPI -> getCities: ', error);
            return { isError: true, data: [], message: 'Get cities error' };
        };
    };

    getWarehouses = async (CityRef?: string, FindByString?: string): Promise<INPResponse<IWarehouse[]>> => {
        try {
            const response = await fetch(api_link, {
                method: 'POST',
                headers: { 'Accept': '*/*', 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    apiKey: api_key,
                    modelName: "Address",
                    calledMethod: "getWarehouses",
                    methodProperties: {
                        CityRef,
                        FindByString,
                    }
                }),
            });
            const result = await this.processingResponse(response);
            return result;
        } catch (error) {
            console.error('NovaPoshtaAPI -> getWarehouses: ', error);
            return { isError: true, data: [], message: 'Get warehouses error' };
        };
    };

    private processingResponse = async (response: any): Promise<INPResponse<any>> => {
        try {
            const result: INPAddressResponse = await response.json();
            if (result.success) {
                return { isError: false, data: result.data, message: '' };
            } else {
                let error = ''
                Object.keys(result?.errors).forEach((key) => {
                    error = error + result?.errors[key as any] + ' ';
                });
                return { isError: true, data: null, message: error || 'Nova Poshta error' };
            };
        } catch (error) {
            console.error('NovaPoshtaAPI -> processingResponse: ', error);
            return { isError: true, data: null, message: 'NovaPoshtaAPI error processingResponse ' };
        };
    }

};

export const novaPoshtaAPI = new NovaPoshtaAPI();