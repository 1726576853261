import { useState, FC } from "react";
import { ICatalog } from "../../store/catalog/repository/ICatalog";
import { ROUTES } from "../../constants";
import { Link } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";
import Catalog from "../catalog/catalog";
import "./catalogItem.scss";

interface IProps {
  item: ICatalog;
  depthLevel: number;
  onClickLink: () => void;
  maxDepth?: number;
}

const CatalogItem: FC<IProps> = ({
  item,
  depthLevel,
  maxDepth = 4,
  onClickLink,
}) => {
  const [isOpen, setOpen] = useState(false);

  const onMouseEnter = () => setOpen(true);

  const onMouseLeave = () => setOpen(false);

  const { childCategories, name, id, description } = item;

  const hasChildCategories = !!childCategories?.length;
  const isMaxDepthReached = depthLevel >= maxDepth;
  const linkToProducts = `${ROUTES.ProductList}/${id}`;
  const linkToCategories = `${ROUTES.Categories}/${id}`;

  return (
    <div
      className="catalog_item"
      onMouseEnter={hasChildCategories ? onMouseEnter : undefined}
      onMouseLeave={hasChildCategories ? onMouseLeave : undefined}
    >
      <Link
        className="catalog_item__link"
        to={hasChildCategories ? linkToCategories : linkToProducts}
        onClick={onClickLink}
      >
        <span className="catalog_item__text">{name}</span>
        {hasChildCategories && !isMaxDepthReached && (
          <span className="catalog_item__arrow">
            <FiChevronDown />
          </span>
        )}
      </Link>
      {hasChildCategories && !isMaxDepthReached && (
        <Catalog
          isOpen={isOpen}
          depthLevel={depthLevel}
          items={childCategories}
          onClickLink={onClickLink}
        />
      )}
    </div>
  );
};

export default CatalogItem;
