import i18n from "../../locales";
import { FC } from "react";
import classNames from "classnames";
import { transformPrice } from "../../utils/transformPrice";
import "./price.scss";

interface IProps {
  price: number | string;
  oldPrice?: number;
  size?: "xs" | "base";
  singlePrice?: number;
  currency?: string;
  promo?: any;
}

const Price: FC<IProps> = ({ price, oldPrice, singlePrice, size = "base", currency, promo }) => {
  const cleanedCur: string = currency?.replace(/\./g, "") ?? "uah";
  const currentCur: string = ` ${
    cleanedCur ? i18n.t(cleanedCur) : i18n.t("uah")
  }`; 

  return (
    <div
      className={classNames("price", {
        "price--base_size": size === "base",
        "price--xs_size": size === "xs",

        "price--old_mod": oldPrice && oldPrice !== price,
        "price--single_mod": singlePrice,
      })}
    > 
      {oldPrice && oldPrice !== price && (
        <div className="price__wrapper">
          <div className="price__val price__val--old_mod">
            {transformPrice(oldPrice)}
            {currentCur}
          </div>
          {/* <div className="price__val price__val--difference_mod">
            {transformPrice(Number(price) - oldPrice)}
            {currentCur}
          </div> */}
        </div>
      )}
      {singlePrice && (
        <div className="price__val price__val--single_mod">
          {transformPrice(price)}
          {currentCur}
        </div>
      )}
     <div className={`price__val ${!promo ? 'price__val--promo_mod' : 'price__val--current_mod'}`}>
        {singlePrice
          ? `${transformPrice(singlePrice)}`
          : `${transformPrice(price)}`}
        {currentCur}
      </div>
    </div>
  );
};

export default Price;
