import {
  FC,
  RefObject,
  useRef,
  useState,
  useEffect,
  useCallback,
  memo,
} from "react";
import { UseFormSetValue } from "react-hook-form";
import CustomSelect from "../../customSelect/customSelect";
import { Props } from "react-select";
import FilterItem from "../../filterItem/filterItem";

interface IProps extends Props {
  id: string;
  name: string;
  resetKey: number;
  index: number;
  handleFilterChange: (ref: RefObject<HTMLDivElement>) => void;
  handleButtonHide: () => void;
  setValue: UseFormSetValue<any>;
  onSelectChange?: (selected: boolean, index: number, id: string) => void;
  isDisabled?: boolean;
  shouldClear?: boolean;
  defaulValue?: {
    label: string;
    value: string;
  };
}

export const SelectItem: FC<IProps> = memo(
  ({
    id,
    name,
    resetKey,
    index,
    options,
    setValue,
    handleFilterChange,
    handleButtonHide,
    onSelectChange,
    defaultValue,
    isDisabled,
    shouldClear,
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<any>(null);
    const selectRef = useRef<HTMLDivElement>(null);

    const handleChange = useCallback(
      (selectedOption: any) => {
        setSelectedOption(selectedOption);
        setValue(id, selectedOption?.value);
        handleFilterChange(selectRef);

        if (!onSelectChange) return;

        if (selectedOption === null) {
          onSelectChange(false, index, id);
        } else {
          onSelectChange(true, index, id);
        }
      },
      [id, index, setValue, handleFilterChange, onSelectChange]
    );

    useEffect(() => {
      if (isDisabled || shouldClear) {
        setSelectedOption(null);
        setValue(id, undefined);
      }
    }, [isDisabled, id, shouldClear]);

    useEffect(() => {
      setSelectedOption(null);
      setValue(id, undefined);
    }, [resetKey]);

    return (
      <FilterItem
        onSpoilerClose={handleButtonHide}
        selectIsOpen={isOpen}
        name={name}
      >
        <div ref={selectRef} className="filter_item__select">
          <CustomSelect
            name={id}
            key={resetKey}
            options={options}
            setValue={setValue}
            isClearable={true}
            defaultValue={defaultValue}
            menuIsOpen={isOpen}
            isDisabled={isDisabled}
            onMenuClose={() => setIsOpen(false)}
            onMenuOpen={() => setIsOpen(true)}
            value={selectedOption}
            onChange={handleChange}
          />
        </div>
      </FilterItem>
    );
  }
);
