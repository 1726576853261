import { ICatalog } from "../store/catalog/repository/ICatalog";

export const findCatalogById = (
  id: number,
  items: ICatalog[]
): ICatalog | undefined => {
  for (const item of items) {
    if (item.id === id) {
      return item;
    }
    if (item.childCategories) {
      const foundItem = findCatalogById(id, item.childCategories);
      if (foundItem) {
        return foundItem;
      }
    }
  }
  return undefined;
};
