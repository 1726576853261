import { IRootState } from "../../store";

export const selectNews = (state: IRootState) => state.news.news;
export const selectNewsPage = (state: IRootState) => state.news.newsPage;
export const selectNewsLoading = (state: IRootState) => state.news.newsLoading;

export const selectSingleNews = (state: IRootState) => state.news.singleNews;
export const selectSingleNewsLoading = (state: IRootState) =>
  state.news.singleNewsLoading;

export const selectRatingPopupOpen = (state: IRootState) =>
  state.news.isRatingPopupOpen;
export const selectVotedArticleId = (state: IRootState) =>
  state.news.votedArticleId;
