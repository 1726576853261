import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import {
  saveProductDescription,
  setProductDescriptionLoading,
} from "../../repository/actions";
import { createNotification } from "../../../../utils/createNotification";

function* getProductDescriptionsWorker({ payload }: any): SagaIterator {
  try {
    yield put(setProductDescriptionLoading(true));
    const { data, isError, message } = yield call(
      requester.get,
      `${links.productDescription}/${payload}`
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(saveProductDescription(data.data));
    }
  } catch (error) {
    console.error("getProductDescriptionsWorker: ", error);
  } finally {
    yield put(setProductDescriptionLoading(false));
  }
}

export function* getProductDescriptionWatcher() {
  yield takeLatest(
    "@saga/GET_PRODUCT_DESCRIPTION",
    getProductDescriptionsWorker
  );
}
