import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { PRODUCTS_PER_PAGE } from "../../../../constants";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { saveNews, setNewsLoading } from "../../repository/actions";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";

interface INewsWorker {
  type: string;
  payload: number;
}

interface IParams {
  page: number;
  perPage: number;
}

function* getNewsWorker({ payload }: INewsWorker): SagaIterator {
  try {
    yield put(setNewsLoading(true));

    const params: IParams = {
      page: payload ?? 1,
      perPage: PRODUCTS_PER_PAGE,
    };

    const { data, isError, message } = yield call(
      requester.get,
      links.news,
      params
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(saveNews(data));
    }
  } catch (error) {
    console.error("getNewsWorker: ", error);
  } finally {
    yield put(setNewsLoading(false));
  }
}

export function* getNewsWatcher() {
  yield takeLatest("@saga/GET_NEWS", getNewsWorker);
}
