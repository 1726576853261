import { INewsResponse } from "./INewsResponse";
import { INews } from "./INews";

interface IInitialState {
  news: INewsResponse;
  newsLoading: boolean;
  newsPage: number;

  singleNews: INews | null;
  singleNewsLoading: boolean;

  isRatingPopupOpen: boolean;
  votedArticleId: number | null;
}

const initialState: IInitialState = {
  news: {
    count: 0,
    data: null,
  },
  newsLoading: false,
  newsPage: 1,

  singleNews: null,
  singleNewsLoading: false,

  isRatingPopupOpen: false,
  votedArticleId: null,
};

export function newsReducer(
  state: IInitialState = initialState,
  action: any
): IInitialState {
  switch (action.type) {
    case "SAVE_NEWS":
      return { ...state, news: action.payload };
    case "SAVE_NEWS_PAGE":
      return { ...state, newsPage: action.payload };
    case "SET_NEWS_LOADING":
      return { ...state, newsLoading: action.payload };

    case "SAVE_SINGLE_NEWS":
      return { ...state, singleNews: action.payload };
    case "SET_SINGLE_NEWS_LOADING":
      return { ...state, singleNewsLoading: action.payload };

    case "SET_RATING_POPUP_OPEN":
      return { ...state, isRatingPopupOpen: action.payload };
    case "SAVE_VOTED_ARTICLE_ID":
      return { ...state, votedArticleId: action.payload };

    default:
      return state;
  }
}
