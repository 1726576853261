import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";

function* getTelegramBotLinkWorker(): SagaIterator {
  try {
    const { data, isError } = yield call(requester.get, links.telegramBot);
    if (!isError) {
      window.open(data, '_blank'); 
    }
  } catch (error) {
    console.error("getTelegramBotLinkWorker: ", error);
  }
};

export function* getTelegramBotLinkWatcher() {
  yield takeLatest("@saga/GET_TELEGRAM_BOT_LINK", getTelegramBotLinkWorker);
};
