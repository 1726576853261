import { useSelector } from "react-redux";
import { selectisLoggedIn } from "../../store/user/repository/selectors";
import UnregisteredMessage from "../unregisteredMessage/unregisteredMessage";
import EditProfileForm from "../editProfileForm/editProfileForm";

const ProfileSection = () => {
  const isLoggedIn = useSelector(selectisLoggedIn);

  if (isLoggedIn !== "true") return <UnregisteredMessage />;

  return <EditProfileForm />;
};

export default ProfileSection;
