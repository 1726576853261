import { IGroupProductsResponse } from "./IGroupProductsResponse";

export const saveGroupProducts = (payload: IGroupProductsResponse) => ({
  type: "SAVE_GROUP_PRODUCTS",
  payload,
});
export const setGroupProductsLoading = (payload: boolean) => ({
  type: "SET_GROUP_PRODUCTS_LOADING",
  payload,
});
export const savePaginationGroupId = (payload: number | undefined) => ({
  type: "SAVE_PAGINATION_GROUP_ID",
  payload,
});
