import { FC, ReactNode, RefObject, useEffect, useRef } from "react";
import { CLASSES } from "../../constants";
import { useOutsideClick } from "../../customHooks";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { AiFillCloseCircle } from "react-icons/ai";
import "./popup.scss";

interface IProps {
  children: ReactNode;
  isOpen: boolean;
  handleClose: () => void;
  noOutsideClick?: boolean;
  triggerRef?: RefObject<HTMLButtonElement>;
  size?: "xxs" | "xs" | "base" | "lg";
}

const Popup: FC<IProps> = ({
  children,
  isOpen,
  handleClose,
  noOutsideClick = false,
  triggerRef,
  size = "base",
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  useOutsideClick(
    contentRef,
    noOutsideClick ? () => {} : handleClose,
    isOpen,
    triggerRef
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        handleClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleClose]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(CLASSES.bodyScrollHide);
      document.body.classList.add(CLASSES.blurred);
      document.documentElement.classList.add(CLASSES.paddingLock);
    } else {
      document.body.classList.remove(CLASSES.bodyScrollHide);
      document.body.classList.remove(CLASSES.blurred);
      document.documentElement.classList.remove(CLASSES.paddingLock);
    }

    return () => {
      document.body.classList.remove(CLASSES.bodyScrollHide);
      document.body.classList.remove(CLASSES.blurred);
      document.documentElement.classList.remove(CLASSES.paddingLock);
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={classNames("popup", `popup--${size}_mod`, {
        "popup--open_state": isOpen,
      })}
    >
      <div ref={contentRef} className="popup__content">
        <button
          className="popup__close font_icon"
          type="button"
          aria-label="Close Popup"
          onClick={handleClose}
        >
          <AiFillCloseCircle />
        </button>
        {children}
      </div>
    </div>,
    document.getElementById("root") as HTMLElement
  );
};

export default Popup;
