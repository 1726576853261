import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import i18n from "../../locales";
import classNames from "classnames";
import {
  setAuthPopupOpen,
  setAuthType,
  setInfoPopupOpen,
} from "../../store/user/repository/actions";
import {
  selectAuthType,
  selectInfoPopupOpen,
  selectPopupOpen,
  selectisLoggedIn,
} from "../../store/user/repository/selectors";
import { ROUTES } from "../../constants";
import { Link } from "react-router-dom";
import { FaUserAlt, FaUserCheck } from "react-icons/fa";
import Popup from "../popup/popup";
import LoginTabs from "../authTabs/authTabs";
import "./userButton.scss";

const UserButton = () => {
  const isOpen = useSelector(selectPopupOpen);
  const isInfoPopupOpen = useSelector(selectInfoPopupOpen);
  const isLoggedIn = useSelector(selectisLoggedIn);
  const authType = useSelector(selectAuthType);

  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const handleClick = () => {
    dispatch(setAuthPopupOpen(true));
  };
  const handleClose = () => dispatch(setAuthPopupOpen(false));
  const handleInfoPopupClose = () => dispatch(setInfoPopupOpen(false));

  useEffect(() => {
    if (authType === "register" && isLoggedIn === "true") {
      setTimeout(() => {
        dispatch(setInfoPopupOpen(true));
        dispatch(setAuthType(null));
      }, 100);
    }
  }, [authType, isLoggedIn]);

  return (
    <>
      {isLoggedIn === "true" ? (
        <Link
          to={ROUTES.Profile}
          aria-label="Profile page"
          className={classNames("user_button", {
            "user_button--current_state": pathname === ROUTES.Profile,
          })}
        >
          <span className="user_button__icon user_button__icon--big_mod">
            <FaUserCheck />
          </span>
        </Link>
      ) : (
        <button
          onClick={handleClick}
          aria-label="Login / register / restore pass"
          className="user_button"
        >
          <span className="user_button__icon">
            <FaUserAlt />
          </span>
        </button>
      )}

      {isOpen && isLoggedIn !== "true" && (
        <Popup
          isOpen={isOpen}
          handleClose={handleClose}
          noOutsideClick
          size="xs"
        >
          <LoginTabs />
        </Popup>
      )}
      {isInfoPopupOpen && (
        <Popup
          size="xxs"
          isOpen={isInfoPopupOpen}
          handleClose={handleInfoPopupClose}
        >
          <div className="user_button__content">
            <h4 className="user_button__title">{i18n.t("attention")}</h4>
            <div className="user_button__text">
              {i18n.t("afterRegisterText")}
            </div>
            <button
              onClick={handleInfoPopupClose}
              type="button"
              className="user_button__popup_button"
            >
              {i18n.t("ok")}
            </button>
          </div>
        </Popup>
      )}
    </>
  );
};

export default UserButton;
