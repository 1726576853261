import { useState, useRef, useEffect, ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import i18n from "../../locales";
import { findPercentFromPrice } from "../../utils/findPercentFromPrice";
import { findPercent } from "../../utils/findPercent";
import { deleteAllCart } from "../../store/orders/useCases/deleteAllCart/action";
import {
  selectCart,
  selectCartChange,
  selectCartLoading,
  selectFactPaynamentMethod,
} from "../../store/orders/repository/selectors";
import {
  selectUser,
  selectisLoggedIn,
} from "../../store/user/repository/selectors";
import { setAuthPopupOpen } from "../../store/user/repository/actions";
import {
  setCartChange,
  setFactPaynamentMethod,
} from "../../store/orders/repository/actions";
import { activatePromoCode } from "../../store/promoCode/useCases/activate/action";
import { LOCAL_STORAGE, ROUTES } from "../../constants";
import { ICartProduct } from "../../store/orders/repository/ICartProduct";
import CartProduct from "../../ui-kit/cartProduct/cartProduct";
import Price from "../price/price";
import InputField from "../inputField/inputField";
import Popup from "../popup/popup";
import ConfirmPopup from "../confirmPopup/confirmPopup";
import Checkbox from "../checkbox/checkbox";
import Loader from "../loader/loader";
import "./cart.scss";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, totalPriceGRN, totalPriceYE, oldTotalPriceGRN, oldTotalPriceYE, promoCode, } = useSelector(selectCart);
  const cartChange = useSelector(selectCartChange);
  const isLoading = useSelector(selectCartLoading);
  const isLoggedIn = useSelector(selectisLoggedIn);
  const isPaynamentMethodFact = useSelector(selectFactPaynamentMethod);
  const user = useSelector(selectUser);

  const { handleSubmit, register } = useForm();

  const [isCheckboxVisible, setCheckboxVisible] = useState<boolean>(false);
  const [products, setProducts] = useState<ICartProduct[]>([]);
  const [priceGRN, setPriceGRN] = useState<string>("");
  const [oldpriceGRN, setOldPriceGRN] = useState<number | undefined>(oldTotalPriceGRN);
  const [priceYe, setPriceYe] = useState<string>(totalPriceYE);
  const [oldPriceYe, setOldPriceYe] = useState<number | undefined>(oldTotalPriceYE);
  const [isConfirmOpen, setConfirmOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handlePromoApply = () => { };

  const handleOpen = () => setConfirmOpen(true);

  const handleClose = () => setConfirmOpen(false);

  const handleClearCart = () => {
    if (isLoggedIn === "true") {
      dispatch(deleteAllCart());
    } else {
      localStorage.removeItem(LOCAL_STORAGE.cart);
      dispatch(setCartChange(0));
    }

    setProducts([]);
    handleClose();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const toOrderingClick = () => {
    if (isLoggedIn === "true") {
      navigate(ROUTES.Ordering);
    } else {
      dispatch(setAuthPopupOpen(true));
    }
  };

  const onSubmit = (data: any) => {
    if (isLoggedIn !== "true") {
      dispatch(setAuthPopupOpen(true));
      return;
    } else {
      if (data.promoCode === promoCode?.promCode) return;

      dispatch(activatePromoCode(data.promoCode));
    }
  };

  const calculateTotalPrice = (oldPrice?: boolean) => {
    const products = localStorage.getItem(LOCAL_STORAGE.cart);
    if (!products) return "0";

    const parsedProducts = JSON.parse(products);

    const totalSum = parsedProducts.reduce(
      (accumulator: number, { product, amount }: ICartProduct) => {
        if (oldPrice) {
          return accumulator + product.price * amount;
        }

        if (product.promo && product.promo.amount && product.promo.percents) {
          let priceWithPromo;
          const { promo } = product;

          if (amount >= promo.amount) {
            priceWithPromo = findPercentFromPrice(
              product.price,
              promo.percents,
              "add"
            );
          } else {
            priceWithPromo = findPercentFromPrice(
              product.price,
              promo.percents,
              "del"
            );
          }

          return accumulator + priceWithPromo * amount;
        }

        return accumulator + product.price * amount;
      },
      0
    );

    return totalSum;
  };

  const setLSProducts = () => {
    const products = localStorage.getItem(LOCAL_STORAGE.cart);
    if (products) {
      return JSON.parse(products);
    }

    return [];
  };

  const handlePaynamentChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.checked) {
      dispatch(setFactPaynamentMethod(true));
    } else {
      dispatch(setFactPaynamentMethod(false));
    }
  };

  useEffect(() => {
    if (isLoggedIn === "true") {
      setPriceGRN(totalPriceGRN);
      setOldPriceGRN(oldTotalPriceGRN);
    } else {
      setPriceGRN(calculateTotalPrice());
      setOldPriceGRN(calculateTotalPrice(true));
    }
  }, [isLoggedIn, totalPriceGRN, oldTotalPriceGRN, cartChange]);

  useEffect(() => {
    setPriceYe(totalPriceYE);
    setOldPriceYe(oldTotalPriceYE);
  }, [totalPriceYE, oldTotalPriceYE]);

  useEffect(() => {
    if (isLoggedIn === "true") {
      setProducts(data);
    } else {
      setProducts(setLSProducts());
    }
  }, [isLoggedIn, data, cartChange]);

  useEffect(() => {
    if (!user || isLoggedIn !== "true") return;

    const isLgWholesale = user.roles.some(({ name }) =>
      name.endsWith("_largeWholesale")
    );
    const isWholesale = user.roles.some(({ name }) =>
      name.endsWith("_wholesale")
    );

    if (isLgWholesale || isWholesale) setCheckboxVisible(true);
  }, [isLoggedIn, user]);

  useEffect(() => {
    if (isLoggedIn !== "true") return;

    if (isPaynamentMethodFact) {
      if (promoCode && promoCode?.type === "PERCENTS") {
        const percentNow = (100 - promoCode.value) / 100;

        const originTotalPriceYe = Number(totalPriceYE) / percentNow;
        const resultYe =
          Number(originTotalPriceYe) -
          (Number(originTotalPriceYe) / 100) * (5 + promoCode.value);

        const originTotalPriceGRN = Number(totalPriceGRN) / percentNow;
        const resultGRN =
          Number(originTotalPriceGRN) -
          (Number(originTotalPriceGRN) / 100) * (5 + promoCode.value);

        setPriceYe(resultYe.toString());
        setPriceGRN(resultGRN.toString());
      } else {
        setPriceGRN(findPercent(totalPriceGRN));
        setPriceYe(findPercent(totalPriceYE));
      }
    } else {
      setPriceGRN(totalPriceGRN);
      setPriceYe(totalPriceYE);
    }
  }, [isLoggedIn, isPaynamentMethodFact, promoCode]);

  return (
    <div className="cart">
      {isLoading && <Loader />}
      {products.length > 0 ? (
        <>
          <div className="cart__body">
            <div className="cart__head">
              <div className="cart__col">{i18n.t("description")}</div>
              <div className="cart__col">{i18n.t("price")}</div>
              <div className="cart__col">{i18n.t("quantity")}</div>
              <div className="cart__col">{i18n.t("remove")}</div>
            </div>
            <ul className="cart__list">
              {products &&
                products.map(({ product, amount }) => {
                  return (
                    <CartProduct
                      key={product.id}
                      initialAmount={amount}
                      {...product}
                    />
                  );
                })}
            </ul>
            <div className="cart_total">
              {Number(priceGRN) > 0 && priceGRN && (
                <div className="cart_total__item">
                  <div className="cart_total__label">{i18n.t("totalSum")}:</div>
                  <div className="cart_total__number">
                    <Price price={priceGRN} oldPrice={oldpriceGRN} />
                  </div>
                </div>
              )}

              {Number(priceYe) > 0 && isLoggedIn === "true" && (
                <div className="cart_total__item">
                  <div className="cart_total__label">{i18n.t("totalSum")}:</div>
                  <div className="cart_total__number">
                    <Price
                      currency="ye"
                      price={priceYe}
                      oldPrice={oldPriceYe}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="cart__bottom">
            {isCheckboxVisible && (
              <div className="cart__checkbox">
                <Checkbox
                  id="fact"
                  value="Fact"
                  register={register("paymentMethod")}
                  text={i18n.t("factPay")}
                  defaultChecked={isPaynamentMethodFact}
                  onChange={handlePaynamentChange}
                />
              </div>
            )}

            <form
              action="#"
              method="POST"
              onSubmit={handleSubmit(onSubmit)}
              className="cart_promo"
            >
              <div className="cart_promo__input">
                <InputField
                  id="promoCode"
                  register={register("promoCode")}
                  label={`${i18n.t("promoCode")}`}
                  defaultValue={promoCode?.promCode}
                  placeholder={`${i18n.t("enter")} ${i18n.t("promoCode")}`}
                  required
                />
              </div>
              <button
                className="cart_promo__button"
                type="submit"
                onClick={handlePromoApply}
              >
                {i18n.t("apply")}
              </button>
            </form>

            <div className="cart__bottom_in">
              <button
                type="button"
                onClick={toOrderingClick}
                className="cart__button cart__button--secondary_mod"
              >
                <span>{i18n.t("toOrdering")}</span>
              </button>
              <button
                ref={buttonRef}
                onClick={handleOpen}
                type="button"
                className="cart__button cart__button--primary_mod"
              >
                <span>{i18n.t("clearCart")}</span>
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="cart__message">
          <p>{i18n.t("emptyCart1")}</p>
          <p>{i18n.t("emptyCart2")}</p>
        </div>
      )}
      <Popup
        isOpen={isConfirmOpen}
        handleClose={handleClose}
        triggerRef={buttonRef}
        size="xxs"
      >
        <ConfirmPopup
          title={i18n.t("agreeDelete")}
          onConfirm={handleClearCart}
          onDecline={handleClose}
        />
      </Popup>
    </div>
  );
};

export default Cart;
