import { FC, ChangeEventHandler } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import "./textarea.scss";

interface IProps {
  id: string;
  register: UseFormRegisterReturn;
  autoComplete?: string;
  required?: boolean;
  value?: string;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
}

const Textarea: FC<IProps> = ({ id, register, ...props }) => {
  return (
    <textarea
      maxLength={1000}
      id={id}
      {...props}
      {...register}
      className="textarea"
    />
  );
};

export default Textarea;
