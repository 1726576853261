import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";
import { setAppLoading } from "../../../app/repository/actions";
import { saveRegisterData, saveRestoreData } from "../../repository/actions";
import { IRestorePassData } from "../../repository/IRestorePassData";
import { setConfirmation, saveToken } from "../../repository/actions";
import { LOCAL_STORAGE } from "../../../../constants";

interface IRestorePassWorker {
  type: string;
  payload: IRestorePassData;
}

interface IRestoreParams {
  email?: string;
  phone?: string;
}

function* restorePassWorker({ payload }: IRestorePassWorker): SagaIterator {
  const { input, email, phone } = payload;

  try {
    yield put(setAppLoading(true));
    const link =
      input === "email" ? links.restorePassEmail : links.restorePassPhone;

    const params: IRestoreParams = {};
    if (input === "email") params.email = email;
    else params.phone = phone;

    const { data, isError, message } = yield call(requester.post, link, params);

    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else if (data.message === "please_confirm_your_phone") {
      createNotification({
        title: i18n.t("error"),
        message: i18n.t(data.message),
        type: "danger",
      });
    } else {
      if (data.token) {
        yield put(saveRegisterData(null));
        yield put(saveRestoreData(payload));

        yield put(setConfirmation(true));
        yield put(saveToken(data.token));
        localStorage.setItem(LOCAL_STORAGE.token, data.token);
        localStorage.setItem(LOCAL_STORAGE.confirmMod, input);
      } else {
        createNotification({
          title: i18n.t("error"),
          message: i18n.t(data.message),
          type: "danger",
        });
      }
    }
  } catch (error) {
    console.error("restorePassWorker: ", error);
  } finally {
    yield put(setAppLoading(false));
  }
}

export function* restorePassWatcher() {
  yield takeLatest("@saga/RESTORE_PASS", restorePassWorker);
}
