import { combineReducers } from "@reduxjs/toolkit";
import { catalogReducer } from "./catalog/repository/reducer";
import { appReducer } from "./app/repository/reducer";
import { productsReducer } from "./products/repository/reducer";
import { languagesReducer } from "./language/repository/reducer";
import { userReducer } from "./user/repository/reducer";
import { newsReducer } from "./news/repository/reducer";
import { groupProductsReducer } from "./groupProducts/repository/reducer";
import { promoProductsReducer } from "./promo/repository/reducer";
import { favoritesReducer } from "./favorites/repository/reducer";
import { reviewsReducer } from "./reviews/repository/reducer";
import { ordersReducer } from "./orders/repository/reducer";
import { bannersReducer } from "./banners/repository/reducer";
import { promoCodeReducer } from "./promoCode/repository/reducer";
import { notificationsReducer } from "./notifications/repository/reducer";
import { waitingReducer } from "./waitingList/repository/reducer";
import { priceListReducer } from "./excel/repository/reducer";
import { xmlReducer } from "./excel copy/repository/reducer";

export const rootReducer = combineReducers({
  app: appReducer,
  catalog: catalogReducer,
  languages: languagesReducer,
  products: productsReducer,
  user: userReducer,
  news: newsReducer,
  groupProducts: groupProductsReducer,
  promoProducts: promoProductsReducer,
  favorites: favoritesReducer,
  reviews: reviewsReducer,
  orders: ordersReducer,
  banners: bannersReducer,
  promoCode: promoCodeReducer,
  notifications: notificationsReducer,
  waiting: waitingReducer,
  priceList: priceListReducer,
  xml: xmlReducer,
});
