import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { saveFilters } from "../../repository/actions";
import { createNotification } from "../../../../utils/createNotification";
import { IFilterRequest } from "../../repository/IFilterRequest";

interface IGetFiltersWorker {
  type: string;
  payload: {
    categoryId?: string;
    filter?: IFilterRequest[];
    promoId?: number;
  };
};

function* getFiltersWorker({ payload }: IGetFiltersWorker): SagaIterator {
  const { categoryId, filter, promoId } = payload;
  
  try {
    let params: any = {};
    if(categoryId) params = { categoryId };
    if(promoId) params = { promoId };

    if (filter && filter.length > 0) {
      params.filter = JSON.stringify(filter);
    };

    const { data, isError, message } = yield call(requester.get, links.filters, params);

    if (isError) {
      createNotification({ title: i18n.t("error"), message, type: "danger" });
    } else {
      yield put(saveFilters(data.filters));
    }
  } catch (error) {
    console.error("getFiltersWorker: ", error);
  } finally {
  }
};

export function* getFiltersWatcher() {
  yield takeLatest("@saga/GET_FILTERS", getFiltersWorker);
};
