import { FC } from "react";
import Image from "../image/image";

interface IProps {
  image?: string | null;
  title?: string | null;
  text?: string | null;
}

const SingleColumn: FC<IProps> = ({ image, title, text }) => {
  const hasInfo = title || text;

  return (
    <div className="images_descriptions__column images_descriptions__column--full_mod">
      {hasInfo && (
        <div className="images_descriptions__content">
          {title && <h3 className="images_descriptions__title">{title}</h3>}
          {text && <div className="images_descriptions__text">{text}</div>}
        </div>
      )}
      {image && (
        <Image
          imgClass="images_descriptions__image"
          src={image}
          alt={title ?? " "}
          contain
        />
      )}
    </div>
  );
};

export default SingleColumn;
