import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { call, put, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";
import { getWaitingList } from "../getWaitingList/action";

interface IDeleteFromWaitingWorker {
  type: string;
  payload: {
    id: number;
    page?: number;
  };
}

function* deleteFromWaitingWorker({
  payload,
}: IDeleteFromWaitingWorker): SagaIterator {
  try {
    const { isError, message } = yield call(
      requester.post,
      links.deleteFromWaiting,
      { productId: payload.id }
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      createNotification({
        title: i18n.t("successDeleteWaiting"),
        message,
        type: "success",
      });
      yield put(getWaitingList(payload.page, true));
    }
  } catch (error) {
    console.error("deleteFromWaitingWorker: ", error);
  }
}

export function* deleteFromWaitingWatcher() {
  yield takeLatest("@saga/DELETE_FROM_WAITING", deleteFromWaitingWorker);
}
