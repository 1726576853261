import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../locales";
import Section from "../section/section";
import Pagination from "../pagination/pagination";
import Loader from "../loader/loader";
import "./promotionSection.scss";
import PromotionCard from "../promotionCard/promotionCard";
import { selectPromoProductsLoading, selectPromotionCount, selectPromotionList } from "../../store/promo/repository/selectors";
import EmptyState from "../emptyState/emptyState";
import { PRODUCTS_PER_PAGE } from "../../constants";
import { getPromoList } from "../../store/promo/useCases/getPromoList/action";

const PromotionSection = () => {
  const promotionList = useSelector(selectPromotionList);
  const promotionCount = useSelector(selectPromotionCount);

  const dispatch = useDispatch();

  const [activeItem, setActiveItem] = useState(1);
  const isPromoLoading = useSelector(selectPromoProductsLoading);

  const paginationClick = (page: number) => {
    dispatch(getPromoList({ page, perPage: 20 }));
    setActiveItem(page);
  };

  return (
    <Section className="promotion_section">
      {isPromoLoading && <Loader />}
      <h1 className="promotion_section__title">{i18n.t("promotions")}</h1>
      <ul className="promotion_section__list">
        {!!promotionList?.length ?
          promotionList.map((item: any) => {
            return (
              <li key={item.id} className="promotion_section__item">
                <PromotionCard item={item} />
              </li>
            );
          })
          :
          <EmptyState
            title={i18n.t("emptyStateTitle")}
            description={i18n.t("emptyStateDesc")}
            image="/favicon-310.png"
          />
        }
      </ul>
      {promotionCount > PRODUCTS_PER_PAGE && (
        <div style={{marginTop: '50px'}}>
          <Pagination
            pagesCount={promotionCount}
            onClick={paginationClick}
            activeItem={activeItem}
          />
        </div>
      )}
    </Section>
  );
};

export default PromotionSection;
