import { IReviewsResponse } from "./IReviewsResponse";

interface IInitialState {
  productReviews: IReviewsResponse;
  productReviewsLoading: boolean;
  productReviewsPage: number;
  productReviewsPopupOpen: boolean;
}

const initialState: IInitialState = {
  productReviews: { data: [], count: 0 },
  productReviewsLoading: false,
  productReviewsPage: 1,
  productReviewsPopupOpen: false,
};

export function reviewsReducer(
  state: IInitialState = initialState,
  action: any
): IInitialState {
  switch (action.type) {
    case "SAVE_PRODUCT_REVIEWS":
      return { ...state, productReviews: action.payload };
    case "SAVE_PRODUCT_REVIEWS_PAGE":
      return { ...state, productReviewsPage: action.payload };
    case "SET_PRODUCT_REVIEWS_LOADING":
      return { ...state, productReviewsLoading: action.payload };
    case "SET_PRODUCT_REVIEWS_POPUP_OPEN":
      return { ...state, productReviewsPopupOpen: action.payload };

    default:
      return state;
  }
}
