import i18n from "../../locales";
import { useProductAvailable, useMinimalPrice } from "../../customHooks";
import { memo } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/user/repository/selectors";
import { FC } from "react";
import { IProduct } from "../../store/products/repository/IProduct";
import { ROUTES } from "../../constants";
import { Link } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import Image from "../image/image";
import Rating from "../rating/rating";
import Price from "../price/price";
import LikeButton from "../likeButton/likeButton";
import BuyButton from "../buyButton/buyButton";
import "./productCard.scss";

interface IProps {
  product: IProduct;
  onDelete?: (id: number) => void;
};

const ProductCard: FC<IProps> = ({ product, onDelete }) => {
  const { id, name, price, largeWholesalePrice, wholesalePrice, rating, images, isFavorite, isSale, promo, initialCount, isAvailable, minimalCount, warehouse, oldPrice, oldWholesalePrice, oldLargeWholesalePrice } = product;
  const linkTo = `${ROUTES.SingleProduct}/${id}`;
  const user = useSelector(selectUser);

  const { productAvailable } = useProductAvailable(warehouse, isAvailable, user);
  const { minimalPrice, currency, previousPrice } = useMinimalPrice({ largeWholesalePrice, wholesalePrice, price, oldPrice, oldWholesalePrice, oldLargeWholesalePrice });

  return (
    <div className="product_card">
      <div className="product_card__block">
        <Link to={linkTo} className="product_card__link">
          <div className="product_card__label_container">
            {isSale && (
              <div className="product_card__label product_card__label--sale_mod">
                {i18n.t("sale")}
              </div>
            )}
            {promo && (
              <div className="product_card__label product_card__label--promo_mod">
                {i18n.t("promo")}
              </div>
            )}
            {initialCount && initialCount > 1 && (
              <div className="product_card__label product_card__label--pair_mod">
                {i18n.t("pair")}
              </div>
            )}
          </div>
          <div className="product_card_image">
            <div className="product_card_image__wrap">
              {images.length > 0 && (
                <Image
                  imgClass={"product_card_image__picture"}
                  src={images[0].fileUrl}
                  contain
                  alt={name}
                />
              )}
            </div>
          </div>
        </Link>
        <div className="product_card__like">
          <LikeButton
            key={`${isFavorite}`}
            isActive={isFavorite}
            productId={id}
          />
        </div>
        {onDelete && (
          <button
            className="product_card__delete_btn font_icon"
            type="button"
            aria-label="Delete from notify"
            onClick={() => onDelete(id)}
          >
            <RxCross2 />
          </button>
        )}
      </div>
      <div className="product_card__container">
        <Link to={linkTo} className="product_card__link">
          <span className="product_card__title">{name}</span>
        </Link>
        <div className="product_card__rating">
          <Rating size="small" rating={rating} />
          {rating && <span>{Math.round(rating)}</span>}
        </div>
        <div className="product_card__wrap">
          <div className="product_card__price">
            <Price price={minimalPrice} currency={currency} oldPrice={previousPrice} promo={promo} />
          </div>
          {!onDelete && (
            <BuyButton
              productAvailable={productAvailable}
              id={id}
              product={product}
              minimalCount={minimalCount}
              className="product_card__button"
              productAmount={minimalCount || 1}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(ProductCard);
