import { FC } from "react";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import classNames from "classnames";
import "./rating.scss";

interface IProps {
  rating?: number;
  size?: "base" | "small";
}

const Rating: FC<IProps> = ({ rating = 0, size = "base" }) => {
  const fullStars = Math.round(rating);

  return (
    <div className={classNames("rating", `rating--${size}_mod`)}>
      <div className="rating__stars">
        {[1, 2, 3, 4, 5].map((item) => (
          <div key={item} className="rating__star font_icon">
            <AiOutlineStar />
          </div>
        ))}
      </div>
      {rating > 0 && (
        <div className="rating__stars rating__stars--fill_mod">
          {[...Array(fullStars)].slice(0, 5).map((_, index) => (
            <div key={index} className="rating__star font_icon">
              <AiFillStar />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Rating;
