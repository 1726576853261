import i18n from "../../../../locales";
import { createNotification } from "../../../../utils/createNotification";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { saveCart, setCartLoading } from "../../repository/actions";

function* deleteAllCartWorker(): SagaIterator {
  try {
    yield put(setCartLoading(true));

    const { isError, message } = yield call(
      requester.delete,
      links.deleteAllFromOrder,
      undefined,
      undefined,
      true
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      createNotification({
        title: i18n.t("success"),
        message,
        type: "success",
      });
      yield put(saveCart({ data: [], totalPriceGRN: "", totalPriceYE: "" }));
    }
  } catch (error) {
    console.error("deleteAllCartWorker: ", error);
  } finally {
    yield put(setCartLoading(false));
  }
}

export function* deleteAllCartWatcher() {
  yield takeLatest("@saga/DELETE_ALL_CART", deleteAllCartWorker);
}
