import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectisLoggedIn } from "../../store/user/repository/selectors";
import ProfileWrapper from "../../ui-kit/profileWrapper/profileWrapper";
import NotifyProducts from "../../ui-kit/notifyProducts/notifyProducts";
import { getWaitingList } from "../../store/waitingList/useCases/getWaitingList/action";

const NotifyProductsPage = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectisLoggedIn);

  useEffect(() => {
    if (isLoggedIn !== "true") return;

    dispatch(getWaitingList());
  }, [isLoggedIn]);

  return (
    <ProfileWrapper>
      <NotifyProducts />
    </ProfileWrapper>
  );
};

export default NotifyProductsPage;
