import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { call, put, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";
import { saveSinglePromo, setPromoProductsLoading } from "../../repository/actions";
import { IProductSettings } from "../../../products/repository/IProductSettings";
import { PRODUCTS_PER_PAGE, REGULARS } from "../../../../constants";
import { saveProductsPrices } from "../../../products/repository/actions";

interface IGetPromoProductsWorker {
  type: string;
  payload: {
    payload: IProductSettings;
    name: string;
  };
};

function* getSinglePromoWorker({ payload }: IGetPromoProductsWorker): SagaIterator {
  const { id = 0, filter = [], priceMin, priceMax, sortBy = [], page = 1, perPage = PRODUCTS_PER_PAGE, slider, productGroupId, noLoading, isSavePrice, isPromo } = payload.payload;

  try {
    yield put(setPromoProductsLoading(true));

    const { name } = payload;
    const sortByArr = slider ? [] : [{ field: "isAvailable", order: "DESC" }, ...sortBy];

    const params: any = { page, perPage, sortBy: JSON.stringify(sortByArr) };

    if (name) {
      if (REGULARS.isNumbersOnly.test(name)) {
        params.code1c = name;
      } else {
        params.name = name;
      }
    } else if (slider) {
      slider === "sale" && (params.isSale = true);
      slider === "promo" && (params.isPromo = true);
      slider === "group" && (params.productGroupId = productGroupId);
      id && (params.categoryId = id);
    } else {
      params.promoId = id;

      priceMin && (params.priceMin = priceMin);
      priceMax && (params.priceMax = priceMax);
      if (filter && filter.length > 0) {
        params.filter = JSON.stringify(filter);
      }
    }

    const { data, isError, message } = yield call(requester.get, links.singlePromo + `${id}/products`, params);
    if (isError) {
      createNotification({ title: i18n.t("error"), message, type: "danger" });
    } else {
      yield put(saveSinglePromo(data));
      if (isSavePrice) {
        yield put(saveProductsPrices(data.products.prices));
      }
    }
  } catch (error) {
    console.error("getSinglePromoWorker: ", error);
  } finally {
    yield put(setPromoProductsLoading(false));
  }
};

export function* getSinglePromoWatcher() {
  yield takeLatest("@saga/GET_SINGLE_PROMO", getSinglePromoWorker);
};
