import { FC } from "react";
import "./orderInfoItem.scss";

interface IProps {
  label: string;
  value: string;
}

const OrderInfoItem: FC<IProps> = ({ label, value }) => {
  return (
    <div className="order_info_item">
      <div className="order_info_item__key">{label}:</div>
      <div className="order_info_item__value">{value}</div>
    </div>
  );
};

export default OrderInfoItem;
