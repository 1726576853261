import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import {
  saveShortPriceList,
  setShortPriceListLoading,
} from "../../repository/actions";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";

function* getShortPriceListWorker(): SagaIterator {
  try {
    yield put(setShortPriceListLoading(true));
    const { data, isError, message } = yield call(
      requester.get,
      links.getShortPriceList
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(saveShortPriceList(data));
    }
  } catch (error) {
    console.error("getShortPriceListWorker: ", error);
  } finally {
    yield put(setShortPriceListLoading(false));
  }
}

export function* getShortPriceListWatcher() {
  yield takeLatest("@saga/GET_SHORT_PRICE_LIST", getShortPriceListWorker);
}
