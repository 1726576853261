export const transformPrice = (price: number | string) => {
  const roundedPrice = Number(price).toFixed(2);
  const decimalPart = Number(roundedPrice.split(".")[1]);

  if (decimalPart === 0) {
    return roundedPrice.split(".")[0];
  }

  return roundedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
