import { useSelector } from "react-redux";
import {
  selectSingleNews,
  selectSingleNewsLoading,
} from "../../store/news/repository/selectors";
import { transformDate } from "../../utils/transformDate";
import Section from "../section/section";
import Loader from "../loader/loader";
import SingleNewsContent from "./ui/singleNewsContent";
import "./singleNewsSection.scss";

const SingleNewsSection = () => {
  const currentNews = useSelector(selectSingleNews);
  const isSingleNewsLoading = useSelector(selectSingleNewsLoading);

  const created_at = currentNews?.created_at; 
  let formattedDate = "";

  if (created_at) {
    formattedDate = transformDate(created_at);
  }

  return (
    <Section className="single_news">
      {isSingleNewsLoading && <Loader />}
      <div className="single_news__head">
        {currentNews?.title && (
          <h1 className="single_news__title">{currentNews.title}</h1>
        )}
        {currentNews?.created_at && (
          <div className="single_news__date">{formattedDate}</div>
        )}
      </div>
      {currentNews?.content && (
        <SingleNewsContent content={currentNews.content} />
      )}
    </Section>
  );
};

export default SingleNewsSection;
