export const PaymentVisa = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1582 512"
    >
      <path
        fill="currentColor"
        d="M685.538 504.613h-128.154l80.157-495.63h128.147z"
      ></path>
      <path
        fill="currentColor"
        d="M1150.108 21.101c-25.277-10.028-65.37-21.101-114.944-21.101-126.557 0-215.677 67.487-216.223 163.972-1.051 71.186 63.808 110.725 112.317 134.461 49.581 24.255 66.436 40.086 66.436 61.705-0.505 33.204-40.064 48.509-76.961 48.509-51.165 0-78.58-7.891-120.242-26.364l-16.875-7.919-17.933 111.258c30.057 13.694 85.434 25.846 142.927 26.378 134.469 0 222.011-66.442 223.048-169.263 0.512-56.421-33.736-99.653-107.572-134.98-44.823-22.678-72.273-37.969-72.273-61.172 0.525-21.094 23.217-42.7 73.815-42.7 41.662-1.058 72.273 8.956 95.462 18.977l11.591 5.263 17.428-107.025z"
      ></path>
      <path
        fill="currentColor"
        d="M1320.436 329.029c10.554-28.474 51.165-138.673 51.165-138.673-0.533 1.058 10.533-28.999 16.861-47.451l8.956 42.707c0 0 24.268 118.638 29.538 143.417-20.028 0-81.208 0-106.521 0v0zM1478.627 8.984h-99.128c-30.569 0-53.793 8.956-66.982 41.123l-190.357 454.5h134.468c0 0 22.138-61.173 26.897-74.347 14.752 0 145.562 0 164.54 0 3.679 17.401 15.292 74.347 15.292 74.347h118.659l-103.388-495.623z"
      ></path>
      <path
        fill="currentColor"
        d="M450.344 8.984l-125.505 337.972-13.714-68.545c-23.204-79.091-95.974-165.023-177.182-207.75l114.958 433.427h135.519l201.436-495.104h-135.512z"
      ></path>
      <path
        fill="currentColor"
        d="M208.297 8.984h-206.188l-2.11 10.014c160.839 41.13 267.36 140.271 311.125 259.434l-44.823-227.786c-7.38-31.655-30.057-40.618-58.005-41.662v0z"
      ></path>
    </svg>
  );
};
