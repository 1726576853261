import { IGroupProductsResponse } from "./IGroupProductsResponse";

interface IInitialState {
  groupList: IGroupProductsResponse;
  isGroupProductsLoading: boolean;
  paginationGroupId: number | null;
}

const initialState: IInitialState = {
  groupList: {
    count: 0,
    data: [],
  },
  isGroupProductsLoading: false,
  paginationGroupId: null,
};

export function groupProductsReducer(
  state: IInitialState = initialState,
  action: any
): IInitialState {
  switch (action.type) {
    case "SAVE_GROUP_PRODUCTS":
      return { ...state, groupList: action.payload };

    case "SET_GROUP_PRODUCTS_LOADING":
      return { ...state, isGroupProductsLoading: action.payload };

    case "SAVE_PAGINATION_GROUP_ID":
      return { ...state, paginationGroupId: action.payload };

    default:
      return state;
  }
}
