const AppStore = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1728 512"
    >
      <path
        fill="currentColor"
        d="M385.639 253.235c-0.371-41.255 33.779-61.325 35.341-62.259-19.341-28.198-49.318-32.051-59.853-32.358-25.178-2.649-49.6 15.065-62.425 15.065-13.082 0-32.832-14.81-54.118-14.375-27.392 0.423-53.017 16.281-67.072 40.909-29.005 50.214-7.373 124.007 20.416 164.595 13.901 19.878 30.144 42.074 51.405 41.293 20.8-0.858 28.569-13.261 53.67-13.261 24.87 0 32.166 13.261 53.849 12.762 22.323-0.358 36.377-19.968 49.792-40.026 16.064-22.784 22.515-45.223 22.771-46.375-0.525-0.179-43.353-16.525-43.776-65.971z"
      ></path>
      <path
        fill="currentColor"
        d="M344.678 131.917c11.187-13.99 18.841-33.024 16.717-52.339-16.192 0.717-36.442 11.2-48.103 24.883-10.317 12.058-19.533 31.821-17.152 50.407 18.189 1.357 36.864-9.178 48.538-22.951z"
      ></path>
      <path
        fill="currentColor"
        d="M686.656 403.251h-29.069l-15.923-50.035h-55.347l-15.168 50.035h-28.301l54.835-170.343h33.869l55.104 170.343zM636.864 332.224l-14.4-44.48c-1.523-4.544-4.378-15.245-8.589-32.089h-0.512c-1.677 7.245-4.378 17.945-8.090 32.089l-14.144 44.48h45.735z"
      ></path>
      <path
        fill="currentColor"
        d="M827.673 340.327c0 20.889-5.645 37.401-16.934 49.523-10.112 10.791-22.669 16.179-37.658 16.179-16.179 0-27.802-5.811-34.88-17.433h-0.512v64.704h-27.289v-132.441c0-13.133-0.346-26.611-1.011-40.435h24l1.523 19.469h0.512c9.101-14.669 22.912-21.99 41.447-21.99 14.49 0 26.586 5.721 36.263 17.178 9.702 11.469 14.541 26.547 14.541 45.248zM799.872 341.325c0-11.955-2.688-21.811-8.090-29.568-5.901-8.089-13.824-12.135-23.757-12.135-6.733 0-12.851 2.253-18.317 6.695-5.479 4.48-9.063 10.329-10.739 17.575-0.845 3.379-1.267 6.144-1.267 8.32v20.48c0 8.935 2.739 16.474 8.217 22.63s12.595 9.229 21.351 9.229c10.279 0 18.279-3.968 24-11.878 5.735-7.923 8.601-18.368 8.601-31.347z"
      ></path>
      <path
        fill="currentColor"
        d="M968.947 340.327c0 20.889-5.645 37.401-16.947 49.523-10.099 10.791-22.656 16.179-37.645 16.179-16.179 0-27.802-5.811-34.867-17.433h-0.512v64.704h-27.289v-132.441c0-13.133-0.346-26.611-1.011-40.435h24l1.523 19.469h0.512c9.088-14.669 22.899-21.99 41.447-21.99 14.477 0 26.573 5.721 36.275 17.178 9.664 11.469 14.515 26.547 14.515 45.248zM941.145 341.325c0-11.955-2.701-21.811-8.102-29.568-5.901-8.089-13.798-12.135-23.744-12.135-6.745 0-12.851 2.253-18.33 6.695-5.479 4.48-9.049 10.329-10.726 17.575-0.832 3.379-1.267 6.144-1.267 8.32v20.48c0 8.935 2.739 16.474 8.192 22.63 5.479 6.144 12.595 9.229 21.376 9.229 10.279 0 18.279-3.968 24-11.878 5.735-7.923 8.601-18.368 8.601-31.347z"
      ></path>
      <path
        fill="currentColor"
        d="M1126.899 355.481c0 14.49-5.031 26.279-15.13 35.379-11.098 9.945-26.547 14.912-46.4 14.912-18.33 0-33.024-3.533-44.147-10.611l6.323-22.745c11.981 7.245 25.127 10.88 39.449 10.88 10.278 0 18.278-2.329 24.025-6.963 5.722-4.634 8.576-10.854 8.576-18.611 0-6.912-2.355-12.736-7.079-17.459-4.697-4.723-12.544-9.113-23.501-13.171-29.824-11.123-44.723-27.418-44.723-48.845 0-14.003 5.223-25.485 15.68-34.419 10.419-8.947 24.32-13.415 41.703-13.415 15.501 0 28.378 2.701 38.656 8.089l-6.822 22.246c-9.6-5.222-20.455-7.833-32.601-7.833-9.6 0-17.101 2.368-22.477 7.078-4.544 4.211-6.822 9.344-6.822 15.424 0 6.733 2.599 12.301 7.821 16.678 4.544 4.045 12.8 8.423 24.781 13.146 14.656 5.901 25.421 12.8 32.345 20.711 6.899 7.885 10.342 17.753 10.342 29.529z"
      ></path>
      <path
        fill="currentColor"
        d="M1217.127 300.902h-30.080v59.635c0 15.168 5.299 22.745 15.923 22.745 4.877 0 8.921-0.423 12.121-1.267l0.755 20.723c-5.376 2.010-12.455 3.021-21.223 3.021-10.777 0-19.2-3.289-25.28-9.856-6.054-6.579-9.101-17.613-9.101-33.113v-61.913h-17.92v-20.48h17.92v-22.49l26.803-8.089v30.579h30.080v20.505z"
      ></path>
      <path
        fill="currentColor"
        d="M1352.845 340.825c0 18.88-5.401 34.381-16.179 46.502-11.303 12.48-26.304 18.701-45.005 18.701-18.023 0-32.371-5.978-43.072-17.933s-16.051-27.046-16.051-45.235c0-19.034 5.504-34.624 16.551-46.745 11.021-12.135 25.895-18.202 44.595-18.202 18.023 0 32.525 5.978 43.469 17.945 10.47 11.61 15.693 26.599 15.693 44.966zM1324.531 341.709c0-11.328-2.419-21.043-7.322-29.146-5.722-9.805-13.901-14.695-24.499-14.695-10.969 0-19.303 4.902-25.024 14.695-4.902 8.115-7.322 17.984-7.322 29.658 0 11.328 2.419 21.043 7.322 29.133 5.901 9.805 14.144 14.695 24.781 14.695 10.419 0 18.599-4.992 24.499-14.95 5.031-8.256 7.565-18.074 7.565-29.389z"
      ></path>
      <path
        fill="currentColor"
        d="M1441.549 304.423c-2.701-0.499-5.581-0.755-8.601-0.755-9.6 0-17.024 3.623-22.246 10.88-4.544 6.4-6.822 14.49-6.822 24.256v64.448h-27.277l0.256-84.147c0-14.157-0.345-27.046-1.024-38.669h23.77l0.998 23.501h0.755c2.88-8.077 7.424-14.579 13.645-19.456 6.080-4.39 12.647-6.579 19.725-6.579 2.522 0 4.8 0.179 6.822 0.499v26.022z"
      ></path>
      <path
        fill="currentColor"
        d="M1563.597 336.026c0 4.889-0.32 9.011-0.998 12.377h-81.869c0.32 12.135 4.275 21.414 11.879 27.815 6.899 5.721 15.821 8.589 26.777 8.589 12.121 0 23.181-1.933 33.127-5.811l4.275 18.944c-11.622 5.069-25.344 7.591-41.178 7.591-19.046 0-33.997-5.606-44.877-16.807-10.854-11.2-16.294-26.24-16.294-45.107 0-18.522 5.056-33.945 15.181-46.246 10.599-13.133 24.921-19.699 42.944-19.699 17.703 0 31.104 6.567 40.205 19.699 7.207 10.432 10.829 23.334 10.829 38.656zM1537.574 328.947c0.179-8.089-1.6-15.079-5.299-20.979-4.723-7.591-11.981-11.379-21.747-11.379-8.921 0-16.179 3.699-21.722 11.123-4.544 5.901-7.245 12.979-8.077 21.223h56.845z"
      ></path>
      <path
        fill="currentColor"
        d="M627.84 128.115c0 15.065-4.519 26.406-13.543 34.023-8.359 7.027-20.237 10.547-35.623 10.547-7.629 0-14.157-0.333-19.622-0.999v-82.317c7.13-1.152 14.809-1.741 23.104-1.741 14.656 0 25.702 3.187 33.152 9.561 8.346 7.207 12.531 17.511 12.531 30.925zM613.696 128.487c0-9.767-2.586-17.255-7.757-22.477-5.171-5.21-12.723-7.821-22.669-7.821-4.224 0-7.821 0.281-10.803 0.871v62.579c1.651 0.256 4.672 0.371 9.063 0.371 10.265 0 18.189-2.855 23.769-8.563s8.397-14.029 8.397-24.96z"
      ></path>
      <path
        fill="currentColor"
        d="M702.835 141.274c0 9.28-2.649 16.883-7.949 22.848-5.555 6.131-12.915 9.19-22.106 9.19-8.857 0-15.91-2.931-21.171-8.819-5.248-5.875-7.872-13.286-7.872-22.221 0-9.344 2.701-17.011 8.128-22.963s12.723-8.934 21.913-8.934c8.857 0 15.975 2.931 21.363 8.806 5.12 5.709 7.693 13.081 7.693 22.093zM688.922 141.709c0-5.568-1.203-10.343-3.597-14.323-2.816-4.813-6.822-7.219-12.032-7.219-5.389 0-9.485 2.406-12.301 7.219-2.407 3.981-3.597 8.832-3.597 14.566 0 5.568 1.203 10.343 3.597 14.323 2.905 4.813 6.951 7.219 12.173 7.219 5.12 0 9.139-2.445 12.032-7.347 2.483-4.058 3.725-8.87 3.725-14.439z"
      ></path>
      <path
        fill="currentColor"
        d="M803.392 111.603l-18.88 60.339h-12.288l-7.821-26.202c-1.984-6.541-3.597-13.043-4.851-19.495h-0.243c-1.165 6.63-2.778 13.12-4.851 19.495l-8.307 26.202h-12.429l-17.754-60.339h13.785l6.822 28.685c1.651 6.784 3.008 13.248 4.096 19.367h0.243c0.999-5.043 2.649-11.469 4.979-19.238l8.563-28.8h10.931l8.205 28.186c1.984 6.874 3.597 13.491 4.838 19.865h0.371c0.909-6.208 2.279-12.826 4.096-19.865l7.322-28.186h13.171z"
      ></path>
      <path
        fill="currentColor"
        d="M872.934 171.942h-13.414v-34.56c0-10.65-4.045-15.975-12.16-15.975-3.981 0-7.193 1.459-9.689 4.391-2.471 2.931-3.725 6.387-3.725 10.343v35.789h-13.414v-43.085c0-5.299-0.167-11.046-0.486-17.267h11.789l0.627 9.433h0.371c1.562-2.931 3.891-5.35 6.951-7.283 3.635-2.253 7.706-3.392 12.16-3.392 5.632 0 10.317 1.817 14.042 5.466 4.634 4.467 6.951 11.136 6.951 19.993v36.147z"
      ></path>
      <path
        fill="currentColor"
        d="M909.927 171.942h-13.402v-88.025h13.402v88.025z"
      ></path>
      <path
        fill="currentColor"
        d="M988.903 141.274c0 9.28-2.649 16.883-7.949 22.848-5.555 6.131-12.928 9.19-22.106 9.19-8.87 0-15.923-2.931-21.171-8.819-5.248-5.875-7.872-13.286-7.872-22.221 0-9.344 2.701-17.011 8.128-22.963s12.723-8.934 21.901-8.934c8.87 0 15.975 2.931 21.376 8.806 5.12 5.709 7.693 13.081 7.693 22.093zM974.976 141.709c0-5.568-1.203-10.343-3.597-14.323-2.803-4.813-6.822-7.219-12.019-7.219-5.402 0-9.497 2.406-12.301 7.219-2.407 3.981-3.597 8.832-3.597 14.566 0 5.568 1.203 10.343 3.597 14.323 2.905 4.813 6.951 7.219 12.173 7.219 5.12 0 9.126-2.445 12.019-7.347 2.496-4.058 3.725-8.87 3.725-14.439z"
      ></path>
      <path
        fill="currentColor"
        d="M1053.824 171.942h-12.045l-0.998-6.951h-0.371c-4.121 5.542-9.997 8.32-17.625 8.32-5.696 0-10.304-1.831-13.773-5.465-3.149-3.303-4.723-7.411-4.723-12.288 0-7.373 3.072-12.992 9.255-16.883 6.169-3.891 14.848-5.798 26.023-5.709v-1.126c0-7.949-4.173-11.917-12.531-11.917-5.952 0-11.2 1.498-15.731 4.467l-2.726-8.806c5.606-3.469 12.531-5.21 20.697-5.21 15.77 0 23.68 8.32 23.68 24.96v22.221c0 6.029 0.294 10.829 0.87 14.387zM1039.898 151.207v-9.305c-14.797-0.256-22.195 3.802-22.195 12.16 0 3.149 0.845 5.504 2.573 7.078s3.929 2.355 6.553 2.355c2.944 0 5.696-0.935 8.205-2.791 2.522-1.869 4.071-4.237 4.647-7.143 0.141-0.653 0.217-1.446 0.217-2.355z"
      ></path>
      <path
        fill="currentColor"
        d="M1130.048 171.942h-11.904l-0.627-9.69h-0.371c-3.802 7.373-10.278 11.059-19.379 11.059-7.271 0-13.325-2.855-18.125-8.563s-7.194-13.12-7.194-22.221c0-9.767 2.599-17.677 7.821-23.719 5.056-5.632 11.251-8.448 18.624-8.448 8.102 0 13.773 2.727 16.998 8.192h0.256v-34.637h13.427v71.769c0 5.875 0.153 11.29 0.474 16.256zM1116.147 146.496v-10.061c0-1.741-0.128-3.149-0.371-4.224-0.755-3.226-2.381-5.939-4.851-8.128-2.496-2.189-5.504-3.29-8.973-3.29-5.005 0-8.921 1.984-11.802 5.965-2.854 3.981-4.301 9.063-4.301 15.271 0 5.965 1.37 10.803 4.121 14.528 2.906 3.968 6.822 5.952 11.725 5.952 4.403 0 7.923-1.651 10.599-4.966 2.585-3.059 3.853-6.745 3.853-11.047z"
      ></path>
      <path
        fill="currentColor"
        d="M1244.774 141.274c0 9.28-2.649 16.883-7.949 22.848-5.555 6.131-12.902 9.19-22.105 9.19-8.845 0-15.898-2.931-21.171-8.819-5.248-5.875-7.872-13.286-7.872-22.221 0-9.344 2.701-17.011 8.128-22.963s12.723-8.934 21.927-8.934c8.845 0 15.975 2.931 21.351 8.806 5.12 5.709 7.693 13.081 7.693 22.093zM1230.873 141.709c0-5.568-1.203-10.343-3.597-14.323-2.829-4.813-6.822-7.219-12.045-7.219-5.376 0-9.472 2.406-12.301 7.219-2.407 3.981-3.597 8.832-3.597 14.566 0 5.568 1.203 10.343 3.597 14.323 2.906 4.813 6.95 7.219 12.173 7.219 5.12 0 9.152-2.445 12.045-7.347 2.47-4.058 3.725-8.87 3.725-14.439z"
      ></path>
      <path
        fill="currentColor"
        d="M1316.902 171.942h-13.401v-34.56c0-10.65-4.045-15.975-12.173-15.975-3.981 0-7.194 1.459-9.677 4.391s-3.725 6.387-3.725 10.343v35.789h-13.427v-43.085c0-5.299-0.153-11.046-0.474-17.267h11.776l0.627 9.433h0.371c1.574-2.931 3.904-5.35 6.95-7.283 3.648-2.253 7.706-3.392 12.173-3.392 5.619 0 10.304 1.817 14.029 5.466 4.647 4.467 6.95 11.136 6.95 19.993v36.147z"
      ></path>
      <path
        fill="currentColor"
        d="M1407.181 121.651h-14.771v29.312c0 7.45 2.624 11.174 7.821 11.174 2.407 0 4.403-0.205 5.977-0.627l0.345 10.176c-2.649 0.999-6.131 1.498-10.419 1.498-5.299 0-9.421-1.613-12.403-4.839-2.995-3.226-4.48-8.653-4.48-16.269v-30.425h-8.819v-10.048h8.819v-11.059l13.146-3.968v15.014h14.771v10.061z"
      ></path>
      <path
        fill="currentColor"
        d="M1478.195 171.942h-13.427v-34.304c0-10.816-4.045-16.231-12.147-16.231-6.221 0-10.47 3.136-12.8 9.408-0.397 1.319-0.627 2.931-0.627 4.826v36.288h-13.401v-88.013h13.401v36.365h0.256c4.224-6.618 10.278-9.92 18.125-9.92 5.555 0 10.15 1.817 13.799 5.466 4.544 4.544 6.822 11.302 6.822 20.237v35.879z"
      ></path>
      <path
        fill="currentColor"
        d="M1551.449 138.918c0 2.406-0.179 4.429-0.499 6.080h-40.23c0.179 5.965 2.099 10.509 5.824 13.657 3.405 2.816 7.795 4.224 13.171 4.224 5.952 0 11.379-0.947 16.269-2.855l2.099 9.319c-5.722 2.483-12.455 3.725-20.249 3.725-9.344 0-16.704-2.752-22.029-8.256-5.351-5.504-8-12.889-8-22.157 0-9.101 2.47-16.678 7.449-22.72 5.197-6.451 12.224-9.677 21.094-9.677 8.678 0 15.271 3.225 19.725 9.677 3.597 5.12 5.376 11.456 5.376 18.983zM1538.649 135.45c0.102-3.981-0.781-7.411-2.599-10.304-2.33-3.725-5.875-5.594-10.675-5.594-4.378 0-7.949 1.817-10.675 5.466-2.227 2.906-3.545 6.374-3.981 10.432h27.929z"
      ></path>
    </svg>
  );
};

export default AppStore;
