import { IBannerResponse } from "./IBannerResponse";

interface IInitialState {
  banners: IBannerResponse;
}

const initialState: IInitialState = {
  banners: {
    data: [],
    count: 0,
  },
};

export function bannersReducer(
  state: IInitialState = initialState,
  action: any
): IInitialState {
  switch (action.type) {
    case "SAVE_BANNERS":
      return { ...state, banners: action.payload };

    default:
      return state;
  }
}
