import { IFavoritesResponse } from "./IFavoritesResponse";

export const saveFavoritesProducts = (payload: IFavoritesResponse) => ({
  type: "SAVE_FAVORITES_PRODUCTS",
  payload,
});

export const saveFavoritesPage = (payload: number) => ({
  type: "SAVE_FAVORITES_PAGE",
  payload,
});

export const saveUnlikedProducts = (payload: number[] | []) => ({
  type: "SAVE_UNLIKED_PRODUCTS",
  payload,
});

export const saveLikedProducts = (payload: number[] | []) => ({
  type: "SAVE_LIKED_PRODUCTS",
  payload,
});

export const setFavoritesProductsLoading = (payload: boolean) => ({
  type: "SET_FAVORITES_PRODUCTS_LOADING",
  payload,
});
