import { IPriceListResponse } from "./IPriceListResponse";

export const savePriceList = (payload: IPriceListResponse) => ({
  type: "SAVE_PRICE_LIST",
  payload,
});

export const setPriceListLoading = (payload: boolean) => ({
  type: "SET_PRICE_LIST_LOADING",
  payload,
});

export const saveShortPriceList = (payload: IPriceListResponse) => ({
  type: "SAVE_SHORT_PRICE_LIST",
  payload,
});

export const setShortPriceListLoading = (payload: boolean) => ({
  type: "SET_SHORT_PRICE_LIST_LOADING",
  payload,
});
