import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { selectCurrentSettings } from "../../store/products/repository/selectors";
import { getCategoryProducts } from "../../store/products/useCases/getCategoryProducts/action";
import { saveCurrentSettings } from "../../store/products/repository/actions";
import SearchResult from "../../ui-kit/searchResult/searchResult";

const SearchResultPage = () => {
  const currentSettings = useSelector(selectCurrentSettings);
  const dispatch = useDispatch();
  const { name } = useParams();

  useEffect(() => {
    if (currentSettings?.name === name) return;

    dispatch(getCategoryProducts({}, name));
    dispatch(saveCurrentSettings({ name }));
  }, [currentSettings, name]);

  return <SearchResult />;
};

export default SearchResultPage;
