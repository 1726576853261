import { useRef, useState, useEffect, FC } from "react";
import { IPicture } from "../../store/products/repository/IPicture";
import SwiperArrow from "../swiperArrow/swiperArrow";
import Image from "../image/image";
import SwiperCore, { Navigation, Thumbs, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Lightbox from "react-image-lightbox";
import YouTube, { YouTubePlayer } from "react-youtube";
import { BsYoutube } from "react-icons/bs";
import "./singleProductSlider.scss";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "react-image-lightbox/style.css";

interface IProps {
  images: IPicture[];
  videoId?: string;
}

const SingleProductSlider: FC<IProps> = ({ images, videoId }) => {
  SwiperCore.use([Navigation, Thumbs, EffectFade]);

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const thumbSwiperRef = useRef<SwiperCore | undefined>(undefined);
  const playerRef = useRef<YouTubePlayer | null>(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const handleOpen = (index: number) => {
    setPhotoIndex(index);
    setOpen(true);
  };

  useEffect(() => {
    if (thumbSwiperRef.current) {
      setThumbsSwiper(thumbSwiperRef.current);
    }
  }, [thumbSwiperRef]);

  const handleThumbSwiperInit = (swiper: SwiperCore) => {
    thumbSwiperRef.current = swiper;
  };

  const handleSlideChange = () => {
    if (playerRef.current && isPlaying) {
      playerRef.current.pauseVideo();
      setIsPlaying(false);
    }
  };

  const handlePlayerReady = (event: YouTubePlayer) => {
    playerRef.current = event.target;
  };

  const handlePlayerStateChange = (event: YouTubePlayer) => {
    if (event.data === 1) {
      setIsPlaying(true);
    } else if (event.data === 0) {
      setIsPlaying(false);
    }
  };

  const prevIndex = (photoIndex + images.length - 1) % images.length;

  const lightBoxProps = {
    mainSrc: images[photoIndex].fileUrl,
    nextSrc: images[(photoIndex + 1) % images.length].fileUrl,
    prevSrc: images[prevIndex].fileUrl,
    imageTitle: `${photoIndex + 1} / ${images.length}`,
    onCloseRequest: () => setOpen(false),
    onMovePrevRequest: () => setPhotoIndex(prevIndex),
    onMoveNextRequest: () => setPhotoIndex((photoIndex + 1) % images.length),
  };

  const swiperProps = {
    thumbs: { swiper: thumbsSwiper },
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
      prevEl: prevRef.current,
      nextEl: nextRef.current,
    },
    onSlideChange: handleSlideChange,
  };

  const swiperThumbnailsProps = {
    onSwiper: handleThumbSwiperInit,
    watchSlidesProgress: true,
    spaceBetween: 20,
    breakpoints: {
      768: {
        slidesPerView: 6.5,
      },
      320: {
        slidesPerView: 4.5,
      },
    },
  };

  const videoProps = {
    videoId: videoId,
    iframeClassName: "single_product_slider__iframe",
    onReady: handlePlayerReady,
    onStateChange: handlePlayerStateChange,
  };

  return (
    <div className="single_product_slider">
      <div className="single_product_slider__main">
        <Swiper
          effect="fade"
          fadeEffect={{
            crossFade: true,
          }}
          {...swiperProps}
        >
          {images.map((image, index: number) => {
            const { fileName, fileUrl } = image;

            return (
              <SwiperSlide key={fileName}>
                <div className="single_product_slider__container">
                  <div
                    onClick={() => handleOpen(index)}
                    className="single_product_slider__wrap single_product_slider__wrap--main_mod"
                  >
                    <Image
                      contain
                      imgClass="single_product_slider__image"
                      src={fileUrl}
                      alt={fileName}
                    />
                  </div>
                </div>
              </SwiperSlide>
            );
          })}

          {videoId && (
            <SwiperSlide>
              <div className="single_product_slider__video">
                <YouTube {...videoProps} />
              </div>
            </SwiperSlide>
          )}
        </Swiper>
        <SwiperArrow
          arrowRef={prevRef}
          classes="single_product_slider__arrow single_product_slider__arrow--prev_mod"
        />
        <SwiperArrow
          arrowRef={nextRef}
          classes="single_product_slider__arrow single_product_slider__arrow--next_mod"
        />
      </div>

      <div className="single_product_slider__thumbs">
        <Swiper {...swiperThumbnailsProps}>
          {images.map(({ fileUrl, fileName }) => (
            <SwiperSlide key={fileName}>
              <div className="single_product_slider__wrap">
                <Image
                  contain
                  imgClass="single_product_slider__image"
                  src={fileUrl}
                  alt={fileName}
                />
              </div>
            </SwiperSlide>
          ))}
          {videoId && (
            <SwiperSlide>
              <div className="single_product_slider__wrap">
                <div className="single_product_slider__icon font_icon">
                  <BsYoutube />
                </div>
              </div>
            </SwiperSlide>
          )}
        </Swiper>
      </div>

      {isOpen && !!images.length && <Lightbox {...lightBoxProps} />}
    </div>
  );
};

export default SingleProductSlider;
