import { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ROUTES } from "./constants";
import Layout from "./Layout";
import withLanguage from "./hoc/withLanguage";
import { useDispatch, useSelector } from "react-redux";
import { getLanguages } from "./store/language/useCases/action";
import { getCatalog } from "./store/catalog/useCases/getCatalog/action";
import { saveCurrentSettings } from "./store/products/repository/actions";
import {
  saveLikedProducts,
  saveUnlikedProducts,
} from "./store/favorites/repository/actions";
import { savePrevPathname } from "./store/app/repository/actions";
import { setRatingPopupOpen } from "./store/news/repository/actions";
import { getUser } from "./store/user/useCases/getUser/actions";
import {
  selectNews,
  selectRatingPopupOpen,
} from "./store/news/repository/selectors";
import { selectisLoggedIn } from "./store/user/repository/selectors";
import { selectVotedArticleId } from "./store/news/repository/selectors";
import RatePopup from "./ui-kit/ratePopup/ratePopup";
// pages
import HomePage from "./pages/HomePage/HomePage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import ProductListPage from "./pages/ProductListPage/ProductListPage";
import CategoriesListPage from "./pages/CategoriesListPage/CategoriesListPage";
import SearchResultPage from "./pages/SearchResultPage/SearchResultPage";
import SingleProductPage from "./pages/SingleProductPage/SingleProductPage";
import NewsPage from "./pages/NewsPage/NewsPage";
import SingleNewsPage from "./pages/SingleNewsPage/SingleNewsPage";
import WarrantyPage from "./pages/WarrantyPage/WarrantyPage";
import FavoritesPage from "./pages/FavoritesPage/FavoritesPage";
import CartPage from "./pages/CartPage/CartPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import OrdersPage from "./pages/OrdersPage/OrdersPage";
import OrderingPage from "./pages/OrderingPage/OrderingPage";
import NotificationPage from "./pages/NotificationPage/NotificationPage";
import NotifyProductsPage from "./pages/NotifyProductsPage/NotifyProductsPage";
import ContactsPage from "./pages/ContactsPage/ContactsPage";
import InfoEmailPage from "./pages/InfoEmailPage/InfoEmailPage";
import "./index.scss";
import PromotionPage from "./pages/PromotionPage";
import SinglePromotionPage from "./pages/SinglePomotionPage/SinglePromotionPage";

const App = () => {
  const isRatingPopupOpen = useSelector(selectRatingPopupOpen);
  const [newsId, setNewsId] = useState<null | number>(null);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectisLoggedIn);
  const newsList = useSelector(selectNews);
  const votedArticleId = useSelector(selectVotedArticleId);

  const newsPageRegex = /^\/news\/(\d+)$/;
  const match = pathname.match(newsPageRegex);

  useEffect(() => {
    if (!pathname.includes("product")) {
      dispatch(saveCurrentSettings({}));
    }

    dispatch(savePrevPathname(pathname));
  }, [pathname]);

  useEffect(() => {
    dispatch(getCatalog());
    dispatch(saveLikedProducts([]));
    dispatch(saveUnlikedProducts([]));
    if (isLoggedIn === "true") dispatch(getUser());
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) return;

    if (match) {
      const id = Number(pathname.split("/").pop());
      setNewsId(id);
    } else if (newsId && newsList.data) {
      const currentArticle = newsList.data.find(({ id }) => id === newsId);

      if (currentArticle?.isVoted || votedArticleId === currentArticle?.id)
        return;

      setTimeout(() => {
        dispatch(setRatingPopupOpen(true));
      }, 10);
    }
  }, [pathname, isLoggedIn]);

  useEffect(() => {
    dispatch(getLanguages());
  }, []);

  return (
    <>
      <Routes>
        <Route path={ROUTES.Home} element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path={ROUTES.NotFound} element={<NotFoundPage />} />
          <Route path={ROUTES.CategoryProducts} element={<ProductListPage />} />
          <Route
            path={ROUTES.CategoriesItem}
            element={<CategoriesListPage />}
          />
          <Route
            path={ROUTES.CategoryProduct}
            element={<SingleProductPage />}
          />
          <Route path={ROUTES.NewsPage} element={<NewsPage />} />
          <Route path={ROUTES.PromotionList} element={<PromotionPage />} />
          <Route path={ROUTES.SinglePromotionPage} element={<SinglePromotionPage />} />
          <Route path={ROUTES.SingleNewsPage} element={<SingleNewsPage />} />
          <Route path={ROUTES.WarrantyConditions} element={<WarrantyPage />} />
          <Route path={ROUTES.SearchResult} element={<SearchResultPage />} />
          <Route path={ROUTES.Favorites} element={<FavoritesPage />} />
          <Route path={ROUTES.Cart} element={<CartPage />} />
          <Route path={ROUTES.Profile} element={<ProfilePage />} />
          <Route path={ROUTES.Orders} element={<OrdersPage />} />
          <Route path={ROUTES.Ordering} element={<OrderingPage />} />
          <Route path={ROUTES.Notifications} element={<NotificationPage />} />
          <Route
            path={ROUTES.NotifyProducts}
            element={<NotifyProductsPage />}
          />
          <Route path={ROUTES.ContactsPage} element={<ContactsPage />} />
          <Route
            path={ROUTES.SuccessConfirmEmail}
            element={<InfoEmailPage success />}
          />
          <Route
            path={ROUTES.FailureConfirmEmail}
            element={<InfoEmailPage />}
          />
        </Route>
      </Routes>

      {isRatingPopupOpen && <RatePopup setNewsId={setNewsId} newsId={newsId} />}
    </>
  );
};

export default withLanguage(App);
