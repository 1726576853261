import { useDispatch, useSelector } from "react-redux";
import Section from "../section/section";
import Loader from "../loader/loader";
import Image from "../image/image";
import { useEffect } from "react";
import { selectPromoProductsLoading, selectSinglePromo } from "../../store/promo/repository/selectors";
import Products from "../products/products";
import { saveFilters, saveProducts } from "../../store/products/repository/actions";
import Timer from "../timer/timer";
import PromoDetails from "./ui/promoDetails/promoDetails";
import './singlePrjmotionSection.scss';
import { selectCurrentSettings } from "../../store/products/repository/selectors";
import { getFilters } from "../../store/products/useCases/getFilters/action";

const SinglePromotionSection = () => {
  const dispatch = useDispatch();
  const singlePromo = useSelector(selectSinglePromo);
  const isPromoLoading = useSelector(selectPromoProductsLoading);
  const currentSettings = useSelector(selectCurrentSettings);
  
  useEffect(() => {
    if (singlePromo) {
      //@ts-ignore
      dispatch(saveProducts({
        //@ts-ignore
        count: singlePromo.products.rows.length,
        //@ts-ignore
        data: singlePromo.products.rows,
        //@ts-ignore
        // filters: singlePromo.products.filters,
        // prices: {
        //   maxPrice: 0,
        //   minPrice: 0,
        // },
      })); 

      //@ts-ignore
      // dispatch(saveFilters(singlePromo.products?.filters));
      dispatch(getFilters(undefined, currentSettings?.filter, singlePromo.promo.id));
    }
  }, [singlePromo]); 
  
  return (
    <Section noMaxWidth className="single_promotion">
      {isPromoLoading && <Loader />}
      {singlePromo && (
        <>
          <div className="single_promotion__wrap">
            <div className="section_in">
              <div className="single_promotion__row">
                <div className="single_promotion__column1">
                  <Image src={singlePromo?.promo?.image ? singlePromo.promo.image.fileUrl : '/favicon-310.png'} alt="cover" imgClass="single_promotion__cover"/>
                </div>
                <div className="single_promotion__column2">
                  <div className="promotion_description">
                    <h1 className="promotion_description__title">{singlePromo?.promo?.title}</h1>
                    <p className="promotion_description__description">{singlePromo?.promo?.description}</p>
                    {singlePromo?.promo?.endDate && <Timer startDate={singlePromo.promo.startDate} endDate={singlePromo.promo.endDate} />}
                    {singlePromo?.promo?.description && <PromoDetails value={singlePromo.promo.description} />}
                  </div>
                </div>
              </div>
              <Products title={""} description={""} />
            </div>
          </div>
        </>
      )}
    </Section>
  );
};

export default SinglePromotionSection;