import { useEffect, useState } from "react";
import i18n from "../locales";

export const useTimer = (endDate: string) => {
    
    const getDayWord = (days: number) => {
        if (days === 1) return i18n.t('day1');
        if (days >= 2 && days <= 4) return i18n.t('day2');
        if (days >= 5 && days <= 20) return i18n.t('day3');

        const remainder = days % 10;
        if (remainder === 1) return i18n.t('day1');
        if (remainder >= 2 && remainder <= 4) return i18n.t('day2');
        return i18n.t('day3');
    };

    const calculateTimeLeft = (endDate: string) => {
        const difference = new Date(endDate).getTime() - new Date().getTime();
        let timeLeft: { days?: number; hours?: number; minutes?: number; seconds?: number } = {};

        if (difference > 0) {
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / 1000 / 60) % 60);
            const seconds = Math.floor((difference / 1000) % 60);

            if (days > 0) {
                timeLeft = { days };
            } else {
                timeLeft = { hours, minutes, seconds };
            }
        } else {
            timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endDate));
    const [isExpired, setIsExpired] = useState(false);

    useEffect(() => {
        const newTimeLeft = calculateTimeLeft(endDate);
        setTimeLeft(newTimeLeft);

        if (
            newTimeLeft.days === 0 &&
            newTimeLeft.hours === 0 &&
            newTimeLeft.minutes === 0 &&
            newTimeLeft.seconds === 0
        ) {
            setIsExpired(true);
        }
    }, [endDate]);

    useEffect(() => {
        const timer = setTimeout(() => {
            const newTimeLeft = calculateTimeLeft(endDate);
            setTimeLeft(newTimeLeft);
            if (
                newTimeLeft.days === 0 &&
                newTimeLeft.hours === 0 &&
                newTimeLeft.minutes === 0 &&
                newTimeLeft.seconds === 0
            ) {
                setIsExpired(true);
            }
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft, endDate]);

    return { timeLeft, getDayWord, isExpired };
};