import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { saveUser } from "../../repository/actions";

function* getUserWorker(): SagaIterator {
  try {
    const { data, isError } = yield call(requester.get, links.user);
    if (!isError) {
      yield put(saveUser(data));
    }
  } catch (error) {
    console.error("getUserWorker: ", error);
  }
}

export function* getUserWatcher() {
  yield takeLatest("@saga/GET_USER", getUserWorker);
}
