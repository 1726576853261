import { FC, RefObject } from "react";
import classNames from "classnames";
import { BsChevronRight } from "react-icons/bs";
import Loader from "../loader/loader";
import "./swiperArrow.scss";

interface IProps {
  arrowRef: RefObject<HTMLButtonElement>;
  classes?: string;
  isLoading?: boolean;
}

const SwiperArrow: FC<IProps> = ({ classes, arrowRef, isLoading }) => {
  return (
    <button
      type="button"
      aria-label="Prev/next slide"
      className={classNames("swiper_arrow", classes, {
        "swiper_arrow--loading_state": isLoading,
      })}
      ref={arrowRef}
    >
      <BsChevronRight />
      {isLoading && (
        <div className="swiper_arrow__loader">
          <Loader btnStyle />
        </div>
      )}
    </button>
  );
};

export default SwiperArrow;
