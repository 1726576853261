import { FC, ReactNode } from "react";
import Section from "../section/section";
import "./textSection.scss";

interface IProps {
  children: ReactNode;
}

const TextSection: FC<IProps> = ({ children }) => {
  return <Section className="text_section">{children}</Section>;
};

export default TextSection;
