import i18n from "../../locales";
import classNames from "classnames";
import { gsap, Power2 } from "gsap";
import {
  FC,
  RefObject,
  useRef,
  useState,
  useEffect,
  useCallback,
  memo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getCategoryProducts } from "../../store/products/useCases/getCategoryProducts/action";
import { saveCurrentSettings } from "../../store/products/repository/actions";
import {
  selectCurrentSettings,
  selectFilters,
  selectProductsPrices,
} from "../../store/products/repository/selectors";
import { IFilterRequest } from "../../store/products/repository/IFilterRequest";
import FilterItem from "../filterItem/filterItem";
import PriceSlider from "../priceSlider/priceSlider";
import { FiltersGroup } from "./ui";
import { AiOutlineClear } from "react-icons/ai";
import "./productFilters.scss";

interface FormValues {
  [key: string]: string | string[] | number | undefined;
  min?: number;
  max?: number;
};

interface IProps {
  isPromo: boolean;
  handleClose: () => void;
};

const ProductFilters: FC<IProps> = ({ handleClose, isPromo }) => {
  const [resetKey, setResetKey] = useState<number>(0);
  const [isFilterSelected, setFilterSelected] = useState<boolean>(false);
  const [distance, setDistance] = useState<number>(0);
  const [olDistance, setOldDistance] = useState<number>(distance);
  
  const priceRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLFormElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const { register, handleSubmit, setValue, getValues, reset } =
    useForm<FormValues>({});

  const { id } = useParams();
  const dispatch = useDispatch();
  const currentSettings = useSelector(selectCurrentSettings);
  const prices = useSelector(selectProductsPrices);
  const filters = useSelector(selectFilters);
  const sortBy = currentSettings?.sortBy;

  const isPricesExist = prices?.maxPrice > 0 && prices?.minPrice !== undefined;

  const onSubmit = useCallback(
    (data: FormValues) => {
      let filter: IFilterRequest[] | undefined = [];
      const { min, max } = data;

      if (Object.keys(data).length === 2) {
        filter = currentSettings?.filter;
      } else {
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            if (
              key !== "min" &&
              key !== "max" &&
              data[key] &&
              typeof data[key] !== "boolean"
            ) {
              Array.isArray(data[key])
                ? filter.push({
                    keyId: Number(key),
                    values: data[key] as string[],
                  })
                : filter.push({
                    keyId: Number(key),
                    values: [data[key] as string],
                  });
            }
          }
        }
      }

      if (typeof min === "number" && typeof max === "number") {
        const params = { id, filter, priceMin: min, priceMax: max, sortBy, isPromo };
        dispatch(getCategoryProducts({ ...params }));
        dispatch(saveCurrentSettings({ ...params }));
      } else {
        dispatch(getCategoryProducts({ id, filter, sortBy, isPromo }));
        dispatch(saveCurrentSettings({ id, filter, sortBy, isPromo }));
      }

      handleClose();
      handleButtonHide();
    },
    [id, sortBy]
  );

  const onReset = useCallback(() => {
    reset();
    dispatch(saveCurrentSettings({ id, sortBy }));
    dispatch(getCategoryProducts({ id/* , sortBy, isSavePrice: true, isPromo */ }));
    setResetKey((prev) => prev + 1);
    handleButtonHide(10);
  }, [reset, id, sortBy]);

  const handleButtonHide = useCallback((timeout = 0) => {
    setTimeout(() => {
      setFilterSelected(false);
    }, timeout);
  }, []);

  const handleFilterChange = useCallback(
    (ref: RefObject<HTMLElement>) => {
      if (!ref.current || !bodyRef.current) return;
      const filterTop = ref.current.getBoundingClientRect().top;
      const bodyTop = bodyRef.current.getBoundingClientRect().top;

      if (!isFilterSelected) {
        setFilterSelected(true);
      }
      setOldDistance(distance);
      setDistance(filterTop - bodyTop);
    },
    [isFilterSelected, distance, olDistance]
  );

  useEffect(() => {
    if (distance === olDistance) return;

    gsap.context(() => {
      gsap.fromTo(
        buttonRef.current,
        { y: olDistance },
        {
          y: distance,
          ease: Power2.easeIn,
          duration: 0.3,
        }
      );
    }, bodyRef);
  }, [distance, olDistance]);

  useEffect(() => {
    if (id !== currentSettings?.id) {
      onReset();
    }
  }, [id]);

  return (
    <form
      ref={bodyRef}
      action="GET"
      onSubmit={handleSubmit(onSubmit)}
      className="product_filtres"
    >
      <button onClick={onReset} type="reset" className="product_filtres__reset">
        <span>{i18n.t("resetFilters")}</span>
        <AiOutlineClear />
      </button>
      <div className="product_filtres__body">
        {isPricesExist && (
          <div className="product_filtres__item">
            <FilterItem
              onSpoilerClose={handleButtonHide}
              name={i18n.t("price")}
            >
              <div ref={priceRef} className="product_filtres__item">
                <PriceSlider
                  key={resetKey}
                  onChange={() => handleFilterChange(priceRef)}
                  register={register}
                  setValue={setValue}
                  min={prices.minPrice}
                  max={prices.maxPrice}
                />
              </div>
            </FilterItem>
          </div>
        )}
        {filters && (
          <FiltersGroup
            filters={filters}
            handleButtonHide={handleButtonHide}
            handleFilterChange={handleFilterChange}
            resetKey={resetKey}
            setValue={setValue}
            getValues={getValues}
            handleSubmit={handleSubmit(onSubmit)}
          />
        )}
      </div>
      <div
        ref={buttonRef}
        className={classNames("product_filtres__wrapper", {
          "product_filtres__wrapper--active_state": isFilterSelected,
        })}
      >
        <button className="product_filtres__sumbit" type="submit">
          <span>{i18n.t("apply")}</span>
        </button>
      </div>
    </form>
  );
};

export default memo(ProductFilters);
