import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { IAddNewsRating } from "../../repository/IAddNewsRating";
import { createNotification } from "../../../../utils/createNotification";
import { setAppLoading } from "../../../app/repository/actions";
import {
  setRatingPopupOpen,
  saveVotedArticleId,
} from "../../repository/actions";
import i18n from "../../../../locales";

interface IAddRatingWorker {
  type: string;
  payload: IAddNewsRating;
}

function* addRatingWorker({ payload }: IAddRatingWorker): SagaIterator {
  try {
    yield put(setAppLoading(true));

    const { isError, message } = yield call(
      requester.post,
      links.addRating,
      payload
    );

    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(setRatingPopupOpen(false));
      yield put(saveVotedArticleId(payload.newsId));

      createNotification({
        title: i18n.t("success"),
        message: "",
        type: "success",
      });
    }
  } catch (error) {
    console.error("addRatingWorker: ", error);
  } finally {
    yield put(setAppLoading(false));
  }
}

export function* addRatingWatcher() {
  yield takeLatest("@saga/ADD_RATING", addRatingWorker);
}
