import { FC } from "react";
import "./emptyState.scss";

interface IProps {
  title: string;
  description: string;
  image: string;
};

const EmptyState: FC<IProps> = ({ title, description, image }) => {

  return (
    <div className="empty-state-container">
      <img src={image} alt={title} className="empty-state-image" />
      <h2 className="empty-state-title">{title}</h2>
      <p className="empty-state-description">{description}</p>
    </div>
  );
};

export default EmptyState;
