import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAppLoading } from "./store/app/repository/selectors";
import { ReactNotifications } from "react-notifications-component";
import { useScrollbarWidth } from "./customHooks";
import Header from "./ui-kit/header/header";
import Footer from "./ui-kit/footer/footer";
import Loader from "./ui-kit/loader/loader";

import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";

const Layout = () => {
  const { pathname } = useLocation();
  const isLoading = useSelector(selectAppLoading);
  const scrollBarWidth = useScrollbarWidth();

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--scrollbar-width",
      `${scrollBarWidth}px`
    );
  }, [scrollBarWidth]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [pathname]);

  return (
    <>
      {isLoading && <Loader />}
      <ReactNotifications />
      <Header />
      <div className="wrapper">
        <div className="base">
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
