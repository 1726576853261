import i18n from "../../locales";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";
import "./notFoundSection.scss";

const NotFoundSection = () => {
  return (
    <div className="not_found">
      <h1 className="not_found__title">
        <span> 404 </span> {i18n.t("title404")}
      </h1>
      <div className="not_found__text">{i18n.t("message404")}</div>
      <Link to={ROUTES.Home} className="not_found__link">
        <span>{i18n.t("goToHomePage")}</span>
      </Link>
    </div>
  );
};

export default NotFoundSection;
