import i18n from "../../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { setCartLoading, saveCart } from "../../repository/actions";
import { links } from "../../../../utils/links";
import { createNotification } from "../../../../utils/createNotification";
import { PRODUCTS_PER_PAGE } from "../../../../constants";

interface IGetCartWorker {
  type: string;
  payload: number;
}

function* getCartWorker({ payload }: IGetCartWorker): SagaIterator {
  try {
    yield put(setCartLoading(true));

    const params = {
      // page: payload ?? 1,
      // perPage: PRODUCTS_PER_PAGE,
    };

    const { data, isError, message } = yield call(
      requester.get,
      links.getCart,
      params,
      undefined,
      undefined,
      true
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(saveCart(data));
    }
  } catch (error) {
    console.error("getCartWorker: ", error);
  } finally {
    yield put(setCartLoading(false));
  }
}

export function* getCartWatcher() {
  yield takeLatest("@saga/GET_CART", getCartWorker);
}
