import { FC } from "react";
import { IProductWarehouseItem } from "../../store/products/repository/IProductWarehouseItem";
import i18n from "../../locales";
import "./availableTable.scss";

interface IProps {
  data: IProductWarehouseItem[];
  allWarehouseAmount: number;
}

const AvailableTable: FC<IProps> = ({ data, allWarehouseAmount }) => {
  return (
    <table className="available_table">
      <thead className="available_table__head">
        <td>{i18n.t("storage")}</td>
        <td>{i18n.t("availability")}</td>
      </thead>
      <tbody className="available_table__body">
        {data.map(({ warehouseName, amount }) => (
          <tr key={warehouseName}>
            <td>{warehouseName}</td>
            <td>
              {amount} {i18n.t("piece")}
            </td>
          </tr>
        ))}
        <tr>
          <td>{i18n.t("allWarehouses")}</td>
          <td>
            {allWarehouseAmount} {i18n.t("piece")}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default AvailableTable;
