import { FC, ReactNode } from "react";
import { UseFormSetValue } from "react-hook-form";
import Label from "../label/label";
import MarkRating from "../markRating/markRating";
import "./ratingField.scss";

interface IProps {
  label: ReactNode;
  required?: boolean;
  name?: string;
  setValue?: UseFormSetValue<any>;
}

const RatingField: FC<IProps> = ({ label, ...props }) => {
  return (
    <div className="rating_field">
      <Label>{label}</Label>
      <div className="rating_field__body">
        <MarkRating {...props} />
      </div>
    </div>
  );
};

export default RatingField;
