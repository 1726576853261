import { FC } from "react";
import classNames from "classnames";
import "./infoItem.scss";

interface IProps {
  label: string;
  text: string;
  title?: string;
  mod?: "sale" | "promo";
}

export const InfoItem: FC<IProps> = ({ label, title, text, mod }) => {
  return (
    <div
      className={classNames("info_item", {
        "info_item--promo_mod": mod === "promo",
        "info_item--sale_mod": mod === "sale",
      })}
    >
      <div className="info_item__title">
        <span>{label}:</span>
        {title}
      </div>
      <div className="info_item__text">{text}</div>
    </div>
  );
};
