import { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IProduct } from "../../../store/products/repository/IProduct";
import { IGroupCategory } from "../../../store/groupProducts/repository/IGroupCategory";
import { TSliderMod } from "../../../types/sliderMod";
import { getGroupProducts } from "../../../store/groupProducts/useCases/getGroupList/action";
import { getCategoryProducts } from "../../../store/products/useCases/getCategoryProducts/action";
import { savePaginationGroupId } from "../../../store/groupProducts/repository/actions";
import { selectGroupProductsLoading } from "../../../store/groupProducts/repository/selectors";
import { selectProductsLoading } from "../../../store/products/repository/selectors";
import { selectProducts } from "../../../store/products/repository/selectors";
import { selectPaginationGroupId } from "../../../store/groupProducts/repository/selectors";
import { SLIDES_PER_PAGE } from "../../../constants";
import ProductsTabs from "../../productsTabs/productsTabs";

interface IProps {
  products: IProduct[];
  title: string;
  categories: IGroupCategory[];
  mod: TSliderMod;
  groupId: number;
}

const GroupProductsTabs: FC<IProps> = ({ products, groupId, ...props }) => {
  const [groupProducts, setGroupProducts] = useState<IProduct[]>([]);
  const [categoryId, setCategoryId] = useState<number>(-1);

  const dispatch = useDispatch();
  const { data } = useSelector(selectProducts);
  const paginationGroupId = useSelector(selectPaginationGroupId);
  const isGroupProductsLoading = useSelector(selectGroupProductsLoading);
  const isCategoryProductsLoading = useSelector(selectProductsLoading);

  const handleTabClick = (id: number, productGroupId: number) => {
    setCategoryId(id);
    dispatch(savePaginationGroupId(-1));

    if (id === -1) {
      dispatch(getGroupProducts());
    } else {
      dispatch(
        getCategoryProducts({
          id,
          productGroupId,
          perPage: SLIDES_PER_PAGE,
          slider: "group",
        })
      );
    }
  };

  useEffect(() => {
    if (categoryId === -1 && paginationGroupId !== groupId) {
      setGroupProducts(products);
    } else {
      setGroupProducts(data);
    }
  }, [data, products]);

  return (
    <ProductsTabs
      handleTabClick={(categoryId) => handleTabClick(categoryId, groupId)}
      isLoading={isGroupProductsLoading || isCategoryProductsLoading}
      products={groupProducts}
      groupId={groupId}
      {...props}
    />
  );
};

export default GroupProductsTabs;
