import i18n from "../../../locales";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../libs/requester";
import { put, call, takeLatest } from "redux-saga/effects";
import { links } from "../../../utils/links";
import { saveLanguages } from "../repository/actions";
import { createNotification } from "../../../utils/createNotification";
import { setAppLoading } from "../../app/repository/actions";

function* getLanguagesProductsWorker(): SagaIterator {
  try {
    yield put(setAppLoading(true));
    const { data, isError, message } = yield call(
      requester.get,
      links.languages
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      yield put(saveLanguages(data));
    }
  } catch (error) {
    console.error("getLanguagesWorker: ", error);
  } finally {
    yield put(setAppLoading(false));
  }
}

export function* getLanguagesWatcher() {
  yield takeLatest("@saga/GET_LANGUAGES", getLanguagesProductsWorker);
}
