import { ISinglePromo } from "./ISinglePromo";
import { IPromoProducts } from "./IPromoProducts";
import { IPromo } from "../../products/repository/IPromo";

interface IInitialState {
  promoList: IPromoProducts;
  singlePromo: ISinglePromo | null;
  isPromoProductsLoading: boolean;
  isHiddenPromoProductsLoading: boolean;
  promotionList: IPromo[],
  promoCount: number
}

const initialState: IInitialState = {
  promoList: {
    products: [],
    categories: [],
  },
  promotionList: [],
  promoCount: 0,
  singlePromo: null,
  isPromoProductsLoading: false,
  isHiddenPromoProductsLoading: false,
};

export function promoProductsReducer(
  state: IInitialState = initialState,
  action: any
): IInitialState {
  switch (action.type) {
    case "SAVE_PROMO_PRODUCTS":
      return { ...state, promoList: action.payload };
    
      case "SAVE_PROMO_LIST":
      return { ...state, promotionList: action.payload.promo, promoCount: action.payload.count };

    case "SET_PROMO_PRODUCTS_LOADING":
      return { ...state, isPromoProductsLoading: action.payload };

    case "SET_HIDDEN_PROMO_PRODUCTS_LOADING":
      return { ...state, isHiddenPromoProductsLoading: action.payload };

    case "SAVE_SINGLE_PROMO":
      return { ...state, singlePromo: action.payload };

    default:
      return state;
  }
}
