import i18n from "../../../../locales";
import { createNotification } from "../../../../utils/createNotification";
import { SagaIterator } from "redux-saga";
import { requester } from "../../../../libs/requester";
import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import { links } from "../../../../utils/links";
import { saveCart, setCartLoading } from "../../repository/actions";

interface IUpdateCartProductWorker {
  type: string;
  payload: {
    productId: number;
    amount: number;
  };
}

function* updateCartProductWorker({
  payload,
}: IUpdateCartProductWorker): SagaIterator {
  try {
    // yield put(setCartLoading(true));

    const { data, isError, message } = yield call(
      requester.put,
      links.updateProductInCart,
      payload,
      undefined,
      undefined,
      true
    );
    if (isError) {
      createNotification({
        title: i18n.t("error"),
        message,
        type: "danger",
      });
    } else {
      // createNotification({
      //   title: i18n.t("success"),
      //   message,
      //   type: "success",
      // });
      yield put(saveCart(data));
    }
  } catch (error) {
    console.error("updateCartProductWorker: ", error);
  } finally {
    // yield put(setCartLoading(false));
  }
}

export function* updateCartProductWatcher() {
  yield takeEvery("@saga/UPDATE_CART_PRODUCT", updateCartProductWorker);
}
