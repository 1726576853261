import { IResponseLanguage } from "./IResponseLanguage";

export const saveLanguages = (payload: IResponseLanguage) => ({
  type: "SAVE_LANGUAGES",
  payload,
});

export const saveCurrentLang = (payload: string) => ({
  type: "SAVE_CURRENT_LANG",
  payload,
});
